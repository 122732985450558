@use "./assets_v2/scss/mixin"as *;

//==========================================
// コンポーネント用見出し ※実際のページでは使用しない
//==========================================
.component-h2 {
	background-color: #333;
	color: #fff;
	padding: 20px;
	margin-bottom: 30px;
}

.component-h3 {
	border-bottom: 1px solid #333;
	margin-bottom: 30px;
	padding: 0 0 10px 0 !important;

	@include media(sp) {
		padding: 0 23px 10px 23px !important;
	}
}

.w-2 {
	width: 48.5%;

	@include media(sp) {
		width: 100%;
	}
}

.w-3 {
	width: 31.5%;

	@include media(sp) {
		width: 100%;
	}
}

.w-4 {
	width: 23%;

	@include media(sp) {
		width: 100%;
	}
}

//==========================================
// 見出し・本文
//==========================================

// h1
//------------------------------------------
h1 {
	font-size: 40px;
	@include lineheight(1.55);

	@include media(sp) {
		font-size: 32px;
		@include lineheight(1.375);
	}
}

// h2
//-------------------------------------------
h2 {
	font-size: 30px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 24px;
	}
}

// h3
//-------------------------------------------
h3 {
	font-size: 24px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 20px;
	}
}

// h4
//-------------------------------------------
h4 {
	font-size: 20px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 18px;
	}
}

// h5
//-------------------------------------------
h5 {
	font-size: 18px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 16px;
	}
}

h1,
h2,
h3,
h4,
h5 {
	color: #333;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	font-weight: bold;
}

// 小文字タイトル
//-------------------------------------------
.xxl-ttl {
	font-size: 30px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 24px;
	}
}

.xl-ttl {
	font-size: 24px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 20px;

		&--sp {
			font-size: 24px !important;
		}
	}
}

.l-ttl {
	font-size: 20px;
	@include lineheight(1.5);

	@include media(sp) {
		font-size: 18px;
	}

	&--sp {
		@include media(sp) {
			font-size: 20px;
		}
	}
}

.s-ttl {
	font-size: 18px;
	@include lineheight(1.5);
}

.ss-ttl {
	font-size: 16px;
	@include lineheight(1.5);
}

// 検索結果ハイライト
//-------------------------------------------
.SS_highlight {
	background-color: #ff0;
	display: inline;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	font-weight: bold;
}

// グラデーションボーダー
//-------------------------------------------
.g-hr {
	width: 100%;
	height: 3px;
	border: none;
	background: rgb(0, 91, 170);
	background: linear-gradient(90deg,
			rgba(0, 91, 170, 1) 0%,
			rgba(0, 180, 237, 1) 30%,
			rgba(137, 186, 23, 1) 70%,
			rgba(255, 233, 0, 1) 100%);
}

// 本文
//-------------------------------------------
p {
	@include lineheight(1.5);
}

.XXl-txt {
	font-size: 26px;

	@include media(sp) {
		font-size: 18px;
	}

	&--sp {
		@include media(sp) {
			font-size: 26px;
		}
	}
}

.l-txt {
	font-size: 18px;

	@include media(sp) {
		font-size: 16px;
	}

	&--sp {
		@include media(sp) {
			font-size: 18px;
		}
	}
}

.s-txt {
	font-size: 14px;

	@include media(sp) {
		font-size: 13px;
	}
}

.lh-txt {
	line-height: 2;

	&--sp {
		@include media(sp) {
			line-height: 1.6;
		}
	}
}

.bold-txt {
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	font-weight: bold;
}

.normal-txt {
	font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
	font-weight: normal;
	color: #333;
}

// 注釈
//-------------------------------------------
.notes {
	position: relative;
	padding-left: 20px;
	font-size: 14px;
	text-indent: 0;

	&::before {
		content: "※";
		position: absolute;
		top: 4px;
		left: 0;
	}

	&--number {
		counter-increment: number;
		padding-left: 30px;

		&::before {
			content: "※"counter(number) " ";
			/* カウンターの値を表示 */
			top: 0;
		}
	}
}

li.notes {
	&::before {
		top: 0;
	}
}

span.notes {
	display: block;

	&::before {
		top: 0;
		line-height: 1.6;
	}
}

.sup {
	font-size: 10px;
}

//==========================================
// キービジュアル
//==========================================

.kv {
	height: getvwPc(302);
	position: relative;
	margin-bottom: 80px;
	background-color: #f6f6f4;

	@include media(sp) {
		height: auto;
		margin-bottom: 40px;
	}

	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
		left: 44%;
		aspect-ratio: 72 / 31;
		background-repeat: no-repeat;
		background-image: url("https://placehold.jp/dddddd/dddddd/720x306.png");
		background-size: contain;
		z-index: 1;

		@include media(sp) {
			background-image: url("https://placehold.jp/dddddd/dddddd/750x760.png");
			background-size: contain;
			position: static;
			width: 100%;
			aspect-ratio: 75/76;
			background-repeat: no-repeat;
		}
	}

	&__in {
		@include media(sp) {
			max-width: 100%;
		}
	}

	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		bottom: -1px;
		background-image: url("../../assets_v2/img/common/kv_wave.svg");
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		aspect-ratio: 128/ 11;
		z-index: 2;

		@include media(sp) {
			aspect-ratio: 125 / 11;
			background-image: url("../../assets_v2/img/common/kv_wave_sp.svg");
			background-size: cover;
		}
	}

	&__ttl {
		height: getvwPc(178);
		display: flex;
		align-items: center;

		@include media(sp) {
			height: auto;
			padding: 40px 0;
			display: block;
		}
	}

	// 第三階層
	//-------------------------------------------

	&__layer3 {
		height: getvwPc(318);

		&::before {
			bottom: 0;
			aspect-ratio: 10/ 1;
			background-image: url("../../assets_v2/img/common/wave_white_01.svg");
			background-size: cover;

			@include media(sp) {
				aspect-ratio: 375 / 47;
				background-image: url("../../assets_v2/img/common/wave_white_01_sp.svg");
				background-size: cover;
			}
		}

		&::after {
			aspect-ratio: 36 / 16.3;
		}
	}

	// 第四階層
	//-------------------------------------------

	&__layer4 {
		height: auto;
		padding-bottom: getvwPc(55);

		@include media(sp) {
			padding-bottom: getvw(110);
		}

		&::before {
			bottom: getvwPc(-3);
			aspect-ratio: 30/ 1.3;
			background-image: url("../../assets_v2/img/common/wave_white_04.svg");
			background-size: cover;

			@include media(sp) {
				aspect-ratio: 30/ 3.3;
				background-image: url("../../assets_v2/img/common/wave_white_04_sp.svg");
				background-size: cover;
			}
		}
	}

	&__no-img {
		&::after {
			content: none;
		}

		@include media(sp) {
			padding-bottom: getvw(110);
		}
	}

	&:has(+ .kv-txt) {
		margin-bottom: 40px;
	}
}

.kv-txt {
	margin-bottom: 80px;
	font-size: 18px;
	line-height: 1.9;

	@include media(sp) {
		font-size: 16px;
		margin-bottom: 40px;
	}
}

//===========================================
// ボタン
//===========================================

// デフォルトボタン
//-------------------------------------------
.btn {
	display: inline-block;
	max-width: 352px;
	width: 100%;
	min-height: 56px;
	padding: 0 40px 0 32px;
	border: 2px solid #005baa;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	font-weight: bold;
	text-decoration: none;
	outline: none;
	transition: all 0.3s;
	background-color: #005baa;
	color: #fff;
	position: relative;
	display: flex;
	justify-content: center;
	border-radius: 50px;
	align-items: center;
	text-align: center;
	font-size: 16px;
	cursor: pointer;

	@include media(sp) {
		max-width: getvw(352);
		width: 100%;
		letter-spacing: 0;

		* {
			pointer-events: none;
		}
	}

	@media only screen and (max-width: 767px) {
		max-width: 100%;
	}

	&__txt {
		display: block;
		position: relative;
		line-height: 1.3;
		padding: 4px 0;

		&--short {
			letter-spacing: -0.03em;
		}
	}

	&__notes {
		line-height: 1.3;
		font-size: 14px;
		text-align: left;
		display: block;
		width: 100%;
		margin-top: -4px;
	}

	@include hover {
		background-color: #fff;
		border-color: #005baa;
		color: #005baa;

		&:after {
			right: 20px;
			border-color: #005baa;

			@include media(sp) {
				right: 18px;
			}
		}
	}

	&--notes {
		flex-wrap: wrap;
	}

	&--open {
		&:after {
			transform: rotate(-45deg);
			top: calc(50% - 7px / 2 / 2);
		}
	}

	&--no-radius {
		border-radius: 12px;
	}

	// 検索ボタン
	//-------------------------------------------
	&--search {
		@media only screen and (max-width: 767px) {
			max-width: 200px;
		}
	}

	// 短いボタン
	//-------------------------------------------
	&--mini {
		max-width: 200px;
		padding: 0 30px 0 24px;

		@media only screen and (max-width: 767px) {
			max-width: 200px;
		}
	}

	// 戻るボタン
	//-------------------------------------------
	&--back {
		padding: 0 15px 0 30px;

		.icon-arrow {
			right: auto;
			left: 15px;
			transform: scale(-1, 1);
		}
	}

	// 別窓アイコン
	//-------------------------------------------
	&--blank {
		border-radius: 5px;
		height: 96px;
		background-color: #fff;
		border: 1px solid #707070 !important;
		color: #005baa;

		@include media(sp) {
			height: 92px;
			border-radius: 8px;
			border: 2px solid #707070 !important;
		}

		&::after {
			content: none;
		}
	}

	// アイコン
	//-------------------------------------------
	&--icon-img-01 {
		padding-left: 16px;
	}

	&--icon-img-02 {
		.icon-img {
			width: 24px;
		}
	}

	&--icon-img-03 {
		.icon-img {
			width: 32px;
		}
	}

	// 赤ボタン
	//-------------------------------------------
	&--red {
		background-color: #db342e;
		border-color: #db342e;

		@include hover {
			color: #db342e;
			border-color: #db342e;
		}
	}

	// カラー反転
	//-----------------------------------------
	&--reversal {
		background-color: #fff;
		border: 2px solid #005baa;
		color: #005baa;

		&:after {
			border-color: #005baa;
		}

		@include hover {
			background-color: #005baa;
			color: #fff;

			&:after {
				border-color: #fff;
			}
		}
	}

	// 住所検索ボタン
	//-----------------------------------------
	&--post {
		width: auto;
		min-height: 100%;
		padding: 0 20px;

		@include media(sp) {
			width: auto;
		}
	}

	// もっと見る
	//-----------------------------------------
	&--more {
		.icon-arrow {
			transform: rotate(90deg);
		}

		&.open {
			.icon-arrow {
				transform: rotate(-90deg);
			}
		}
	}

	// アンカー中央配置
	//-----------------------------------------
	&--anchor {
		border-radius: 8px;
		max-width: 256px;
		height: 100%;
		line-height: 1.2;
		min-height: 72px;

		@include media(sp) {
			font-size: 14px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			max-height: 100%;
			text-align: center;
			padding: 8px 14px 16px;
		}

		.icon-arrow {
			transform: rotate(90deg);

			@include media(sp) {
				bottom: 6px;
				left: 0;
				right: 0;
				top: auto;
				margin: 0 auto;
			}
		}

		&--01 {
			padding: 0 40px 0 16px;

			.btn__txt {
				width: 100%;
			}

			.icon--img {
				width: 40px;
				height: 40px;
			}

			@include media(sp) {
				align-items: baseline;

				.icon--img {
					width: 48px;
					height: 48px;
					margin: 0 auto;
					display: block;
				}
			}
		}

		&--02 {
			padding: 0 20px;

			.btn__txt {
				padding: 14px 0 30px;

				@include media(sp) {
					padding: 8px 0;
				}
			}

			.icon-arrow {
				position: absolute;
				left: calc(50% - 12px / 2);
				bottom: 8px;
				top: auto;
			}
		}

		&--03 {
			padding: 0 40px 0 16px;
			max-width: 100%;
			justify-content: flex-start;

			@include media(sp) {
				padding: 0 40px 0 16px;
				text-align: left;

				.icon-arrow {
					top: calc(50% - 11px / 2);
					right: 15px;
					left: auto;
					margin: 0;
				}
			}
		}
	}

	&--img {
		@include media(sp) {
			padding-left: getvw(50);
			padding-right: getvw(50);
		}

		@media only screen and (max-width: 767px) {
			max-width: 100%;
		}
	}

	&--center {
		margin: 0 auto;
	}
}

// アンカーリスト
//-----------------------------------------

.anchor-list {
	gap: 24px 32px;
	display: flex;
	flex-wrap: wrap;

	li {
		@include media(sp) {
			width: 100%;
		}
	}
}

// ブランドサイトへのボタン
//-------------------------------------------
.btn-brand-s,
.btn-brand-l {
	border: 1px solid #707070;
	display: block;
	border-radius: 4px;
	cursor: pointer;
	position: relative;

	@include hover {
		border-color: #005baa;

		&:before {
			content: "";
			position: absolute;
			border-radius: 4px;
			top: -2px;
			right: -2px;
			bottom: -2px;
			left: -2px;
			border: 2px solid #005baa;
		}
	}

	a {
		position: relative;
		z-index: 1;
		display: block;
	}

	img {
		border-radius: 4px;
		display: block;
	}
}

.btn-brand-s {
	max-width: 148px;

	@include media(sp) {
		max-width: 85%;
		margin: 0 auto;
	}
}

.btn-brand-l {
	max-width: 448px;

	@include media(sp) {
		max-width: 100%;
	}
}

// アイコン
//-----------------------------------------
.icon {
	position: static;
	margin-right: 10px;

	&--img {
		width: 25px;
	}
}

.icon-blank {
	display: flex;
	position: absolute;
	width: 13px;
	top: calc(50% - (13px) / 2);
	right: 15px;
}

.icon-arrow {
	display: flex;
	position: absolute;
	width: 8px;
	top: calc(50% - (11px) / 2);
	right: 15px;

	@include media(sp) {}

	&--anchor {
		transform: rotate(90deg);
	}
}

.icon-pdf {
	display: flex;
	position: absolute;
	width: 19px;
	top: calc(50% - (19px) / 2);
	right: 15px;
}

.icon-img {
	width: 40px;
	margin-right: 8px;
}

.icon-anchor {
	display: flex;
	position: absolute;
	width: 12px;
	bottom: 10px;
	left: calc(50% - 12px / 2);

	@include media(sp) {}
}

.icon-open {
	position: absolute;
	width: 32px;
	top: calc(50% - 32px / 2);
	right: 0;

	@include media(sp) {}
}

.icon-products {
	width: 100%;

	@include media(sp) {}
}

.icon-top {
	width: 13px;
	display: inline-block;
	vertical-align: baseline;
	margin: -2px 7px 0 0;

	@include media(sp) {}
}

//===========================================
// テキストリンク
//===========================================

.txt-link {
	text-decoration: underline;
	color: #005baa;
	position: relative;
	display: inline;

	@include hover {
		text-decoration: none;
	}

	.icon-img {
		width: 16px;
		display: inline;
		position: static;
		vertical-align: middle;
		margin: -3px 0 0 8px;
	}

	&--back {
		.icon-img {
			margin: -3px 8px 0 0;
			transform: scaleX(-1);
		}
	}

	&--anchor {
		.icon-img {
			margin: -3px 0 0 8px;
			transform: rotate(90deg);
		}
	}
}

//===========================================
// ホバー
//===========================================

// ボーダーホバー
//-----------------------------------------
.hover-border {
	height: 100%;
	display: block;
	position: relative;
	cursor: pointer;

	@include hover {
		position: relative;
		transition: 0.3s;

		&:before {
			content: "";
			position: absolute;
			border-radius: 8px;
			top: -2px;
			right: -2px;
			bottom: -2px;
			left: -2px;
			z-index: 2;
			border: 2px solid #005baa;
		}
	}
}

//===========================================
// タグ
//===========================================

.tag {
	border-radius: 4px;
	display: inline-block;
	padding: 3px 8px 2px;
	font-size: 14px;
	transition: 0.3s;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;

	&::before,
	&::after {
		content: none;
	}

	&--01 {
		background-color: #ccf2ff;
	}

	&--02 {
		background-color: #ccf2ff;
	}

	&--03 {
		background-color: #005baa;
		border: 1px solid #005baa;
		color: #fff;

		&.sold-out {
			background-color: #707070;
			border: 1px solid #707070;
		}
	}

	&--04 {
		border: 1px solid #707070;
		font-size: 13px;
		border-radius: 0;
		font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
	}

	&--05 {
		background-color: #db342e;
		color: #fff;
		vertical-align: bottom;
	}
}

a {

	@include hover {
		.tag {

			&--01,
			&--02 {
				background-color: #fff;
			}

			&--03 {
				background-color: #fff;
				color: #005baa;

				&.sold-out {
					background-color: #fff;
					color: #707070;
				}
			}
		}
	}
}

//===========================================
// 50音で絞り込む
//===========================================

.searchOrderList {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 32px;
	max-width: 1120px;
	width: 100%;
	margin: 0 auto;
	border: 2px solid #005baa;
	border-radius: 12px;

	@include media(sp) {
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 24px 16px 24px 24px;
	}

	.searchOrderList__ttl {
		font-size: 20px;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		width: 160px;
		display: contents;

		@include media(sp) {
			font-size: 18px;
			width: 100%;
		}
	}
}

.orderList {
	position: relative;
	display: block;
	font-size: 0;
	display: flex;
	align-items: center;
	gap: 32px;

	@include media(sp) {
		flex-wrap: wrap;
		width: 100%;
		gap: 16px;
	}

	.all-btn {
		@include media(sp) {
			width: 100%;
		}
	}

	&__box {
		display: flex;
		flex-wrap: wrap;
		gap: 18px;
	}

	.orderList__item {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;

		&:has(> input[type="radio"]:disabled) {
			pointer-events: none;
		}

		&:last-of-type {
			margin-right: 0;
		}

		input {
			appearance: none;
			position: absolute;
			top: calc(50% - 24px / 2);
			left: calc(50% - 24px / 2);

			&:focus-visible {
				outline: none;
			}

			&:focus-visible {
				&+label.orderList__txt {
					outline: 4px solid #007921;
					outline-offset: 4px;
				}
			}
		}

		input[type="radio"]:checked+label.orderList__txt {
			border: #005baa solid 1px;
			background-color: #005baa;
			color: #fff;
		}

		&.all {
			width: 97px;

			.orderList__txt {
				width: 100%;
			}
		}

		.orderList__txt {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 44px;
			height: 44px;
			border: #005baa solid 2px;
			border-radius: 22px;
			box-sizing: border-box;
			background-color: #fff;
			font-size: 16px;
			font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
			color: #005baa;
			transition: all 0.3s;

			@include media(sp) {
				font-size: 16px;
			}

			cursor: pointer;

			@include hover {
				border: #005baa solid 1px;
				background-color: #005baa;
				color: #fff;
			}

			@include media(sp) {
				width: 40px;
				height: 40px;
			}
		}

		input[type="radio"]:disabled+label.orderList__txt {
			background-color: #f6f6f4;
			border: #767676 solid 1px;
			cursor: not-allowed;
			pointer-events: none;
			color: #333;
		}
	}
}

// ページネーション
//===========================================
.pagination {
	display: flex;
	justify-content: center;

	&__item {
		a {
			display: inline-block;
			text-align: center;
			line-height: 32px;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin: 0 10px;
			transition: 0.2s ease-in-out;
			white-space: nowrap;
			color: #767676;

			@include media(sp) {
				margin: 0 5px;
			}

			@include hover {
				background: #005baa;
				color: #fff;
			}

			&.current {
				background: #005baa;
				color: #fff;
			}

			&.next {
				border: none;

				@include hover {
					background: none;
					border: none;
					color: #fff;
				}

				&.current {
					background: none;
					border: none;
					color: #fff;
				}
			}
		}
	}
}

// Languageプルダウン
//===========================================

.language {
	z-index: 3 !important;
	width: 132px;

	@include media(sp) {
		top: 0px;
		width: 140px;
	}
}

#language__select {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	width: 132px;
	height: 32px;
	border: 2px solid #005baa;
	padding-left: 8px;
	border-radius: 4px 4px 4px 4px;
	cursor: pointer;

	@include media(sp) {
		width: 140px;
		height: 32px;
	}

	// &:focus{
	// 	border-radius: 0;
	// }
	.language__icon--earth {
		width: 18px;
		margin-right: 6px;
	}

	.language__txt {
		color: #005baa;
		font-size: 14px;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		font-weight: bold;
		line-height: 56px;

		@include media(sp) {
			line-height: 28px;
		}
	}

	.language__icon--down {
		position: absolute;
		top: 50%;
		right: 8px;
		transform: translate(0, -50%);
		width: 10px;
		height: auto;

		@include media(sp) {
			width: 10px;
		}
	}
}

// プルダウンリスト
#language__menu {
	position: absolute;
	box-sizing: border-box;
	width: 132px;
	border: 2px solid #005baa;
	border-top: none;
	border-radius: 0 0 4px 4px;
	background-color: #fff;

	@include media(sp) {
		width: 140px;
	}
}

// プルダウンリストアイテム
#language__menu li {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 32px;
	box-sizing: border-box;
	border-bottom: #005baa solid 1px;
	z-index: 1;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #005baa;
	font-size: 14px;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	line-height: 1.2;
	z-index: 1;
	cursor: pointer;

	button {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		color: #005baa;
		font-size: 14px;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		font-weight: bold;
		line-height: 1.2;
		z-index: 1;
		transition: .3s;
	}

	&:last-child {
		border-bottom: none;
	}
}

// OPEN時
#language__select[aria-expanded="true"] {
	border-radius: 4px 4px 0 0;

	.language__icon--down {
		transform: translate(0, -50%) rotate(180deg);
	}
}

/* focus styling */
// プルダウンリストfocus
#language__menu li button:focus,
#language__menu li.selected button{
	position: relative;
	background: #005baa;
	color: #fff;
}

#language__menu li button {
	position: relative;

	&:focus {
		background: #005baa;
		color: #fff;
		outline: none;

		&-visible {
			outline: 4px solid #007921;
			outline-offset: 4px;
		}
	}
}

// WEBSHOPアコーディオン
//===========================================
.detailsAccordion {
	z-index: 2;
	position: relative;

	// アコーディオンヘッダー
	.accordion__header {
		position: relative;
		z-index: 1;
		background-color: transparent;

		// close時
		button.detailsAccordion__trigger[aria-expanded="false"] {
			border-radius: 28px 28px 28px 28px;

			&:focus {
				outline: none;

				&-visible {
					outline: #007921 solid;
				}
			}

			@include media(sp) {
				border-radius: 28px 28px 28px 28px;
			}

			.accordion__toggle--minus {
				display: none;
			}

			.accordion__toggle--plus {
				display: block;
			}
		}

		// open時
		button.detailsAccordion__trigger[aria-expanded="true"] {
			border-radius: 28px 28px 0px 0px;

			&:focus {
				outline: none;

				&-visible {
					outline: #007921 solid;
				}
			}

			@include media(sp) {
				border-radius: 28px 28px 0 0;
			}

			.accordion__toggle--minus {
				display: block;
			}

			.accordion__toggle--plus {
				display: none;
			}
		}
	}

	// アコーディオン内容
	.accordion__panel {
		position: absolute;
		width: 272px;
		padding: 0;

		@include media(sp) {
			max-width: getvw(352);
			width: 100%;
			left: calc(50% - getvw(352) / 2);
		}

		@media only screen and (max-width: 767px) {
			max-width: 100%;
			width: 100%;
			left: 0;
		}

		.webShop__list {
			position: relative;
			background-color: #005baa;
			border-radius: 0 0 28px 28px;

			li {
				position: relative;
				background-color: #fff;
				border-radius: 4px;
				height: 54px;
				border: #005baa solid 2px;
				border-top: #005baa solid 0px;

				&:last-child {
					border-radius: 4px 4px 28px 28px;

					a {
						&:focus-visible {
							border-radius: 0px 0px 28px 28px;
						}
					}
				}

				a {
					position: relative;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 1;

					span {
						font-size: 16px;
						font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
						line-height: 1.2;
						color: #005baa;
						margin-left: 8px;
					}

					.icon--shop {
						width: 66px;
						margin: 0 auto;
					}

					.icon--link {
						position: absolute;
						top: 50%;
						right: 16px;
						transform: translate(0, -50%);
						width: 8.12px;
						height: auto;

						@include media(sp) {
							width: 8.12px;
						}
					}
				}
			}
		}
	}

	.accordion__panel[hidden] {
		display: none;
	}
}

.shop__web__btn {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	transition: none;
	width: 272px;
	height: 56px;
	border-radius: 50px;
	background-color: #005baa;
	border: #005baa solid 2px;
	box-sizing: border-box;
	color: #fff;
	font-size: 18px;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	cursor: pointer;

	@include media(sp) {
		max-width: getvw(352);
		width: 100%;
		margin: 0 auto;
	}

	@media only screen and (max-width: 767px) {
		max-width: 100%;
	}

	.icon--cart {
		width: 28px;
		height: auto;
		margin-right: 8px;
	}

	.accordion__toggle--plus,
	.accordion__toggle--minus {
		position: absolute;
		top: 50%;
		right: 12px;
		transform: translate(0, -50%);
		width: 16px;
		height: auto;
	}

	&:focus-visible {
		outline: #007921 solid;
		outline-offset: 4px;
		border-radius: 50px;
	}
}

//===========================================
// カード
//===========================================

.card {
	border: 1px solid #707070;
	border-radius: 8px;
	background-color: #fff;

	&__link {
		height: 100%;
		display: block;
		position: relative;
		border-radius: 8px;

		@include hover {
			background-color: #eaf6fc;

			&:before {
				content: "";
				position: absolute;
				border-radius: 8px;
				top: -2px;
				right: -2px;
				bottom: -2px;
				left: -2px;
				z-index: 1;
				border: 2px solid #005baa;
				/* 枠線を2pxの青色に設定 */
			}
		}
	}

	&__info {
		&--01 {
			padding: 24px;
			border-top: 1px solid #ddd;
		}

		&--02 {
			padding: 16px;
		}
	}

	&--01 {
		padding: 40px 24px;

		@include media(sp) {
			padding: 32px 16px;
		}

		&__txt {
			min-height: 72px;
			font-size: 18px;
			line-height: 1.3;

			@include media(sp) {
				min-height: auto;
				font-size: 24px;
			}
		}

		&__img {
			width: 180px;
			margin: 0 auto;
			display: block;
			background-color: #fff;
		}

		.list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			flex-wrap: wrap;
			gap: 8px;

			@include media(sp) {
				grid-template-columns: 1fr;
				margin: 0 auto;
			}
		}
	}

	&--02 {
		position: relative;

		&__img {
			border-bottom: 1px solid #ddd;
			position: relative;
			padding: 40px 0 24px;
			border-radius: 8px 8px 0 0;
			background-color: #fff;

			img {
				width: 136px;
				display: block;
				margin: 0 auto;
			}
		}

		.tag--03 {
			position: absolute;
			top: 8px;
			right: 8px;
		}

		.info {
			padding: 24px;
			transition: 0.3s;
			border-radius: 0 0 8px 8px;

			.list {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
			}
		}
	}

	&--03 {
		padding: 40px 24px;

		&__img {
			width: 136px;
			display: block;
			margin: 0 auto;
		}

		.list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			flex-wrap: wrap;
			gap: 8px;

			@include media(sp) {
				grid-template-columns: 1fr;
				margin: 0 auto;
			}
		}
	}

	&--04 {
		max-width: 256px;

		@include media(sp) {
			max-width: 100%;
		}

		&__top {
			padding: 24px;

			img {
				width: 136px;
				display: block;
				margin: 0 auto;
			}

			.list {
				display: flex;
				justify-content: flex-end;
				gap: 0 24px;
			}
		}

		&__bottom {
			padding: 24px;
			background-color: #f6f6f4;
			border-radius: 0 0 8px 8px;

			h4 {
				font-size: 16px;
			}
		}
	}

	&--05 {
		max-width: 544px;
		border: none;
		display: flex;
		flex-direction: column;

		@include media(sp) {
			position: relative;
			left: 0;
			width: 100%;
			max-width: 100%;
			gap: 8px;
		}

		// メイン画像
		.mainImg__tabs {
			border: #707070 solid 1px;
			border-radius: 8px;
			margin-bottom: 8px;
			width: 100%;
			display: flex;

			@include media(sp) {
				display: block;
			}

			.tabs__img {
				display: block;
				overflow: hidden;
				padding: 32px;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		[role="tabpanel"] {
			&.is-hidden {
				display: none;
			}

			&:focus-visible {
				outline: 4px solid #007921;
				outline-offset: 4px;
			}
		}

		// 切り替えサムネイル
		.mainImg__tablist {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			gap: 8px;
			order: 2;

			@include media(sp) {
				max-width: 100%;
				max-height: 100%;
			}

			button {
				position: relative;
				border: #767676 solid 1px;
				border-radius: 8px;
				aspect-ratio: 1 / 1;
				cursor: pointer;
				opacity: 0.8;

				span {
					display: block;
					width: 100%;
					height: 100%;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				&:focus {
					outline: none;

					&-visible {
						border-radius: 8px;
						outline: #007921 solid;
					}
				}
			}
		}

		[role="tab"][aria-selected="true"] {
			opacity: 1;
			border: 2px solid #005baa;
		}

		[role="tab"][aria-selected="false"] {
			opacity: 0.8;
		}

		[role="tab"] span.focus {
			opacity: 1;
			padding: 8px;
		}

		// [role="tab"]:hover span.focus,
		// [role="tab"]:focus-visible span.focus,
		// [role="tab"]:active span.focus {
		// 	opacity: 1;
		// }

		[role="tab"] {
			@include hover {
				span.focus {
					opacity: 1;
				}
			}
		}

	}

	&--06 {
		&__img {
			aspect-ratio: 16 / 9;
		}

		a {
			@include hover {
				background-color: #eaf6fc;
			}
		}
	}

	&--06 {
		&__img {
			border-radius: 8px 8px 0 0;
		}

		&__info {
			border-top: 1px solid #ddd;
			padding: 24px;
			transition: 0.3s;
			border-radius: 0 0 8px 8px;
		}
	}

	&--07 {
		&__img {
			border-radius: 8px 8px 0 0;
		}

		&__info {
			border-radius: 0 0 8px 8px;

			img {
				width: auto;
			}
		}
	}

	&--08 {
		padding: 16px;
	}

	&--09 {

		&__img {
			width: 46%;
			overflow: hidden;
			border-radius: 8px 0 0 8px;

			@include media(sp) {
				order: 1;
				border-radius: 8px 8px 0 0;
				width: 100%;
			}
		}

		&__ttl {
			width: 54%;
			transition: 0.3s;
			display: flex;
			align-items: center;
			position: relative;
			padding: 20px;

			@include media(sp) {
				order: 2;
				width: 100%;
				min-height: 70px;
				border-left: none;
			}
		}

		a {
			display: flex;
			border-radius: 10px;
			justify-content: space-between;
			flex-wrap: wrap;

			@include hover {
				.card--09__ttl {
					color: #005baa;
				}
			}
		}
	}

	&--10 {
		&__img {
			border-radius: 8px 8px 0 0;
		}

		&__ttl {
			position: relative;
			display: flex;
			align-items: center;
			padding: 24px;
			border-top: 1px solid #ddd;

			@include media(sp) {
				padding: 20px;
			}
		}
	}

	&--11 {
		&__img {
			padding: 24px;
			border-radius: 8px 8px 0 0;
			background-color: #fff;
			border-bottom: 1px solid #ddd;
		}

		&__ttl {
			padding: 24px;
		}
	}

	&--12 {
		&__img {
			border-radius: 8px 8px 0 0;
			background-color: #fff;
			border-bottom: 1px solid #ddd;
			aspect-ratio: 1;
		}

		&__ttl {
			padding: 8px 16px;
			min-height: 74px;
			display: flex;
			align-items: center;

			@include media(sp) {
				align-items: flex-start;
			}

			.logo {
				width: 24px;
				margin-right: 16px;

				@include media(sp) {
					margin-right: 8px;
				}
			}
		}
	}
}

.card__link-in {
	height: 100%;
	display: block;
	position: relative;
	cursor: pointer;
	border-radius: 8px;

	@include hover {
		.card {
			position: relative;
			transition: 0.3s;

			&:before {
				content: "";
				position: absolute;
				border-radius: 8px;
				top: -2px;
				right: -2px;
				bottom: -2px;
				left: -2px;
				z-index: 2;
				border: 2px solid #005baa;
				/* 枠線を2pxの青色に設定 */
			}
		}
	}
}

//==========================================
// Tab
//==========================================

.tab {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: calc(1120px + 30px * 2);
	margin: 0 auto;
	gap: 0 2.8%;

	@include media(sp) {
		padding: 0;
	}

	&__item {
		width: 33.3333333%;
		flex: 1 1 0%;
		text-align: center;
		color: #707070;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #767676;
		background-color: #f6f6f4;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		font-size: 18px;

		@include media(sp) {
			font-size: 14px;
			line-height: 1.2;
			height: 56px;
			border-bottom: none;
			border-radius: 5px 5px 0 0;
			width: 32%;
		}

		&.current {
			background-color: #fff;
			border-color: #005baa;
			position: relative;
			padding-bottom: 2px;
			margin-bottom: -2px;
			color: #005baa;

			@include media(sp) {}

			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 100%;
				background-color: #fff;
				height: 2px;
				bottom: -2px;
			}
		}
	}
}

.tab-contents {
	background-color: #fff;
	width: 100vw;
	margin: 0 calc(50% - 50vw);
	border-top: 2px solid #005baa;

	@include media(sp) {
		padding: 15px 0 0;
	}

	[role="tabpanel"] {
		padding: 30px 0 0;

		&.is-hidden {
			display: none;
		}

		&:focus-visible {
			outline: 4px solid#007921;
			outline-offset: 4px;
		}
	}
}

//===========================================
// アコーディオン
//===========================================

.accordion {
	position: relative;

	&__item {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		position: relative;

		@include media(sp) {
			font-size: 16px;
			border-bottom: none;
			position: relative;
		}

		&--01 {
			width: 100vw;
			margin: 0 calc(50% - 50vw);
			background-color: #eaf6fc;

			&:not(:last-of-type) {
				margin-bottom: 16px;
			}

			button {
				.txt {
					font-size: 18px;
					display: block;
					width: 100%;
					font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

					@include media(sp) {
						font-size: 16px;
					}
				}
			}

			.box {
				&--01 {
					background-color: #fff;
					border-radius: 8px;
					padding: 24px;
				}
			}

			.img {
				&--01 {
					max-width: 325px;
				}

				&--02 {
					max-width: 512px;
				}
			}
		}

		&--02 {
			background-color: #fff;
			border-radius: 8px;

			.accordion__contents {
				padding-bottom: 24px;
			}

			&:not(:last-of-type) {
				margin-bottom: 24px;

				@include media(sp) {
					margin-bottom: 8px;
				}
			}

			.accordion__ttl,
			.accordion__trigger {
				@include media(sp) {
					min-height: 56px;
				}
			}
		}

		&--03 {
			border-bottom: 1px solid #ddd;
			width: 100vw;
			margin: 0 calc(50% - 50vw);

			@include media(sp) {
				border-bottom: 1px solid #ddd;
				font-size: 16px;
				position: relative;
			}

			.accordion__ttl {
				font-size: 24px;
				min-height: 77px;

				@include media(sp) {
					font-size: 16px;
					min-height: 56px;
				}

				button {
					min-height: 77px;

					@include media(sp) {
						min-height: 56px;
					}
				}
			}
		}
	}

	&__ttl,
	&__sub-ttl {
		padding: 8px 0;
		min-height: 98px;
		position: relative;
		width: 100%;

		button {
			min-height: 98px;
			font-size: inherit;
			font-weight: inherit;
			text-align: left;
			padding-right: 50px;
			font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;

			@include media(sp) {
				padding-right: 36px;
			}
		}
	}

	&__ttl {
		&--short {
			min-height: 78px;

			button {
				min-height: 78px;
				align-items: center;
				display: flex;
				justify-content: flex-start;
			}
		}
	}

	&__sub-ttl {
		min-height: 72px;
		background-color: #fff;

		button {
			min-height: 72px;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}

	&__trigger {
		cursor: pointer;
		width: 100%;
		gap: 0 16px;
		position: relative;

		.trigger__txt {
			position: relative;
			padding: 0 0 0 60px;

			@include media(sp) {
				padding: 0 0 0 55px;
			}

			.ttl-q {
				position: absolute;
				top: 0;
				left: 0;
				text-align: right;
			}
		}
	}

	//販売店検索に関する　よくあるご質問ページ用
	.store-search-trigger-txt {
		position: relative;
		padding: 0 0 0 30px;

		@include media(sp) {
			padding: 0 0 0 24px;
		}
	}

	.store-search-ttl-q {
		position: absolute;
		top: 2px;
		left: 0;
		text-align: right;
	}

	&__icon-img {
		width: 40px;
	}

	&__close {
		text-align: right;
		margin-left: auto;
		display: flex;
		width: auto !important;
		align-items: center;
		position: relative;
		cursor: pointer;

		img {
			right: 0;
		}
	}

	&__contents {
		display: none;
		width: 100%;
		margin-top: 16px;

		.answer__txt {
			position: relative;
			padding-left: 2em;

			.ttl-a {
				position: absolute;
				top: 0;
				left: 0;
			}

			&.store-search-answer-txt {
				padding-left: 30px;

				@include media(sp) {
					padding-left: 24px;
				}

				.ttl-a {
					left: 0;
				}
			}
		}
	}

	.icon-open {
		position: absolute;
		pointer-events: none;
		top: calc(50% - 32px / 2);
		width: 32px;

		@include media(sp) {
			top: calc(50% - 25px / 2);
			width: 25px;
		}
	}

	.close-txt {
		padding: 32px 40px 32px 0;
		font-size: 16px;

		@include media(sp) {
			padding: 20px 40px 20px 0;
		}
	}
}

/* Show the content when aria-expanded is "true" */
.accordion__trigger[aria-expanded="true"]+.accordion__contents {
	display: block;
}

// アコーディオンヘッダー
.accordion__header {
	position: relative;
	z-index: 1;
	background-color: #eaf6fc;

	button.accordion__trigger {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0 40px;

		@include media(sp) {
			margin-top: 20px;
			padding: 0 24px;
			min-height: 65px;
			border-radius: 0 0 0 0;
		}

		&:focus-visible {
			border-radius: 8px;
			outline: #007921 solid;

			@include media(sp) {
				border-radius: 0px;
			}
		}
	}

	// close時
	.accordion__trigger[aria-expanded="false"] {
		&+.accordion__contents {
			display: none;
		}
	}

	// open時
	.accordion__trigger[aria-expanded="true"] {
		&+.accordion__contents {
			display: block;
		}
	}
}

// アコーディオン内容
.accordion__panel {
	position: relative;
	padding: 16px 40px;
	border-radius: 0 0 8px 8px;

	@include media(sp) {
		padding: 12px 24px;
		border-radius: 0 0 0 0;
	}

	&[hidden] {
		display: none;
	}
}

//==========================================
// リスト
//==========================================

.base-list {
	padding-left: 20px;

	li {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&--pd-0 {
		padding: 0;
	}

	&--01 {
		li {
			position: relative;

			&::before {
				content: "・";
				position: absolute;
				left: -20px;
				top: 0;
			}
		}
	}

	&--02 {
		padding-left: 24px;
		counter-reset: number 0;

		li {
			text-indent: -24px;

			&::before {
				counter-increment: number 1;
				content: counter(number) " ";
				margin-right: 8px;
			}
		}

		&.parentheses {
			li {
				list-style-type: none;
				counter-increment: cnt;

				&:before {
					content: "("counter(cnt) ")";
					position: relative;
    			left: -4px;
					margin-right: 0;
				}
			}
		}

		&.parentheses-02 {
			li {
				list-style-type: none;
				counter-increment: cnt;

				&:before {
					content: counter(cnt) "）";
					margin-right: 0;
					position: static;
				}
			}
		}

		&.alphabetical {
			li {
				list-style-type: none;
				counter-increment: alphabet;

				&:before {
					content: counter(alphabet, lower-alpha) ". ";
					margin-right: 5px;
				}
			}
		}
	}

	&--03 {
		padding: 0;

		.list-item {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: baseline;

			&:not(:last-child) {
				padding-bottom: 16px;
			}

			@include media(sp) {
				flex-wrap: wrap;
			}

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
				margin-bottom: 16px;
			}

			&__info {
				display: flex;
				width: 70%;

				@include media(sp) {
					flex-wrap: wrap;
					width: 100%;
				}

				.date {
					width: 160px;

					@include media(sp) {
						width: 100%;
						margin-bottom: 8px;
						font-size: 13px;
					}
				}

				.txt {
					width: calc(100% - 160px);

					@include media(sp) {
						width: 100%;
						margin-bottom: 16px;
					}
				}
			}

			&__link {
				display: flex;
				gap: 0 24px;

				@include media(sp) {
					font-size: 14px;
					flex-wrap: wrap;
					gap: 0 16px;
					margin-left: auto;
				}

				li {
					margin: 0 !important;
				}
			}
		}
	}

	&--04 {
		padding: 0;

		li {
			border-bottom: 1px solid #ddd;

			&:not(:last-child) {
				margin-bottom: 24px;
			}

			&.layout {
				border: none;
				padding: 0;

				a {
					padding: 24px;
				}
			}

			a {
				padding: 0 0 24px 0;
				display: block;
				text-decoration: none;

				@include hover {
					.tag {
						background-color: #ccf2ff;
					}

					.txt-link {
						text-decoration: none;
					}
				}

				.s-txt {
					color: #333;
				}
			}

			.list-head {
				align-items: center;
				margin-bottom: 8px;
				gap: 16px;

				img {
					width: 40px;
				}

				.tag {
					color: #333;
				}
			}
		}
	}

	&--05 {
		padding: 0;

		.list-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 24px;

			@include media(sp) {
				flex-wrap: wrap;
			}

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
				margin-bottom: 24px;
			}

			&__left {
				width: 80%;
				display: flex;
				align-items: center;
				gap: 0 24px;

				@include media(sp) {
					flex-wrap: wrap;
					margin-bottom: 16px;
					justify-content: space-between;
					align-items: flex-start;
					width: 100%;
					gap: 0;
				}
			}

			&__img {
				width: 160px;

				@include media(sp) {
					width: 33%;
				}
			}

			&__info {
				width: calc(100% - 160px - 24px);

				@include media(sp) {
					width: 100%;
				}
			}

			.info-head {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 8px 16px;
				margin-bottom: 16px;

				&__list {
					li {
						margin: 0;
					}
				}
			}

			.info-head-sp {
				width: 100%;

				.info-head {
					.date {
						font-size: 13px;
					}
				}
			}

			&__right {
				margin-left: auto;

				li {
					&:not(:last-child) {
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	&--06 {
		padding: 0;

		li:not(:last-child) {
			margin-bottom: 24px;
		}
	}
}

.important-box {
	border-radius: 8px;
	border: 1px solid #ddd;
	overflow: hidden;

	&__ttl {
		padding: 16px 24px;
		background-color: #ffd9d9;
		border-bottom: 1px solid #ddd;
	}

	&__info {
		padding: 16px 24px;

		@include media(sp) {
			padding: 20px;
		}
	}
}

//==========================================
// その他製品情報
//==========================================

.other-sec {
	text-align: center;

	@include media(sp) {
		text-align: left;
	}

	.flex__column-2 {
		width: 80%;
		margin: 0 auto;

		@include media(sp) {
			width: 100%;
		}

		li {
			width: 352px;

			@include media(sp) {
				max-width: getvw(352);
			}

			@media only screen and (max-width: 767px) {
				width: 100%;
				max-width: 100%;
			}

			a {
				height: 100%;
				max-width: 100%;
			}
		}
	}
}

//==========================================
// 動画モーダル
//==========================================
.mfp-container {
	padding: 0 24px;

	.movie-modal {
		position: relative;
		margin: 0 auto;
		opacity: 1;
		visibility: visible;
		aspect-ratio: 16/9;
		max-width: 853px;
		width: 80%;
		height: auto;
	}

	.mfp-close {
		position: absolute;
		display: block;
		top: -60px;
		right: 0;
		aspect-ratio: 1;
		width: 60px;
		height: auto;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			height: 2px;
			background: #fff;
			width: 34px;
			transform-origin: 0% 0%;
		}

		&::before {
			transform: rotate(45deg) translate(-50%, -50%);
		}

		&::after {
			transform: rotate(-45deg) translate(-50%, -50%);
		}
	}
}

//==========================================
// 表組み
//==========================================

.table-scroll {
	@include media(sp) {
		overflow-x: scroll;
	}
}

.table {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
	border-radius: 8px;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	background-color: #fff;

	tbody {
		width: 100%;
		display: table;
	}

	th,
	td {
		padding: 16px;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		text-align: left;
	}

	th {
		width: 30%;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;

		@include media(sp) {
			width: 40%;
		}
	}

	td {
		width: 70%;

		@include media(sp) {
			width: 60%;
		}
	}

	caption {
		text-align: left;
	}

	&--01 {
		tr {
			&:first-child:last-child {
				th {
					border-radius: 8px 0 0 8px;
				}

				td {
					border-radius: 0 8px 8px 0;
				}
			}

			&:first-child {
				th {
					border-radius: 8px 0 0 0;
				}

				td {
					border-radius: 0 8px 0 0;
				}
			}

			&:last-child {
				th {
					border-radius: 0 0 0 8px;
				}

				td {
					border-radius: 0 0 8px 0;
				}
			}

			th {
				background-color: #005baa;
				color: #fff;
			}
		}

		&.sp-table {
			@include media(sp) {
				tr {
					&:first-child th {
						border-radius: 8px 8px 0 0;
					}

					&:last-child th {
						border-radius: 0;
					}

					&:first-child td {
						border-radius: 0;
					}

					&:last-child td {
						border-radius: 0 0 8px 8px;
					}
				}

				th,
				td {
					width: 100%;
					display: block;
				}
			}
		}
	}

	&--02 {
		@include media(sp) {
			display: inline-table;
		}

		thead {
			th {
				border-radius: 8px 8px 0 0;
				background-color: #005baa;
				color: #fff;
			}
		}

		tbody {
			tr {
				&:last-child {
					th {
						border-radius: 0 0 0 8px;
					}

					td {
						border-radius: 0 0 8px 0;
					}
				}
			}

			th {
				width: 25%;
				font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

				@include media(sp) {
					width: 35%;
				}
			}

			td {
				width: 75%;
				text-align: right;

				@include media(sp) {
					width: 65%;
				}

				&.full {
					text-align: left;
					width: 100%;
				}
			}
		}
	}

	&--03 {
		table-layout: fixed;
		font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

		@include media(sp) {
			display: inline-table;
		}

		thead {
			th {
				background-color: #005baa;
				color: #fff;
				text-align: center;
				width: 100%;

				&:first-child {
					border-radius: 8px 0 0 0;
				}

				&:last-child {
					border-radius: 0 8px 0 0;
				}
			}
		}

		tbody {
			display: contents;

			tr {
				&:first-child {
					@include media(sp) {
						th {
							border-radius: 8px 0 0 8px;
						}

						&:has(+ tr) {
							th {
								border-radius: 8px 0 0 0;
								width: 40%;
							}
							td{
								border-radius: 0 8px 0 0 !important;
								width: 60%;
							}
						}
					}
					td:last-child {
						@include media(sp) {
							border-radius: 0 8px 8px 0;
						}
					}
					
				}

				&:last-child {
					td {
						&:first-child {
							border-radius: 0 0 0 8px;
						}

						&:last-child {
							border-radius: 0 0 8px 0;
						}
					}
					th{
						border-bottom-left-radius:8px
					}
				}

			}

			th.item {
				background-color: #005baa;
				color: #fff;

				@include media(sp) {
					width: 100%;
				}
			}

			td {
				width: 100%;
			}
		}
	}

	&--04 {
		table-layout: fixed;

		@include media(sp) {
			width: 100%;
			min-width: 1120px;
		}

		thead {
			th {
				background-color: #005baa;
				color: #fff;

				&:first-child {
					border-radius: 8px 0 0 0;
				}

				&:last-child {
					border-radius: 0 8px 0 0;
				}
			}
		}

		tbody {
			display: contents;

			tr {
				&:last-child {
					th {
						&:first-child {
							border-radius: 0 0 0 8px;
						}
					}

					td {
						&:last-child {
							border-radius: 0 0 8px 0;
						}
					}
				}

				th {
					background-color: #f5f5f5;
					color: #333;
				}
			}
		}

	}
}

//===========================================
// カルーセル
//===========================================

.swiper-area {
	position: relative;

	.swiper {
		padding: 10px;
		overflow: hidden;

		@include media(sp) {
			padding: 4px 25px;
			width: 100vw;
			margin: 0 calc(50% - 50vw);

			@media only screen and (max-width: 767px) {
				padding: 4px 25px;
			}
		}

		.swiper-slide {
			height: auto;
		}
	}
}

.swiper-control {
	@include media(sp) {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0 20px;
		margin-top: 24px;
	}
}

.swiper-pagination-bullets {
	position: static;
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 16px;

	@include media(sp) {
		padding-top: 0;
		width: auto !important;
		max-width: 225px;
		gap: 10px 16px;
	}
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: #919191;
	opacity: 1;
	margin: 0 !important;

	@include media(sp) {
		width: 10px;
		height: 10px;
	}

	&.swiper-pagination-bullet-active {
		width: 16px;
		height: 16px;
		background-color: #005baa;
	}
}

.swiper-button-next,
.swiper-button-prev {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	top: calc(50% - 48px / 2);
	border: 2px solid #005baa;
	background-color: #fff;

	@include media(sp) {
		position: relative;
		width: 32px;
		height: 32px;
		top: 10px;
	}

	&::after {
		content: "";
		display: inline-block;
		width: 10px;
		height: 10px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		background-size: contain;
		vertical-align: middle;
		//矢印要素の場合
		border-top: 2px solid #005baa;
		border-right: 2px solid #005baa;
		transform: rotate(-135deg);

		@include media(sp) {
			width: 8px;
			height: 8px;
		}
	}

	&.swiper-button-disabled {
		opacity: 0;
	}

	&[class$="ss"] {
		top: 100px;

		@media only screen and (max-width: 1180px) {
			top: 94px;

			@include media(sp) {
				top: 10px;
			}
		}
	}
}

.swiper-button-prev {
	left: -26px;

	&::after {
		margin-left: 4px;
	}

	@media only screen and (max-width: 1200px) {
		left: -23px;

		@include media(sp) {
			left: 0;
		}
	}

	&[class$="ss"] {
		left: -14px;
	}
}

.swiper-button-next {
	right: -20px;

	&::after {
		transform: rotate(45deg);
		margin-right: 4px;
	}

	@include media(sp) {
		right: 0;
	}
}

.swiper-button-disabled {
	pointer-events: all;
}

//==========================================
// レイアウト
//==========================================

.layout {
	border-radius: 8px;

	&--01 {
		padding: 24px;
	}

	&--02 {
		padding: 32px 24px;
	}

	&--03 {
		padding: 32px;
	}

	&--04 {
		padding: 40px 24px;
		text-align: center;

		@include media(sp) {
			text-align: left;
			width: 100vw;
			margin: 0 calc(50% - 50vw);
			border-radius: 0;
		}
	}

	&--05 {
		padding: 40px 24px;

		@include media(sp) {
			padding: 40px 16px;
		}

		.btn {
			padding: 0 30px 0 20px;
		}
	}

	&--06 {
		padding: 64px 40px;

		@include media(sp) {
			padding: 40px 23px;
			width: 100vw;
			border-radius: 0;
			margin: 0 calc(50% - 50vw);
		}

		.s-ttl-area {
			display: flex;
			flex-direction: column;

			h3 {
				order: 2;
			}
		}
	}

	&--07 {
		padding: 40px;

		@include media(sp) {
			padding: 23px;
		}
	}

	&--08 {
		padding: 16px;
		display: block;
	}

	&--sp-01 {
		@include media(sp) {
			padding: 20px;
		}
	}
}

//==========================================
// 入力欄関連
//==========================================
input {

	&[type="text"],
	&[type="email"],
	&[type="tel"],
	&[type="number"],
	&[name="zip"] {
		width: 100%;
		height: 50px;
		line-height: 50px;
		padding: 0 15px;
		background-color: #fff;
		border: 2px solid #767676;
		border-radius: 4px;
		box-sizing: border-box;
		font-size: 16px;
		font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

		@include media(sp) {}
	}

	&[type="radio"] {
		appearance: none;
		position: relative;
		right: 0;
		bottom: 0;
		left: 0;
		height: 24px;
		width: 24px;
		vertical-align: -4px;
		transition: 0.2s;
		cursor: pointer;
		display: inline-block;
		margin-right: 8px;
		border: 1px solid #005baa;
		border-radius: 50%;

		@media only screen and (max-width: 767px) {
			height: 20px;
			width: 20px;
		}

		&:before {
			content: "";
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			position: absolute;
			top: calc(50% - 14px / 2);
			left: calc(50% - 14px / 2);
			transform: scale(0);
			transition: 0.2s;
			background: #005baa;

			@media only screen and (max-width: 767px) {
				top: calc(50% - 12px / 2);
				left: calc(50% - 12px / 2);
				width: 12px;
				height: 12px;
			}
		}

		&:checked:before {
			transform: scale(1);
		}

		&:focus-visible {
			outline: 4px solid #007921;
			outline-offset: 4px;
		}
	}
}

textarea {
	border: 2px solid #767676;
	width: 100%;
	border-radius: 4px;
	font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
	padding: 16px;
	font-size: 16px;

	&.textarea-tall {
		height: 265px;
	}
}

label {
	&.error {
		color: #ff0000;
		display: block;
		width: 100%;

		&:first-of-type {
			margin-top: 8px;
		}
	}
}

.select-box {
	width: auto;
	text-align: center;
	position: relative;

	&::before {
		position: absolute;
		top: calc(50% - 12px / 2);
		right: 20px;
		content: "";
		width: 8px;
		height: 8px;
		border-top: 2px solid #333;
		border-right: 2px solid #333;
		transform: rotate(135deg);
		pointer-events: none;
	}

	select {
		padding: 0 38px 0 20px;
		height: 50px;
		width: 100%;
		font-size: 16px;
		line-height: 50px;
		padding: 0 15px;
		border: 2px solid #767676;
		border-radius: 4px;
		cursor: pointer;
		text-overflow: ellipsis;
		background: transparent;
		background-image: none;
		box-shadow: none;
		appearance: none;
		color: #333;

		&::-ms-expand {
			display: none;
		}
	}
}

input,
textarea,
select {
	&.error {
		background: #fbe9e8;
		border-color: #ff0000;

		&+.input-checkbox__txt::before {
			background: #fbe9e8;
			border-color: #ff0000;
		}
	}
}

::placeholder {
	color: #333;
	opacity: 1 !important;
	font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
}

.input-checkbox {
	cursor: pointer;

	input[type="checkbox"] {
		position: absolute;
		opacity: 0;

		&:checked {
			&+.input-checkbox__txt {
				&::before {
					border-color: #005baa;

					@include media(sp) {
						border-width: 2px;
					}
				}

				&::after {
					opacity: 1;
				}
			}
		}

		&:focus,
		&:focus-visible {
			&+.input-checkbox__txt::before {
				outline: 4px solid #007921;
				outline-offset: 4px;
			}
		}
	}

	&__txt {
		position: relative;
		display: inline-flex;
		align-items: center;
		padding: 0 0 0 32px;

		@include media(sp) {
			padding: 0 0 0 24px;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			box-sizing: border-box;
		}

		&::before {
			top: auto;
			left: 0;
			background: #fff;
			border: 1px solid #767676;
			border-radius: 4px;
			aspect-ratio: 1;
			width: 24px;

			@include media(sp) {
				width: 20px;
			}
		}

		&::after {
			top: auto;
			left: 2px;
			background: url("../../assets_v2/img/common/icon_check.svg") no-repeat 0 0 / 100% auto;
			opacity: 0;
			aspect-ratio: 19 / 14;
			width: 19px;

			@include media(sp) {
				width: 15px;
			}
		}
	}
}

//===========================================
// パンくず
//===========================================

.topic-path {
	padding-bottom: 16px;

	@include media(sp) {
		height: auto;
	}

	&__list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;
		margin: 0 auto;
		max-width: calc(1120px + 23px * 2);
		padding: 0 23px;
		gap: 8px 20px;

		@include media(sp) {
			max-width: 100%;
			gap: 0 15px;
			padding: 0 20px;
		}

		li {
			font-size: 12px;
			position: relative;
			color: #767676;

			&:not(:last-child) {
				&:after {
					content: "";
					display: block;
					width: 5px;
					height: 5px;
					top: 6px;
					right: -11px;
					border-top: 1px solid #767676;
					border-right: 1px solid #767676;
					transform: rotate(45deg);
					position: absolute;

					@include media(sp) {
						right: -8px;
						top: 34.5%;
					}
				}
			}

			a {
				display: block;

				@include hover {
					text-decoration: underline;
				}
			}

			&[aria-current="page"] {
				font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
				color: #333;
			}
		}
	}
}

//===========================================
// フォーカス
//===========================================

a,
input,
button,
.accordion__close,
select,
textarea,
.eq-video_cover,
iframe,
video,
.btn--file {
	&:focus {
		outline: none;

		&-visible {
			outline: 4px solid #007921;
			outline-offset: 4px;
		}
	}
}

.btn--file:focus-visible {
	outline: 4px solid #007921;
	outline-offset: 4px;
}

// アンカーフォーカス
//===========================================
.focus-ttl {
	&:focus-visible {
		outline: #005baa solid;
		outline-offset: 4px;
	}
}

//==========================================
// 単一レベルでのCSS
//==========================================

// margin PC
//-------------------------------------------
// m-center
.m-center {
	margin: 0 auto;
}

// margin 80
.mt-80 {
	margin-top: 80px;
}

.mb-80 {
	margin-bottom: 80px;
}

// margin 40
.mt-40 {
	margin-top: 40px;
}

.mb-40 {
	margin-bottom: 40px;
}

// margin 32
.mt-32 {
	margin-top: 32px;
}

.mb-32 {
	margin-bottom: 32px;
}

// margin 24
.mt-24 {
	margin-top: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

// margin 16
.mt-16 {
	margin-top: 16px;

	@include media(sp) {
		margin-top: 16px;
	}
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-12 {
	margin-bottom: 12px;
}

// margin 8
.mt-8 {
	margin-top: 8px;
}

.mb-8 {
	margin-bottom: 8px;
}


.ml-20 {
	margin-left: 20px;
}

// margin SP
//-------------------------------------------
.mt-80-sp {
	@include media(sp) {
		margin-top: 80px;
	}
}

.mb-80-sp {
	@include media(sp) {
		margin-bottom: 80px;
	}
}

.mt-40-sp {
	@include media(sp) {
		margin-top: 40px;
	}
}

.mb-40-sp {
	@include media(sp) {
		margin-bottom: 40px;
	}
}

.mt-32-sp {
	@include media(sp) {
		margin-top: 32px;
	}
}

.mb-32-sp {
	@include media(sp) {
		margin-bottom: 32px;
	}
}

.mt-20-sp {
	@include media(sp) {
		margin-top: 20px;
	}
}

.mb-20-sp {
	@include media(sp) {
		margin-bottom: 20px;
	}
}

.mt-16-sp {
	@include media(sp) {
		margin-top: 16px;
	}
}

.mb-16-sp {
	@include media(sp) {
		margin-bottom: 16px;
	}
}

.mt-12-sp {
	@include media(sp) {
		margin-top: 12px;
	}
}

.mb-12-sp {
	@include media(sp) {
		margin-bottom: 12px;
	}
}

.mt-8-sp {
	@include media(sp) {
		margin-top: 8px;
	}
}

.mb-8-sp {
	@include media(sp) {
		margin-bottom: 8px;
	}
}

.pl-16 {
	padding-left: 16px;
}

// 色付き背景・マーカー・テキスト・ボーダー
//-------------------------------------------

//背景
.bg-white {
	background-color: #fff;
}

.bg-gray {
	background-color: #f6f6f4;
}

.bg-blue {
	background-color: #eaf6fc;
}

.bg-orange {
	background-color: #fdf2ea;
}

//マーカー
.marker {
	background-color: #ffff00;
	display: inline;
}

//テキスト
.txt-blue {
	color: #005baa;
}

//テキスト
.txt-red {
	color: #db342e;

	&--02 {
		color: #DB342E;
	}
}

//ボーダー
.border-blue {
	border: 1px solid #005baa;

	&--bold {
		border: 2px solid #005baa;
	}
}

//ボーダー
.border-gray {
	border: 1px solid #ddd;
}

.border-gray-02 {
	border: 1px solid #707070;
}

//ボーダーレッド
.border-red {
	border: 1px solid #FF0000;
}

// テキスト位置
//-------------------------------------------

.text-center {
	text-align: center;

	&--sp {
		@include media(sp) {
			text-align: center;
		}
	}
}

.text-left {
	text-align: left;

	&--sp {
		@include media(sp) {
			text-align: left;
		}
	}
}

.text-right {
	text-align: right;

	&--sp {
		@include media(sp) {
			text-align: right;
		}
	}
}

// 角丸
//-------------------------------------------

.radius {
	border-radius: 8px;
}

.radius-02 {
	border-radius: 4px;
}

// 再生ボタン
//-------------------------------------------
.icon-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	aspect-ratio: 1;
	width: 48px;
	height: auto;

	@include media(sp) {
		width: getvw(120);
	}
}

div:has(.icon-play) {
	position: relative;
}

// レイアウト
//-------------------------------------------
.flex {
	&__basic {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;

		&.center {
			justify-content: center;
		}

		&.sb {
			justify-content: space-between;
		}
	}

	&__column-2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		@include media(sp) {
			grid-template-columns: 1fr;
		}

		&--sp {
			@include media(sp) {
				grid-template-columns: repeat(2, 1fr);
				gap: 8px;
			}
		}

		&.center {
			justify-content: center;
			display: flex;
			flex-wrap: wrap;

			@media only screen and (max-width: 767px) {
				display: grid;
				grid-template-columns: 1fr;
			}
		}
	}

	&__column-3 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		@include media(sp) {
			grid-template-columns: 1fr;
		}

		&--sp {
			@include media(sp) {
				grid-template-columns: repeat(3, 1fr);
				gap: 8px;
			}
		}
	}

	&__column-4 {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		@include media(sp) {
			grid-template-columns: 1fr;
		}

		&--sp {
			@include media(sp) {
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&--sp-2 {
			@include media(sp) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	&__column-5 {
		display: grid;
		grid-template-columns: repeat(5, 1fr);

		@include media(sp) {
			grid-template-columns: 1fr;
		}

		&--sp {
			@include media(sp) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	&__column-img {
		display: flex;
		flex-wrap: wrap;

		.info {
			width: calc(100% - 300px - 32px);

			@include media(sp) {
				order: 2;
				width: 100%;
			}
		}

		.img {
			width: 300px;

			@include media(sp) {
				order: 1;
				width: 100%;
			}
		}
	}

	&.gap-0 {
		gap: 0;
	}

	&.gap-01 {
		gap: 32px;

		@include media(sp) {
			gap: 20px;
		}
	}

	&.gap-02 {
		gap: 16px 32px;

		@include media(sp) {
			gap: 8px;
		}
	}

	&.gap-03 {
		gap: 8px;

		@include media(sp) {
			gap: 8px;
		}
	}

	&.gap-04 {
		gap: 32px 16px;

		@include media(sp) {
			gap: 20px 8px;
		}
	}

	&.gap-02-sp {
		@include media(sp) {
			gap: 16px;
		}
	}

	&.gap-03-sp {
		@include media(sp) {
			gap: 8px;
		}
	}

	.btn-list {
		width: 352px;

		@include media(sp) {
			max-width: getvw(352);
			width: 100%;
		}

		@media only screen and (max-width: 767px) {
			max-width: 100%;
		}

		.btn {
			height: 100%;
		}
	}
}

.btn-static-sec {
	ul {
		max-width: 760px;
		margin: 0 auto;

		li {
			width: 352px;

			@include media(sp) {
				max-width: getvw(352);
				width: 100%;
			}

			@media only screen and (max-width: 767px) {
				max-width: 100%;
			}

			.btn {
				height: 100%;
			}
		}
	}
}
