@use "./assets_v2/scss/mixin"as *;

#FAQ {

  //==========================================
  // 汎用CSS
  //==========================================
  .search-sec {
    .layout {
      @include media(sp) {
        padding: 32px 20px;
      }
    }
  }

  .card__link {
    height: 100%;
    display: block;
    position: relative;
    border-radius: 8px;

    @include hover {
      img {
        &:before {
          content: "";
          position: absolute;
          border-radius: 8px;
          top: -2px;
          right: -2px;
          bottom: -2px;
          left: -2px;
          z-index: 2;
          border: 2px solid #005baa;
          /* 枠線を2pxの青色に設定 */
        }
      }
    }

    // 		&:hover,
    // 		&:focus-visible{
    //     img{
    // 			&:before {
    // 				content: "";
    // 				position: absolute;
    // 				border-radius: 8px;
    // 				top: -2px;
    // 				right: -2px;
    // 				bottom: -2px;
    // 				left: -2px;
    // 				z-index: 2;
    // 				border: 2px solid #005baa; /* 枠線を2pxの青色に設定 */
    // 			}
    // 	}
    // }
  }

  .contact-area {
    text-align: center;

    .column {
      &:first-of-type {
        border-right: 1px solid #707070;

        @include media(sp) {
          border-right: none;
          border-bottom: 1px solid #707070;
          margin-bottom: 32px;
          padding-bottom: 28px;
        }
      }
    }

    .tel {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &>span {
        position: relative;
        font-size: 24px;
        font-family: $ff-bold;
        padding: 0 0 0 38px;

        @include media(sp) {
          padding: 0 0 0 42px;
        }

        .icon-tel {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background: url('../img/company/assortment/icon_tel_01.svg') no-repeat 0 0 / 100% auto;
          aspect-ratio: 1;
          width: 30px;
        }
      }
    }
  }


  .form-area {
    border: 2px solid #005BAA;
    border-radius: 8px;
    padding: 24px;

    @include media(sp) {
      border-width: 1px;
      padding: 20px;
    }

    .form-txt {
      font-size: 18px;
      margin-bottom: 24px;

      @include media(sp) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    .form-row {
      display: grid;
      grid-template-columns: calc(328 / 1072 * 100%) 1fr;
      gap: 0 calc(32 / 1072 * 100%);
      border-bottom: 1px solid #767676;
      padding-bottom: 24px;

      @include media(sp) {
        grid-template-columns: 1fr;
        gap: 0;
        padding-bottom: 20px;
      }

      fieldset,
      legend {
        display: contents;

        @include media(sp) {
          display: block;
        }
      }

      &+.form-row {
        margin-top: 24px;

        @include media(sp) {
          margin-top: 20px;
        }
      }
    }

    .note-area {
      margin-top: 24px;

      @include media(sp) {
        margin-top: 20px;
      }
    }

    .radio__wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      @include media(sp) {
        gap: 12px 20px;
      }
    }
  }

  //==========================================
  // TOP
  //==========================================
  &.top {
    .kv {
      &::after {
        background-image: url("../../assets_v2/img/faq/top/kv.jpg");

        @include media(sp) {
          background-image: url("../../assets_v2/img/faq/top/kv_sp.jpg");
        }
      }
    }

    .seen-sec {
      .base-list--04 li:nth-child(n + 6) {
        display: none;
      }
    }
  }

  //==========================================
  // brand
  //==========================================
  &.brand {
    .item-sec {
      .flex {
        gap: 32px;

        @include media(sp) {
          gap: 20px 8px;
        }
      }
    }
  }

  //==========================================
  // products
  //==========================================
  &.products {
    .products-sec {
      &__box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;
        align-items: flex-start;

        .layout--01 {
          width: calc(100% - 200px - 40px);

          a {
            text-decoration: underline;
            color: #005baa;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .layout--08 {
          width: 200px;
        }

        @include media(sp) {
          .layout--01 {
            order: 2;
            width: 100%;
          }

          .layout--08 {
            order: 1;
            width: 100%;
          }
        }
      }

      .flex__basic {
        gap: 24px 32px;

        @include media(sp) {
          gap: 20px 0;

          li {
            width: 100%;
          }
        }
      }
    }

    .content-list-sec {
      .base-list--04 {
        li {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .contact-area {
      max-width: 736px;
      margin: 40px auto;

      .column {
        &:first-of-type {
          border-right: none;

          @include media(sp) {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  //==========================================
  // details
  //==========================================
  &.details {

    .kv-sec,
    .thanks-are {

      .layout.layout--02.border-red,
      .layout.layout--01.bg-blue,
      .layout.layout--01.border-gray,
      .layout.layout--01.bg-gray,
      .bg-white.layout.layout--01 {
        strong {
          font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
          font-weight: bold;
          display: inline-block;
        }

        a {
          text-decoration: underline;
          color: #005baa;

          &:hover,
          &:focus-visible {
            text-decoration: none;
          }
        }
      }
    }

    .thanks-are {
      .bg-white.layout.layout--01 {
        margin-bottom: 24px;
      }
    }

    .kv-sec {
      margin-top: getvwPc(70);

      @include media(sp) {
        margin-top: 40px;
      }

      .sec-in {
        display: grid;
        grid-template-columns: 1fr 200px;
        gap: 40px;
        align-items: flex-start;

        @include media(sp) {
          grid-template-columns: 1fr;

          .layout--01 {
            order: 2;
          }

          .layout--08 {
            order: 1;
          }
        }

        .ttl-area {
          @include media(sp) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0 32px;
          }

          .ttl {
            font-size: 40px;
            margin-bottom: 24px;

            @include media(sp) {
              font-size: 24px;
              margin-bottom: 0;
            }
          }

          &:has(+ .layout.layout--01.border-gray) {
            margin-bottom: 40px;
          }
        }
      }

      &.wave {
        position: relative;
        margin-bottom: getvwPc(80);

        @include media(sp) {
          margin-bottom: 40px;
        }

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          background-repeat: no-repeat;
          width: 100%;
          z-index: 2;

          @include media(sp) {
            content: none;
          }
        }

        &--blue-01 {
          &::after {
            bottom: getvwPc(-80);
            aspect-ratio: 30/ 1.3;
            background-image: url("../../assets_v2/img/common/wave_blue_01.svg");
            background-size: cover;

            @include media(sp) {}
          }
        }
      }
    }

    .faq-list {

      a {
        text-decoration: underline;
        color: #005baa;

        &:hover,
        &:focus-visible {
          text-decoration: none;
        }
      }

      img {
        width: auto;
        max-width: 100%;
      }

      strong {
        font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
        font-weight: bold;
      }

      table {
        margin-top: 32px;
        tr {
          th{
            padding: 8px;
          }
          td {
            padding: 8px;
            border: 1px solid #333;

            &.aly_tx_center {
              text-align: center;
            }
          }
        }
      }

      .icon {
        width: 40px;
      }

      &__q {
        border-bottom: 1px solid #767676;
        padding-bottom: 24px;

        .q-head {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
        }

        .txt {
          font-size: 18px;
          display: block;
        }
      }

      &__a {
        margin-top: 24px;

        .icon {
          margin-bottom: 8px;
        }

        .connection-q {
          display: grid;
          grid-template-columns: 64px 1fr;
          flex-wrap: wrap;
        }

        .aly_tx_s {
          font-size: 14px;
        }
      }

      &__img {
        img {
          width: auto;
          max-width: 100%;
          display: block;
        }
      }

      &__link {
        display: table;

        &+a {
          margin-top: 24px;
        }
      }
    }

    .faq-sec {
      .card--08 {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .enquete-sec {
      button {
        @include media(sp) {
          max-width: 200px;
        }
      }
      .kv-sec__img.sp{
        display: none;
        @include media(sp) {
          display: block;
        }
      }
    }

    .connection-sec {
      &__box {
        border-top: 1px solid #767676;
        padding-top: 40px;
        margin-top: 40px;
      }
    }

    .other-sec {
      &__top {
        width: 100%;
        align-items: center;

        @include media(sp) {

          margin-left: 0;
        }

        .brand-item {
          width: 448px;

          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }

        &:has(.brand-item) {
          margin-left: -45px;

          @include media(sp) {
            margin-left: 0;
          }
        }
      }
    }

    .contact-area {
      max-width: 736px;
      margin: 40px auto;

      .column {
        &:first-of-type {
          border-right: none;

          @include media(sp) {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
