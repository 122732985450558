@use "./assets_v2/scss/mixin" as *;
@use "./assets_v2/scss/component" as *;

//==========================================
// TOP
//==========================================
#TOP {
  .notice-sec {
    &.mt-40 {
      @include media(sp) {
        margin-top: 20px;
      }
    }
    &.mb-40 {
      @include media(sp) {
        margin-bottom: 20px;
      }
    }
    .txt-red {
      color: #FF0000;
    }
  
    .border-red {
      border: 1px solid #FF0000;
    }
    .border-blue--02 {
      border: 1px #00B4ED solid;
    }
  }
  
  .sec-ttl {
    @include media(pc) {
      font-size: 40px;
    }
    @include media(sp) {
      font-size: 20px;
    }
  }
  .sec-txt{
    @include media(sp) {
      width: 50%;
    }
    @media only screen and (max-width: 767px){
      width: 100%;
    }
  }
  .kv {
    position: relative;
    border-top: 1px solid #ccc;
    height: getvwPc(600);
    overflow: hidden;
    margin-top: -1px;
    background-color: #fff;
    @include media(sp) {
      aspect-ratio: 25 / 28.1;
      margin-bottom: 40px;
      background-size: cover;
      height: auto;
    }
    &::before {
      background-image: url(../img/top/kv_wave_01.svg);
    }
    &::after {
      display: none;
    }
    .sec-in {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      @include media(sp) {
        justify-content: flex-end;
        padding-bottom: 48px;
      }
    }

    &__img{
      width: getvwPc(660);
      position: absolute;
      right: -120px;
      top: -60px;
      z-index: 1;
      @include media(sp) {
        right: getvw(-100);
        top: getvw(-40);
        width: getvw(780);

      }
    }

    &__ttl-area{
      z-index: 2;
      position: absolute;
      top: getvwPc(166);
      left: getvwPc(70);
      text-align: left;
      @include media(sp) {
        position: relative;
        top: auto;
        left: auto;
        bottom: getvw(40);
      }
      @media only screen and (max-width: 767px){
        bottom: 0;
      }
    }

    &__ttl {
      width: getvwPc(504);
      height: auto;
      position: relative;
      @include media(sp) {
        order: 2;
        width: getvw(760);
        margin: 16px 0 0 0;
        padding: 0;
      }
    }

    &__link {
      font-size: 1.25vw;
      margin-top: getvwPc(32);
      @include media(sp) {
        order: 3;
        font-size: 2.6rem;
        margin: 16px 0 0 0;
      }
      @media only screen and (max-width: 767px){
        font-size: 14px;
      }
    }

    .icon-img {
      width: getvwPc(30);
      margin: 0 0 0 8px;
      position: relative;
      top: getvwPc(4);
      @include media(sp) {
        width: 22px;
      top: getvw(-2);
      }
    }

    &__btn {
      width: getvwPc(30);
      cursor: pointer;
      position: absolute;
      right: getvwPc(-60);
      bottom: getvwPc(-5);
      @include media(sp) {
        order: 1;
        width: getvw(50);
        right: getvw(-90);
        bottom: getvwPc(-14);
      }
    }

    //背景アニメーション
    @keyframes infinity-scroll-left {
      from {
        transform: translateX(0);
      }
        to {
        transform: translateX(-100%);
      }
      }
      .scroll-infinity{
        position: absolute;
        top: 0;
        &--bottom{
          top: 58%;
          @include media(sp) {
            top: 34.7%;
          }
          .scroll-infinity__list--left {
            animation: infinity-scroll-left 60s infinite linear 0.5s both;
          }
        }
        &__wrap {
          display: flex;
          overflow: hidden;
         
        }
        &__list {
          display: flex;
          list-style: none;
          padding: 0
        }
        &__item {
          width: calc(100vw / 4);
          @include media(sp) {
            width: calc(100vw / 2.3);
          }
          & > img {
            width: 100%;
          }
        }
      }
      .scroll-infinity__list--left {
        animation: infinity-scroll-left 40s infinite linear 0.5s both;
      }

      .scroll-infinity__list--left.paused {
        animation-play-state: paused;
    }
  }

  // fixedmenu
  .fixedmenu {
    position: absolute;
    top: getvwPc(60);
    right: 0;
    z-index: 4;
    width:  getvwPc(48);
    @include media(sp) {
      width:  getvw(96);
      top: getvw(120);
    }
    img {
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.16));
    }
    ul{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  .swiper-settings{
   // position: absolute;
    bottom: -40px;
    //transform:scale(.7);
    width: 50%;

  }
  .selfcare-sec {
    &--01 {
      position: relative;
      @include media(pc) {
        background: url(../img/top/selfcare_bg_01.jpg) no-repeat center 0 / cover;
        min-height: getvwPc(360);
        padding-top: getvwPc(105);
        padding-bottom: getvwPc(45);
      }
      @include media(sp) {
        background: url(../img/top/selfcare_bg_01_sp.jpg) no-repeat center 0 / 100% auto;
        padding-top: getvw(660);
      }

      .wave {
        position: absolute;
        top: 0;
        left: 0;
      }

      .sec-ttl {
        @include media(sp) {
          font-size: 24px;
        }
      }

      .ttl-box {
        align-items: center;
        @include media(sp) {
          gap: 0;
        }
        img {
          max-width: 200px;
          filter: drop-shadow(0 0 4px rgba(0,0,0,.1));
          transform: translateZ(0);
          @include media(sp) {
            position: absolute;
            left: 260px;
            top: 50px;
            max-width: getvwPc(140);
          }
          @media only screen and (max-width: 767px){
            top: auto;
            left: auto;
            bottom: 165px;
            max-width: 100px;
          }
        }
      }
    }
    &--02 {
      @include media(pc) {
        padding: 80px 0 40px;
      }
      @include media(sp) {
        padding-top: 36px;
      }

      .card--06__info{
        position: relative;
        h3{
          padding-right: 20px;
        }
        .icon-blank{
          top: 26px;
          right: 24px;
        }
      }

      .card--06__logo {
        font-size: unset;
      }
    }
  }
  .latest-news-sec {
    .latest-news__ttl {
      padding: 8px;
    }
    .list-item__left {
      @include media(sp) {
        margin-bottom: 0;
      }
    }
    .list-item__info {
      @include media(sp) {
        width: 100%;
      }
    }
  }
  .products-sec {
    &--01 {
      position: relative;
      margin-bottom: 80px;
      @include media(pc) {
        background: url(../img/top/products_bg_01.jpg) no-repeat center 0 / cover;
        min-height: getvwPc(400);
        padding-top: getvwPc(140);
        padding-bottom: getvwPc(64);  
      }
      @include media(sp) {
        background: url(../img/top/products_bg_01_sp.jpg) no-repeat center 0 / 100% auto, #F6F6F4;
        padding-top: getvw(660);
        margin-bottom: 0;
        padding-bottom: 80px;
      }
      .wave {
        position: absolute;
        top: 0;
        left: 0;
      }
      &:has( + .products-sec--02){
        margin-bottom: 0;
        @include media(sp) {
          padding-bottom: 0;
        }
      }
    }
    &--02 {
      position: relative;
      .wave {
        position: relative;
        bottom: -3px;
      }

      .sec-in--bg{
        @include media(sp) {
          padding-top: 40px;
        }
      }

      .swiper-area {
        @include media(sp) {
          margin-top: 16px;
          margin-bottom: 0;
        }
      }

      .card--11__img {
        padding: 0;
      }
    }
  }
  .news-sec {
    position: relative;
    .wave {
      @include media(sp) {
        margin-top: -22.5px;
      }
    }
    hr {
      margin: 0;
    }
    .sec-ttl {
      @include media(sp) {
        margin-top: 30px;
      }
    }
    .swiper-area {
      @include media(sp) {
        margin-bottom: 0;
      }
    }

    .base-list--05 {
      & > li {
        padding: 0;
        margin: 0;
        a {
          display: block;
          text-decoration: none;
          width: 100%;
          padding: 24px 0;
          @include hover {
            .tag {
              background-color: #ccf2ff;
            }
            .txt-link {
              text-decoration: none;
            }
          }
        }
      }
    }
    &--01{
      .layout.layout--02.border-gray{
        a{
          text-decoration: underline;
          color: #005baa;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    &--02{
      .layout--02{
        @include media(sp) {
          padding: 20px;
          .s-ttl{
            font-size: 13px;
          }
        }
        &__list{
          li{
            &:not(:last-child){
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
  .company-sec {
    &--01 {
      position: relative;

      @include media(pc) {
        background: url(../img/top/company_bg_01.jpg) no-repeat center 0 / cover;
        min-height: getvwPc(400);
        padding-top: getvwPc(140);
        padding-bottom: getvwPc(64);
      }

      @include media(sp) {
        background: url(../img/top/company_bg_01_sp.jpg) no-repeat center 0 / 100% auto, #F6F6F4;
        padding-top: getvw(660);
      }

      .btn {
        @include media(sp) {
          margin-top: 32px;
        }
      }

      .wave {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &--02 {
      .layout {
        position: relative;
        display: flex;
        flex-direction: column;

        .btn {
          margin-top: auto;
        }
      }
    }
  }
  .other-sec {
    hr {
      margin: 0;
    }
    &--01 {
      padding-bottom: 40px;
      @include media(sp) {
        margin-top: 0;
      }
      .txt-link__wrap {
        @include media(sp) {
          text-align: right;
        }
      }
    }

    &--02 {
      padding-bottom: 40px;
    }

    &--03 {
      .swiper-wrapper {
        @include media(pc) {
          justify-content: center;
        }
      }
      .swiper-slide {
        // width: 256px;
        .radius {
          border-radius: 8px;
        }
      }
    }
  }
}