@use "./assets_v2/scss/mixin" as *;
@use "./assets_v2/scss/component" as *;

#VOICE {
  //==========================================
  // VOICE
  //==========================================
  &.voice {
    .s-txt--voice {
      font-size: 18px;
    }
    .card__link-in{
      height: auto;
    }
    .voice01 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media(sp) {
        align-items: flex-start;
      }
      .voice01-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        padding-right: 30px;
        @include media(sp) {
          align-items: flex-start;
          width: 80px;
          padding-right: 15px;
        }
      }
      .voice01-txt {
        width: calc(100% - 130px);
        @include media(sp) {
          width: calc(100% - 80px);
        }
      }
      &.voice01-single {
        display: block;
        .voice01-txt {
          @include media(sp) {
            width: 100%;
          }
        }
      }
    }
    .voice02 {
      .voice02-detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        background: #f5f5f5;
        padding: 60px 0;
        position: relative;
        @include media(sp) {
          padding: 15px;
        }
        .detail-item {
          width: 37.3%;
          @media only screen and (max-width: 767px){
            width: 100%;
            max-width: 345px;
          }
        }
        .detail-item-arrow {
          position: relative;
          width: 17px;
          margin-left: 35px;
          margin-right: 35px;
          @media only screen and (max-width: 767px){
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
          }
          &::before {
            @include media(pc) {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12px 0 12px 16px;
              border-color: transparent transparent transparent #0045a3 ;
            }
          }
        } 
        .tag-area{
          position: absolute;
          width: 100%;
          top: 16px;
          left: 16px;
          @include media(sp) {
            position: relative;
            top:0;
            left:0;
          }
        }
      }
      .voice02-detail--single {
        .detail-item {
          text-align: center;
          width: 770px;
          margin: auto;
					@include media(sp) {
						width: 100%;
					}
          img {
            width: auto;
            max-width: 100%;
            height: 255px;
						@include media(sp) {
							width: 100%;
							height: auto;
						}
          }
        }
      }
    }
    .arrow {
      position: relative;
      width: 37px;
      margin: auto;
      display: flex;
      margin-top: 15px;
      padding-bottom: 20px;
      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 12px 0 12px;
        border-color: #0044a3 transparent transparent transparent;
        position: absolute;
        bottom: 50%;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
        .card {
          max-width: 448px;
          @include media(sp) {
            max-width: none;
             
            }
          

          .card--09{
            &__img{
              width: 35%;
              border-radius: 8px  0 0 8px;
            }
            &__ttl{
              font-weight: bold;
              width: 65%;
              @include media(sp) {
                 padding-left: 10px;             
                }
            }
          }
        }
        
  }
}
