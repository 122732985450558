@use "./assets_v2/scss/mixin" as *;

#ASSORTMENT {

  //==========================================
  // ASSORTMENTコンテンツスタイル適用
  //==========================================
  &.assortment {
    .kv {
      margin-bottom: 40px;
    }

    .anchor-sec {
      .btn {
        text-align: left;

        @include media(sp) {
          padding: 20px 40px 20px 20px;
        }

        h5 {
          transition: all 0.3s;
        }

        @include hover {
          h5 {
            color: #fff;
          }
        }
      }

      .btn__txt {
        width: 100%;
      }

      .anchor-area {
        &__item {
          &:not(:first-of-type) {
            margin-top: 8px;
          }

          .btn--anchor {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            border-radius: 4px;
            max-width: 100%;
            max-height: unset;
            padding: 18px 24px;

            @include media(sp) {
              padding: 20px 40px 16px 20px;
            }

            .icon-anchor {
              top: 50%;
              right: 13px;
              left: unset;
            }

            .anchor__ttl {
              color: inherit;
              font-size: 18px;
            }
          }

          .list__item-single {
            flex-direction: initial;
            align-items: center;
          }
        }
      }

      .dot-list {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        gap: 16px 16px;
        width: 100%;

        @include media(sp) {
          grid-template-columns: 1fr;
        }

        &__item {
          position: relative;
          font-size: 16px;
          font-family: $ff-normal;
          padding: 0 0 0 20px;

          @include media(sp) {
            font-size: 13px;
            padding: 0 0 0 16px;
          }

          &::before {
            content: "・";
            position: absolute;
            top: auto;
            left: 0;
            aspect-ratio: 1;
          }
        }
      }
    }

    #distributor-sec {
      .img {
        float: right;
        width: 352px;
        margin-left: 32px;

        // width: calc(352 / 1120 * 100%);
        // margin-left: calc(32 / 1120 * 100%);
        @include media(sp) {
          float: none;
          width: 100%;
          margin: 0 auto 20px;
        }
      }

      .lead {
        margin-top: 16px;
      }

      .content {
        &__row {
          &::after {
            content: "";
            display: block;
            clear: both;
          }
        }

        &__ttl {
          font-size: 24px;
          margin-bottom: 18px;

          @include media(sp) {
            font-size: 20px;
            margin-bottom: 14px;
          }
        }

        .btn-area {
          margin-top: 28px;

          .btn {
            max-width: 448px;
          }
        }
      }

      .btn-padding {
        padding: 0 37px 0 21px;
      }
    }

    #contact-sec {
      .contact-area {
        text-align: center;

        .column {
          &:first-of-type {
            border-right: 1px solid #707070;

            @include media(sp) {
              border-right: none;
              border-bottom: 1px solid #707070;
              margin-bottom: 32px;
              padding-bottom: 28px;
            }
          }
        }

        .tel {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          &>span {
            position: relative;
            font-size: 24px;
            font-family: $ff-bold;
            padding: 0 0 0 38px;

            @include media(sp) {
              padding: 0 0 0 42px;
            }

            .icon-tel {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              background: url(../img/company/assortment/icon_tel_01.svg) no-repeat 0 0 / 100% auto;
              aspect-ratio: 1;
              width: 30px;
            }
          }
        }
      }

      .layout.layout--02.border-red,
      .layout.layout--01.bg-blue,
      .layout.layout--01.border-gray,
      .layout.layout--01.bg-gray {
        margin-bottom: 24px;

        strong {
          font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
          font-weight: bold;
          display: inline-block;
        }

        a {
          text-decoration: underline;
          color: #005baa;

          &:hover,
          &:focus-visible {
            text-decoration: none;
          }
        }
      }
    }

    #product-sec {
      .btn-area {
        .btn-list__item {
          &:nth-of-type(1) {
            @include media(sp) {
              order: 1;
            }
          }

          &:nth-of-type(2) {
            @include media(sp) {
              order: 2;
            }
          }

          &:nth-of-type(3) {
            @include media(sp) {
              order: 3;
            }
          }

          &:nth-of-type(4) {
            @include media(sp) {
              order: 5;
            }
          }

          &:nth-of-type(5) {
            @include media(sp) {
              order: 6;
            }
          }

          &:nth-of-type(6) {
            @include media(sp) {
              order: 4;
              margin-bottom: 28px;
            }
          }
        }
      }
    }

    .border-gray {
      border: 1px solid #dddddd;
    }

    .notice-area {
      border: 1px solid red;
    }
  }

  &.products {
    .kv {
      &__ttl {
        flex-wrap: wrap;
        gap: 40px 84px;
      }

      &__img {
        width: getvwPc(304);
        margin-bottom: getvwPc(-50);

        @include media(sp) {
          width: 100%;
          margin: 32px 0 0;
        }
      }
    }
  }

  //==========================================
  // drug-info
  //==========================================
  &.drug-info {
    .kv {
      &__ttl {
        gap: 40px 84px;
      }

      &__img {
        width: getvwPc(304);
        margin-bottom: getvwPc(-50);

        @include media(sp) {
          width: 100%;
          margin: 32px 0 0;
        }
      }
    }

    .flex-box-01 {
      display: grid;
      grid-template-columns: 1fr 352px;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 32px;

      @include media(sp) {
        grid-template-columns: 1fr;
        gap: 0;
      }

      &__info {
        @include media(sp) {
          display: contents;

          .btn {
            order: 4;
          }
        }
      }
    }

    .flex-box-02 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      flex-wrap: wrap;

      @include media(sp) {
        grid-template-columns: 1fr;
      }

      &>.layout {
        display: flex;
        flex-direction: column;

        img {
          max-width: 352px;
          display: block;
          margin: 0 auto;

          @include media(sp) {
            max-width: 100%;
          }
        }
      }

      &__ttl {
        flex-grow: 1;

        &--02 {
          flex-grow: inherit;
        }
      }

      video {
        width: 100%;
      }
    }

    .btn-flex {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px 32px;

      @include media(sp) {
        gap: 8px;
      }

      li {
        width: 352px;

        @include media(sp) {
          max-width: getvw(352);
          width: 100%;
          letter-spacing: 0;
        }

        @media only screen and (max-width: 767px) {
          max-width: 100%;
        }
      }
    }

    .movie-list {
      &__item {
        max-width: 352px;
        display: flex;
        flex-direction: column;

        @include media(sp) {
          max-width: 100%;
        }
      }

      &__ttl {
        flex-grow: 1;
      }
    }

    &.top {
      .flex__column-3 {
        .layout {
          h2 {
            min-height: 91px;

            @include media(sp) {
              min-height: auto;
            }
          }
        }
      }
    }

    &.press {
      .interview-kv {
        position: relative;

        figcaption {
          position: absolute;
          left: 50px;
          bottom: 40px;
          font-size: 28px;
          color: #fff;
          line-height: 1.8;
          text-shadow: 0px 3px 18px rgba(0, 0, 0, 0.56);

          @include media(sp) {
            margin-top: 20px;
            position: relative;
            left: auto;
            bottom: auto;
            font-size: 19px;
            color: #333;
            text-shadow: none;
          }
        }
      }

      .card--09 {
        &__ttl {
          border-left: 1px solid #ddd;

          @include media(sp) {
            border-top: 1px solid #ddd;
            border-left: none;
          }
        }
      }
    }

    .lumify-box {
      max-width: 740px;
      margin: 0 auto;

      .lumify-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;

        @include media(sp) {
          grid-template-columns: 1fr;
          gap: 8px;
        }

        li {
          width: auto;
        }
      }
    }
  }

  &.test-result-2024,
  &.test-list {

    //==========================================
    // JIS X 8341-3:2016に基づく試験結果（2024年xx月xx日公開）
    //==========================================
    .scroll-table {
      overflow: auto;

      .table {
        &--03 {
          @include media(sp) {
            width: 1024px;
          }

          th {
            width: 10%;

            &:nth-child(2) {
              width: 40%;
            }
          }
        }

        @include media(sp) {
          tbody tr:first-child:has(+tr) td {
            border-radius: 0 0 0 0 !important;
          }
        }
      }
    }
  }

  &.test-result-2024 {
    .table {
      &--03 {
        tr {
          td {
            text-align: center;

            &:nth-child(2),
            &:nth-child(6) {
              text-align: left;
            }
          }
        }
      }
    }
  }

  &.test-list {
    .table {
      &--03 {
        tr {
          th {
            width: 10%;

            &:nth-child(2) {
              width: 40%;
            }

            &:nth-child(3) {
              width: 40%;
            }
          }

          td {
            &:first-child {
              text-align: center;
            }
          }
        }

        .txt-link {
          word-break: break-all;
        }
      }
    }
  }
}

#STATIC {
  &.faq {
    .accordion__ttl {
      .accordion__trigger {

        .trigger__txt {
          position: relative;
          padding: 0 0 0 60px;

          @include media(sp) {
            padding: 0 0 0 55px;
          }
        }

        .ttl-q {
          position: absolute;
          top: 0;
          left: 0;
          text-align: right;
        }
      }
    }

    .layout--answer {
      .answer__txt {
        position: relative;
        padding: 0 0 0 3.8em;

        @include media(sp) {
          padding: 0 0 0 2.9em;
        }
      }

      .ttl-a {
        position: absolute;
        top: 0;
        left: 0;
        text-align: right;
        width: calc(3em - 24px);

        @include media(sp) {
          width: calc(2.5em - 24px);
        }

        &+dd {
          sub {
            font-size: 12px;
          }
        }
      }
    }

    // テーブル
    .table--01 {
      tr:first-of-type {
        th {
          border-radius: 0;
          width: auto;

          &:first-of-type {
            border-radius: 8px 0 0 0;
          }

          &:last-of-type {
            border-radius: 0 8px 0 0;
          }
        }
      }

      tr:last-of-type {
        td {
          &:first-of-type {
            border-radius: 0 0 0 8px;
          }

          &:last-of-type {
            border-radius: 0 0 8px 0;
          }
        }
      }

      th,
      td {
        vertical-align: middle;
        width: auto;
      }
    }
  }
}