@use "./assets_v2/scss/mixin" as *;

#PRODUCTS {
	//==========================================
	// 汎用CSS
	//==========================================
	.prev-sec {
		&__border {
			border-top: 1px solid #ddd;
		}
	}
	//==========================================
	// top
	//==========================================
	&.top {
		
		.kv {
			height: auto;
			margin-bottom: 64px;
			@include media(sp) {
				margin-bottom: 92px;
				padding-bottom: 0;
			}
			.swiper-control {
				@include media(sp) {
					position: absolute;
					right: 0;
					left: 0;
				}
			}
			&__ttl {
				height: 112px;
			}
			.swiper-area {
				.swiper {
					padding:8px 0;
					&-pagination {
						position: absolute;
						bottom: -15px;
						padding-top: 0;
						@include media(sp) {
							position: static;
						}
					}
					&-button {
						&-prev {
							left: getvwPc(80);
							@include media(sp) {
								left: 0;
							}
						}
						&-next {
							right: getvwPc(80);
							@include media(sp) {
								right: 0;
							}
						}
					}
				}
				
			}
		}
		.swiper-tvcm{
			overflow: unset;
			@include media(sp) {
				overflow: hidden;
			}
			& + .swiper-control{
			.swiper-button-next{
				right: 20%;
				@include media(sp) {
					right: 0;
				}
		}
		.swiper-button-prev{
			left: 20%;
			@include media(sp) {
				left: 0;
			}
		}
	}
		}
		.sec-ttl {
			display: flex;
			align-items: center;
			.icon-img {
				margin-right: 16px;
			}
		}
		.brand-sec {
			.card--09 {
				& > div {
					display: flex;
					border-radius: 10px;
					justify-content: space-between;
					flex-wrap: wrap;
				}
				.btn{
					border-radius: 50px;
					justify-content: center;
				}
				&__img {
					@include media(pc) {
						width: 60%;
					}
				}
				&__ttl {
					display: flex;
					justify-content: center;
					align-items: center;

					@include media(pc) {
						width: 40%;
						padding: 0;
					}
					@include media(sp) {
						padding: 32px 16px;
					}
				}
			}
		}
		.category-sec {
			.flex__column-4 {
				@include media(sp) {
					grid-template-columns: repeat(2, 1fr);
					gap: 8px;
				}
			}
			.card__link {
				display: flex;
				flex-direction: column;
				background-color: #eaf6fc;
				@include hover {
					background-color: #fff;
				}
			}
			.card--11__ttl {
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				padding: 16px 30px 16px 16px;
			}
			.card--11__img {
				text-align: center;
				padding: 16px;
				@include media(sp) {
					padding: 0 16px;
				}
				img {
					max-width: 180px;
					@include media(sp) {
						max-width: 110px;
					}
				}
			}
		}
		.new-product-sec {
			.card--11__img {
				text-align: center;
				padding: 36px;
				img {
					max-width: 136px;
				}
			}
		}
		.campaign-sec {
			.flex__column-4 {
				@include media(sp) {
					grid-template-columns: repeat(2, 1fr);
					gap: 8px;
				}
			}
			.ss-ttl {
				@include media(sp) {
					font-size: 14px;
				}
			}
			.card__info--01 {
				@include media(sp) {
					padding: 8px;
				}
			}
			.card--11__img{
				padding: 0;
			}
		}
	}
	//==========================================
	// category
	//==========================================
	&.category {
		.category-ttl {
			display: flex;
			align-items: center;
			&__img {
				width: 80px;
				margin-right: 8px;
				@include media(sp) {
					width: 40px;
				}
			}
		}
		.card--01{
			.list{
				grid-template-columns: 1fr 1fr;
				@include media(sp) {
					grid-template-columns: 1fr;
				}
				&__item{
					// max-width: 100%;
				}
			}
		}
	}
	//==========================================
	// details
	//==========================================
	&.details {
		.products-sec {
			margin-top: 16px;
			.sec-in {
				position: relative;
				height: 670px;
				@media only screen and (max-width: 1180px) {
					height: getvwPc(710);
					@include media(sp) {
						height: auto;
					}
				}
			}
			.language {
				margin-left: auto;
			}
			&__box {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				flex-direction: row-reverse;
				@include media(sp) {
				}
				.info {
					width: 48.5%;
					height: 0;
					@include media(sp) {
						display: contents;
						width: 100%;
					}
				}
			}
			.card--05 {
				width: 48.5%;
				@include media(sp) {
					width: 100%;
					order: 2;
				}
			}
			&__top {
				display: flex;
				flex-direction: column;
				@include media(sp) {
					margin-bottom: 40px;
					order: 1;
					margin-right: auto;
				}
				h1{
					order: 2;
				}
				.flex__basic-01{
					order: 1;
				}
				.flex__basic-03{
					order: 3;
				}
				.self-img {
					width: 62px;
				}
			}
			&__bottom {
				@include media(sp) {
					order: 3;
					overflow: auto;
					width: 100%;
				}
				.bottom-txt {
					font-size: 20px;
					line-height: 1.8;
					margin-bottom: 20px;
				}
				.flex {
					display: flex;
					gap: 24px;
					@include media(sp) {
						gap: 8px;
					}
					li {
						width: 240px;
					}
					.btn {
						max-width: 100%;
						@include media(sp) {
							padding: 0 20px 0 10px;
							min-height: 44px;
						}
					}
				}
				.link-area {
					text-align: right;
					li {
						&:not(:last-child) {
							margin-bottom: 8px;
						}
					}
				}
				.notes--number{
					padding-left: 0;
					&:before{
						content: none;
					}
				}
			}
		}
		.details-sec {
			h2 {
				@include media(sp) {
					font-size: 20px;
					&.l-txt--sp{
						font-size: 18px;
					}
				}
			}
			.btn--red {
				width: 272px;
				@include media(sp) {
					width: 100%;
				}
			}
			.shop__web__btn {
				@include media(sp) {
					width: 100%;
				}
			}
			&__list {
				&--01 {
					display: flex;
					flex-wrap: wrap;
					gap: 32px;
					align-items: flex-end;
					@include media(sp) {
						justify-content: center;
					}

					.sns-icon {
						width: 32px;
					}
				}
			}

			.layout--07 {
				.flex__basic {
					gap: 24px 32px;
					li {
						@include media(sp) {
							width: 100%;
						}
					}
				}
			}
			.accordion__contents {
				.layout--01 {
					&:has(+ .table-box) {
						margin-bottom: 40px;
						@include media(sp) {
							margin-bottom: 20px;
						}
					}
				}
				.layout--d-01 {
					gap: 24px;
					position: relative;
					@include media(sp) {
						justify-content: space-between;
						gap: 0;
					}
					&__img {
						width: 250px;
						@include media(sp) {
							width: 45%;
						}
					}
					&__txt {
						@include media(sp) {
							width: 50%;
						}
					}
				}
				.table-box {
					&:not(:last-of-type) {
						margin-bottom: 40px;
						@include media(sp) {
							margin-bottom: 20px;
						}
					}
					.table {
						&:not(:last-of-type) {
							margin-bottom: 24px;
						}
					}
				}
				.usage-img {
					width: 100px;
				}
				.chatbot {
					display: grid;
					grid-template-columns: 1fr 42%;
					align-items: center;
					padding-left: 80px;
					padding-right: 80px;
					gap: 24px;
					@include media(sp) {
					grid-template-columns: 1fr;
					padding-left: 24px;
					padding-right: 24px;
					gap: 0;
					}
					&__info{
						@include media(sp) {
							display: contents;
							h4{
								order: 1;
								text-align: center;
								font-size: 18px;
							}
							.btn{
								order: 3;
								margin-left: auto;
								margin-right: auto;
							}
						}
					}
					&__img{
						@include media(sp) {
							order: 2;
						}
					}
				}
			}
		}
		div.base-list{
			& > ul{
				& > li{
					position: relative;

			&::before {
				content: "・";
				position: absolute;
				left: -20px;
				top: 0;
			}
				}
			}
		}
		div.base-list.base-list--02{
			li{
				text-indent:0;
				&:before{
					content: none;
				}
			}
			& > ol{
				& > li{
					position: relative;
					list-style-type: decimal;
					& > ol{
						margin-top: 16px;
						padding-left: 24px;
						& > li{
							position: relative;
							list-style-type: none;
							counter-increment: cnt;
							&:not(:last-child){
								margin-bottom: 8px;
							}

				&:before {
					content: "("counter(cnt) ")";
					position: absolute;
    			left: -28px;
					margin-right: 0;
				}
						}
					}
				}
			}
		}
	&.fixed{
		position: fixed;
	}
	}

	//==========================================
	// brand
	//==========================================

	&.brand{
		.brand-sec{
			@include media(sp) {
				margin-bottom: 40px;
			}
		}
		.card--03{
			.list{
				grid-template-columns: 1fr 1fr;
				@include media(sp) {
					grid-template-columns: 1fr;
				}
				&__item{
					// max-width: 100%;
				}
			}
		}
	}

	//==========================================
	// package_insert_foreign
	//==========================================

	&.package_insert_foreign {
		// テーブル
		.container {
			overflow: unset;
		}
		.table--fix-head {
			@include media(sp) {
				table-layout: fixed;
			}

			.btn-brand-s {
				&--ml-auto {
					margin: 0;
					margin-left: auto;
					max-width: 60px;
					border: none;
				}
			}
			.table{
				@include media(sp) {
					border-radius: 0 0 8px 8px;
				}
			}
			tr {
				&:first-of-type{
					th{
						border-radius: 0;
						&:first-of-type{
							border-radius: 8px 0 0 0;
							@include media(sp) {
								border-radius: 0;
							}
						}
						&:last-of-type{
							@include media(sp) {
								border-radius: 0;
							}
						}
					}
				}
				&:last-of-type {
					td:first-of-type {
						border-radius: 0 0 0 8px;
						@include media(sp) {
							border-radius: 0 0 0 8px;
						}
					}
					td:last-of-type {
						border-radius: 0 0 8px 0;
					}
				}


				th {
					width: 12%;
					background-color: #f6f6f4;
					color: #333;
					@include media(sp) {
						width: 33.3333333%;
						text-align: center;
					}
					&:first-of-type {
						width: 64%;
						@include media(sp) {
							width: 33.3333333%;
						}
					}
					&:last-of-type {
						border-radius: 0 8px 0 0;
					}
					&:not(:first-of-type) {
						text-align: center;
					}
				}

				td {
					width: 12%;
					border-radius: 0;
					&:first-of-type {
						width: 64%;
					}
					.txt-link {
						-webkit-transform: none;
						transform: none;
					}
					@include media(sp) {
						position: relative;
						.txt-link {
							display: block;
							margin: 0 auto;
							text-align: center;
							.icon-img {
								&--big {
									width: 27px;
									margin: 0;
								}
							}
						}
					}
				}
				figure {
					margin-left: auto;
					max-width: 60px;
				}

				&:not(:first-of-type) {
					td:not(:first-of-type) {
						position: relative;
						.txt-link {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							@include media(sp) {
								position: static;
								transform: none;
							}

							.icon-img {
								&--big {
									width: 27px;
									margin: 0;
								}
							}
						}
					}
					td:first-of-type {
						display: flex;
						justify-content: space-between;
						width: 100%;

						div:first-of-type {
							width: 70%;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
						}
						div:last-of-type {
							width: 25%;
						}
					}
				}

				.icon-img {
					@include media(sp) {
						width: 27px;
					}
				}
			}
			.sp-table {
				&:not(:last-of-type) {
					margin-bottom: 40px;
				}
			}
			.sp-head {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				align-items: center;
				background-color: #fff;
				padding: 16px;
				border-radius: 8px 8px 0 0;
				border-top: 1px solid #ddd;
				border-left: 1px solid #ddd;
				border-right: 1px solid #ddd;
				&__info {
					width: 75%;
				}
				&__img {
					width: 60px;
				}
			}
		}
	}

	//==========================================
	// package_insert
	//==========================================
	&.package_insert {
		// リンクリスト
		.container {
			overflow: unset;
		}
		.base-list {
			&--link {
				padding: 0;
				li {
					&::before {
						content: none;
					}
				}
			}
		}
		// テーブル
		table {
			&.table--fix-noimg {
				.sticky {
					position: sticky;
					top: 0;
				}

				tr {
					&:first-of-type {
						th {
							border-radius: 0;
							&:first-of-type {
								border-radius: 8px 0 0 0;
							}
						}
					}
					&:last-of-type {
						td:first-of-type {
							border-radius: 0 0 0 8px;
						}
						td:last-of-type {
							border-radius: 0 0 8px 0;
						}
					}
					th {
						width: 12%;
						background-color: #f6f6f4;
						color: #333;
						&:first-of-type {
							width: 64%;
						}
						&:last-of-type {
							border-radius: 0 8px 0 0;
						}
						&:not(:first-of-type) {
							text-align: center;
						}
					}

					td {
						width: 12%;
						border-radius: 0;
						&:first-of-type {
							width: 64%;
						}
						.txt-link {
							-webkit-transform: none;
							transform: none;
						}
					}

					&:not(:first-of-type) {
						td:not(:first-of-type) {
							position: relative;
							.txt-link {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);

								.icon-img {
									&--big {
										width: 27px;
										margin: 0;
									}
								}
							}
						}
						td:first-of-type {
							display: flex;
							justify-content: space-between;
							width: 100%;

							div:first-of-type {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								gap: 10px;
							}
						}
					}
					//商品タグ
					.tag-list {
						@include flex("s", "n");
						align-items: center;
						gap: 10px;
						@include media(sp) {
							flex-wrap: wrap;
						}
					}
					.tag {
						&--a {
							max-width: 92px;
						}
						&--b {
							max-width: 118px;
						}
						&--c {
							@include flex("c", "n");
							flex-direction: row;
							max-width: 70px;
							background: #555555;
							color: #fff;
						}
					}
				}
			}
		}
	}



	//==========================================
	// pb
	//==========================================
	&.pb {
		.header{
			height: 82px;
			@include media(sp) {
				height: 60px;
			}
		}
		.footer{
			border-top: 1px solid #ccc;
			@include media(sp) {
				border: none;
			}
		}


		.products-sec__top{
			margin-bottom: 32px;
			@include media(sp) {
				margin-bottom: 20px;
			}
		}
		.details-sec{
			margin-bottom: 120px;
			@include media(sp) {
				margin-bottom: 60px;
			}
		}
		.base-list li, .notes--number{
			&:not(:last-child){
				margin-bottom: 8px;
			}
			&:has(sup){
				&:before{
					top: 3px;
				}
			}
		}
		.table--03{
			.short-th{
				width: 40%;
			}
			.item{
				@include media(sp) {
					width: 65%;
				}
			}
		}
		.products-sec {
			.sec-in{
				height: auto;
			}
			&__top{
				h1{
					font-size: 32px;
					@include media(sp) {
						font-size: 28px;
					}
				}
			}
			.card--05 {
				width: 32%;
				@include media(sp) {
					display: grid;
					grid-template-columns: 1fr 18%;
					gap:3%;
				}
				.mainImg__tablist {
					grid-template-columns: repeat(4, 1fr);
					@include media(sp) {
						display: flex;
						flex-direction: column;
						gap: 3%;
					}
				}
			}
			&__box{
				.info{
					width: 65%;
				}
			} 
			&__bottom {
				@include media(sp) {
					order: 3;
					overflow: auto;
					width: 100%;
				}
				.bottom-txt {
					font-size: 20px;
					line-height: 1.8;
					margin-bottom: 20px;
				}
				.flex {
					display: flex;
					gap: 24px;
					@include media(sp) {
						gap: 8px;
					}
					li {
						width: 240px;
					}
					.btn {
						max-width: 100%;
						@include media(sp) {
							padding: 0 20px 0 10px;
							min-height: 44px;
						}
					}
				}
				.link-area {
					text-align: right;
					li {
						&:not(:last-child) {
							margin-bottom: 8px;
						}
					}
				}
				.notes--number{
					padding-left: 0;
					&:before{
						content: none;
					}
				}
			}
		}
		.details-sec {
			h2 {
				@include media(sp) {
					font-size: 20px;
					&.l-txt--sp{
						font-size: 18px;
					}
				}
			}
			.btn--red {
				width: 272px;
				@include media(sp) {
					width: 100%;
				}
			}
			.shop__web__btn {
				@include media(sp) {
					width: 100%;
				}
			}
			&__list {
				&--01 {
					display: flex;
					flex-wrap: wrap;
					gap: 32px;
					align-items: flex-end;
					@include media(sp) {
						justify-content: center;
					}

					.sns-icon {
						width: 32px;
					}
				}
			}

			.layout--07 {
				.flex__basic {
					gap: 24px 32px;
					li {
						@include media(sp) {
							width: 100%;
						}
					}
				}
			}
			.accordion__contents {
				.layout--01 {
					&:has(+ .table-box) {
						margin-bottom: 40px;
						@include media(sp) {
							margin-bottom: 20px;
						}
					}
				}
				.layout--d-01 {
					gap: 24px;
					position: relative;
					@include media(sp) {
						justify-content: space-between;
						gap: 0;
					}
					&__img {
						width: 250px;
						@include media(sp) {
							width: 45%;
						}
					}
					&__txt {
						@include media(sp) {
							width: 50%;
						}
					}
				}
				.table-box {
					&:not(:last-of-type) {
						margin-bottom: 40px;
						@include media(sp) {
							margin-bottom: 20px;
						}
					}
					.table {
						&:not(:last-of-type) {
							margin-bottom: 24px;
						}
					}
				}
				.usage-img {
					width: 100px;
				}
				.chatbot {
					display: grid;
					grid-template-columns: 1fr 42%;
					align-items: center;
					padding-left: 80px;
					padding-right: 80px;
					gap: 24px;
					@include media(sp) {
					grid-template-columns: 1fr;
					padding-left: 24px;
					padding-right: 24px;
					gap: 0;
					}
					&__info{
						@include media(sp) {
							display: contents;
							h4{
								order: 1;
								text-align: center;
								font-size: 18px;
							}
							.btn{
								order: 3;
								margin-left: auto;
								margin-right: auto;
							}
						}
					}
					&__img{
						@include media(sp) {
							order: 2;
						}
					}
				}
			}
		}
		div.base-list{
			& > ul{
				& > li{
					position: relative;

			&::before {
				content: "・";
				position: absolute;
				left: -20px;
				top: 0;
			}
				}
			}
		}
		div.base-list.base-list--02{
			li{
				text-indent:0;
				&:before{
					content: none;
				}
			}
			& > ol{
				& > li{
					position: relative;
					list-style-type: decimal;
					& > ol{
						margin-top: 16px;
						padding-left: 24px;
						& > li{
							position: relative;
							list-style-type: none;
							counter-increment: cnt;
							&:not(:last-child){
								margin-bottom: 8px;
							}

				&:before {
					content: "("counter(cnt) ")";
					position: absolute;
    			left: -28px;
					margin-right: 0;
				}
						}
					}
				}
			}
		}
	&.fixed{
		position: fixed;
	}
	}

	// 補足情報
	.supplement-txt {
		padding: 40px;
	}
	.sticky-head {
		position: sticky;
		top: 0;
		z-index: 2;
	}
}
