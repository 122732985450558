@use "./assets_v2/scss/mixin"as *;

#CONTACT {

  .kv{
    background-color: #fff;
    height: auto;
    @include media(sp) {
      padding-bottom: 0;
    }
    &::before{
      content: none;
    }
  }
 .kv__ttl{
  margin: getvwPc(70) 0;
  height: auto;
  @include media(sp) {
    padding: 0;
    margin: 40px 0;
  }
	h1{
		&:focus {
			outline: 4px solid #007921;
			outline-offset: 4px;
		}
	}
 }

//===========================================
// フォームステップ
//===========================================

.contact-step {
	&__list {
		display: flex;
		gap: 0 32px;
		@include media(sp) {
			gap: 0 24px;
		}
	}
	&__item {
		width: calc(33.3333% - 24px);
		background-color: #eee;
		height: 68px;
		border-radius: 12px 0 0 12px;
		display: flex;
		gap: 0 8px;
		flex-wrap: wrap;
		padding-left: 16px;
		align-items: center;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		position: relative;
		@include media(sp) {
			padding-left: 8px;
			width: calc(33.3333% - 12px);
			gap: 0 8px;
		}
		&::after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 34px 0 34px 24px;
			border-color: transparent transparent transparent #eee;
			top: 0;
			right: -24px;
			position: absolute;
			@include media(sp) {
				border-width: 34px 0 34px 12px;
				right: -11.9px;
			}
		}
		.number {
			color: #005baa;
			display: inline-block;
			font-size: 20px;
			@include media(sp) {
				width: 10%;
			}
			img {
				width: 15px;
				display: block;
			}
		}
		.txt {
			@media only screen and (max-width: 767px) {
				width: 80%;
				line-height: 1.2;
			}
		}
		&.current {
			background-color: #005baa;
			color: #fff;
			span {
				color: #fff;
			}
			&::after {
				border-color: transparent transparent transparent #005baa;
			}
		}
	}
}

//===========================================
// ガイドライン
//===========================================

.guidelines {
	padding-left: 18px;
	&__item {
		list-style-type: decimal;
		padding-left: 10px;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}

//===========================================
// エラーボックス
//===========================================
.error-box {
	display: none;
	&__in {
		border: 1px solid #db342e;
		border-radius: 16px;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
	}
	&__ttl {
		background-color: #db342e;
		width: 74px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		padding: 16px 0;
		@media only screen and (max-width: 767px) {
			width: 100%;
			justify-content: flex-start;
			padding: 0;
		}
		.tti-in {
			@media only screen and (max-width: 767px) {
				display: flex;
				align-items: center;
				gap: 0 10px;
				padding: 8px;
			}
			img {
				width: 28px;
				margin: 0 auto;
				display: block;
				@media only screen and (max-width: 767px) {
					width: 24px;
				}
			}
			h2 {
				text-align: center;
				color: #fff;
				font-size: 14px;
				@media only screen and (max-width: 767px) {
					margin-top: 0;
				}
			}
		}
	}
	&__txt {
		padding: 16px 16px 16px 36px;
		color: #d80c18;
		width: calc(100% - 74px);
		display: flex;
		align-items: center;
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
		ul {
			li {
				list-style-type: disc;
				font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
				padding-left: 8px;
				font-weight: bold;
			}
		}
	}
}

//===========================================
// フォームテーブル
//===========================================
.form-table {
	width: 100%;
	border-top: 1px solid #767676;
	margin: 0 0 48px;
	border-collapse: collapse;
	table-layout: fixed;

	&__box {
		border-bottom: 1px solid #767676;
		padding: 32px 0;
		&:after {
			display: block;
			clear: both;
			content: "";
		}
	}

	&__flex-box {
		display: flex;
	}

	&__q-item {
		width: 192px;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		padding-right: 32px;
		float: left;
		@include media(sp) {
			width: 100%;
			float: none;
			padding-right: 0;
			margin-bottom: 16px;
		}
		.tag--05{
    	margin-top: 4px;
		}
		&:has( > .tag--05){
			margin-top: -4px;
		}
	}

	&__input {
		float: left;
		width: calc(100% - 192px);
		@include media(sp) {
			width: 100%;
			float: none;
		}
	}

	input {
		&[type="text"],
		&[type="email"],
		&[type="tel"],
		&[type="number"],
		&[name="zip"] {
			width: 100%;
			height: 50px;
			line-height: 50px;
			padding: 0 15px;
			border: 2px solid #767676;
			border-radius: 4px;
			box-sizing: border-box;
			font-size: 16px;
			font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

			@include media(sp) {
			}
		}

		&[type="radio"] {
			appearance: none;
			position: relative;
			right: 0;
			bottom: 0;
			left: 0;
			height: 24px;
			width: 24px;
			vertical-align: -4px;
			transition: 0.2s;
			cursor: pointer;
			display: inline-block;
			margin-right: 8px;
			border: 1px solid #005baa;
			border-radius: 50%;
			@media only screen and (max-width: 767px) {
				height: 20px;
				width: 20px;
			}
			&:before {
				content: "";
				display: block;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				position: absolute;
				top: calc(50% - 14px / 2);
				left: calc(50% - 14px / 2);
				transform: scale(0);
				transition: 0.2s;
				background: #005baa;
				@media only screen and (max-width: 767px) {
					top: calc(50% - 12px / 2);
					left: calc(50% - 12px / 2);
					width: 12px;
					height: 12px;
				}
			}
			&:checked:before {
				transform: scale(1);
			}
			&:focus-visible{
				outline: 4px solid #007921;
				outline-offset: 4px;
			}
		}
	}

	textarea {
		border: 2px solid #767676;
		width: 100%;
		border-radius: 4px;
		font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
		padding: 16px;
		font-size: 16px;
		&.textarea-tall {
			height: 265px;
		}
	}

	p {
		&.error {
			color: #db342e;
			display: block;
			width: 100%;
			&:first-of-type {
				margin-top: 8px;
			}
		}
	}

	.select-box {
		width: auto;
		text-align: center;
		position: relative;

		&::before {
			position: absolute;
			top: calc(50% - 12px / 2);
			right: 20px;
			content: "";
			width: 8px;
			height: 8px;
			border-top: 2px solid #333;
			border-right: 2px solid #333;
			transform: rotate(135deg);
			pointer-events: none;
		}

		select {
			padding: 0 38px 0 20px;
			height: 50px;
			width: 100%;
			font-size: 16px;
			line-height: 50px;
			padding: 0 15px;
			border: 2px solid #767676;
			border-radius: 4px;
			cursor: pointer;
			text-overflow: ellipsis;
			background: transparent;
			background-image: none;
			box-shadow: none;
			appearance: none;
			color: #333;

			&::-ms-expand {
				display: none;
			}
		}
	}

	input,
	textarea,
	select {
		&.error {
			background: #fbe9e8;
			border-color: #db342e;
			& + .input-checkbox__txt::before {
				background: #fbe9e8;
				border-color: #db342e;
			}
		}
	}

	::placeholder {
		color: #333;
		font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
	}

	.flex {
    display: flex;
    flex-wrap: wrap;
		gap: 16px 32px;
		@media only screen and (max-width: 767px) {
			display: flex !important;
			gap: 16px 20px;
		}
		&--between {
			@media only screen and (max-width: 767px) {
				justify-content: space-between !important;
				gap: 0 !important;
				.w-160 {
					width: 100%;
				}
			}
		}
	}
	.vertical-box {
		li {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}

	.s-txt {
		display: block;
		font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
		&--lp {
			padding-left: 30px;
			margin-top: 4px;
		}
		&--inline {
			display: inline-block;
		}
	}
	.top-txt {
		label {
			display: block;
			margin-bottom: 4px;

			&.inline {
				display: inline-block;
			}
		}
		&__01 {
			@media only screen and (max-width: 767px) {
				width: 48%;
			}
		}
	}

	.other-input {
		margin-left: 30px;
		display: block;
		margin-top: 16px;
		@media only screen and (max-width: 767px) {
			max-width: calc(100% - 30px);
		}
	}
	.input-txt {
		display: inline-block;
		&--left {
			padding-right: 16px;
		}
		&--right {
			padding-left: 16px;
		}
	}

	.uplode-list {
		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px;
			flex-wrap: wrap;
			&:nth-child(odd) {
				background-color: #f6f6f4;
			}
			input[type="file"] {
				display: none;
			}
			.txt {
				display: flex;
				align-items: center;
				width: 60%;
				@include media(sp) {
					display: block;
					width: 50%;
				}
				&__name {
					color: #005baa;
					font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
					margin-right: 16px;
					width: 90px;
					@include media(sp) {
						width: 100%;
					}
				}
				.file-name {
					width: calc(100% - 90px);
					@include media(sp) {
						width: 100%;
					}
				}
			}
			.btn {
				font-size: 16px;
				max-width: max-content;
				padding: 0 10px;
				padding-top: 3px;
				min-height: 32px;
			}
		}
	}
	.txt-list {
		padding-left: 16px;
		&--pl0 {
			padding-left: 0;
		}
		&__item {
			list-style-type: disc;
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	.confirm-flex {
		dl {
			display: flex;
			dt {
				width: 60px;
			}
		}
	}

	//チェックボックス
	//==========================================

	.input-checkbox {
		cursor: pointer;
		input[type="checkbox"] {
			position: absolute;
			opacity: 0;
			&:checked {
				& + .input-checkbox__txt {
					&::before {
						border-color: #005baa;
						@include media(sp) {
							border-width: 2px;
						}
					}
					&::after {
						opacity: 1;
					}
				}
			}
			&:focus-visible {
				& + .input-checkbox__txt::before {
					outline: 4px solid #007921;
					outline-offset: 4px;
				}
			}
		}
		&__txt {
			position: relative;
			display: inline-flex;
			align-items: center;
			padding: 0 0 0 32px;
			@include media(sp) {
				padding: 0 0 0 24px;
			}
			&::before,
			&::after {
				content: "";
				position: absolute;
				box-sizing: border-box;
			}
			&::before {
				top: auto;
				left: 0;
				background: #fff;
				border: 1px solid #767676;
				border-radius: 4px;
				aspect-ratio: 1;
				width: 24px;
				@include media(sp) {
					width: 20px;
				}
			}
			&::after {
				top: auto;
				left: 2px;
				background: url('../img/common/icon_check.svg') no-repeat 0 0 / 100% auto;
				opacity: 0;
				aspect-ratio: 19 / 14;
				width: 19px;
				@include media(sp) {
					width: 15px;
				}
			}
		}
	}

	// 入力欄の長さ
	//===========================================
	input,
	.select-box,
	textarea {
		&.w-80 {
			width: 80px;
		}
		&.w-160 {
			width: 160px;
		}
		&.w-352 {
			width: 352px;
			@include media(sp) {
				width: 100%;
			}
		}
	}
}

.w-100{
	width: 100%;
}

// 確認用テーブル
//===========================================

.form-table {
	width: 100%;
	border-top: 1px solid #767676;
	margin: 0 0 48px;
	border-collapse: collapse;
	table-layout: fixed;

	tr {
		border-bottom: 1px solid #767676;
		@media only screen and (max-width: 767px) {
			padding: 24px 0;
			display: block;
		}
	}

	th,
	td {
		padding: 32px 0;
		text-align: left;
		white-space: normal;
		@media only screen and (max-width: 767px) {
			width: 100%;
			display: block;
			padding: 0;
		}
	}

	.form-table__item {
		width: 150px;
		font-size: 18px;
		vertical-align: top;
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
		font-weight: bold;
		padding-left: 0;
		@media only screen and (max-width: 767px) {
			margin-bottom: 16px;
			width: 100%;
		}
		.s-txt {
			display: block;
			font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
		}
	}
	td {
		padding-left: 40px;
		@include media(sp) {
			padding-left: 0;
		}
	}
}

.privacy-box {
	border: 1px solid #ddd;
	border-radius: 12px;
	padding: 16px;
	&__ttl {
		font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	}
	&__list {
		padding-left: 20px;
	}
	&__item {
		list-style-type: disc;
	}
}
.other-sec {
	.flex__column-2 {
		width: 100%;
    align-items: center;
		li {
			button, p {
				height: 100%;
				max-width: 100%;
			}
			@include media(sp) {
				&:nth-child(1){
					order: 2;
				}
				&:nth-child(2){
					order: 1;
				}
			}
		}
	}
}

// その他
//===========================================
	.pi-list{
		padding-left: 24px;
		li{
			text-indent: 0;
			position: relative;
			&:not(:last-child){
				margin-bottom: 24px;
			}
			&::before{
				position: absolute;
				font-size: 20px;
				left: -24px;
				font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
				font-weight: bold;
				@include media(sp) {
					top: -2px;
				}
			}
		}
	}
}