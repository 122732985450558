@use "./assets_v2/scss/mixin" as *;

#SUPPORT {

	//==========================================
  // 汎用CSS
  //==========================================
	.contact-area {
		text-align: center;

		.column {
			&:first-of-type {
				border-right: 1px solid #707070;

				@include media(sp) {
					border-right: none;
					border-bottom: 1px solid #707070;
					margin-bottom: 32px;
					padding-bottom: 28px;
				}
			}
		}

		.tel {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&>span {
				position: relative;
				font-size: 24px;
				font-family: $ff-bold;
				padding: 0 0 0 38px;

				@include media(sp) {
					padding: 0 0 0 42px;
				}

				.icon-tel {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: url('../img/company/assortment/icon_tel_01.svg') no-repeat 0 0 / 100% auto;
					aspect-ratio: 1;
					width: 30px;
				}
			}
		}
	}


	.video-list {
		button{
			display: block;
			p{
				font-size: 16px;
				text-align: left;
				font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
			}
		}
		.img {
			position: relative;
			aspect-ratio: 16 / 9;
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				pointer-events: none;
				aspect-ratio: 1;
				width: 48px;
				height: auto;
			}
		}
	}

	//==========================================
  // 動画モーダルウィンドウ
  //==========================================
    
	.popup-content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		margin: auto;
	}

	.mfp-close {
		position: absolute;
		display: block;
		top: -15px;
    right: -55px;
		aspect-ratio: 1;
		width: 60px;
		height: auto;
		@include media(sp) {
			top: 0px;
			right: -38px;
			width: 30px;
		}

		&::before, &::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			height: 2px;
			background: #fff;
			width: 34px;
			transform-origin: 0% 0%;
		}

		&::before {
			transform: rotate(45deg) translate(-50%, -50%);
		}

		&::after {
			transform: rotate(-45deg) translate(-50%, -50%);
		}
	}

	.mfp-s-ready {
		.popup-content {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			background: transparent !important;
			opacity: 1;
			visibility: visible;
			aspect-ratio: 16 / 9;
			width: 80%;
			max-width: 853px;
			height: auto;

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}
	//==========================================
  // top
  //==========================================
	&.top{

		.kv {
      &::after {
				background-image: url("../../assets_v2/img/support/top/kv.jpg");
        @include media(sp) {
          background-image: url("../../assets_v2/img/support/top/kv_sp.jpg");
        }
      }
    }
		.img-ttl{
			position: relative;
			padding-left: 52px;
			img{
				position: absolute;
				left: 0;
				top: 2px;
				width: 40px;
				margin-right: 12px;
				display: inline-block;
				@include media(sp) {
					top: -6px;
			}
		}
	}
		.flex__column-2{
			@include media(sp) {
				gap: 0;
			}
			.info{
				@include media(sp) {
					display: contents;
					h2{
						order: 1;
						& + p{
							order: 3;
							line-height: 1.6;
						}
					}
					.btn-list, .btn{
						order: 4;
					}
				}
			}
			.img{
				@include media(sp) {
					order: 2;
					margin-bottom: 16px;
				}
			}
		}
		.lh-txt{
			@include media(sp) {
				line-height: 1.6;
			}
		}
		.menu-sec{
			.btn{
				max-width: 100%;
				&__txt{
					text-align: left;
					@include media(sp) {
						text-align: center;
					}
				}
			}
			.layout.layout--02.border-red,
			.layout.layout--01.bg-blue,
			.layout.layout--01.border-gray,
			.layout.layout--01.bg-gray{
				strong {
					font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
					font-weight: bold;
					display: inline-block;
				}
	
				a {
					text-decoration: underline;
					color: #005baa;
	
					&:hover,
					&:focus-visible {
						text-decoration: none;
					}
				}
			}
		}
		.contact-sec{
			.card--10{
				width: 352px;
				margin: 0 auto 24px;
				@include media(sp) {
					width: 100%;
					margin: 0 auto 40px;
				}
			}
			.gap-02{
				@include media(sp) {
					gap: 8px;
				}
			}
		}
		.window-sec{
			.btn-list{
				li{
					&:not(:last-child){
						margin-bottom: 16px;
						@include media(sp) {
							margin-bottom: 8px;
						}
					}
				}
			}
		}

		.shop__web__btn, .detailsAccordion .accordion__panel{
			width: 100%;
		}

		.shop__web__btn{
			font-size: 16px;
		}
		.webShop__list li{
			margin-bottom: 0 !important;
			a{
				color: #005baa;
				justify-content: flex-start;
				font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
    		font-weight: bold;
				padding: 0 16px;
				flex-wrap: wrap;
				.s-txt{
					margin: -4px 0 0;
					font-size: 14px;
				}
				.icon-img{
    			position: absolute;
    			width: 18px;
    			right: 4px;
				}
			}
		}
	}

	//==========================================
	//contact
	//==========================================
  &.contact{
	// kv
	.kv {
		&::after {
			background-image: url('../../assets_v2/img/support/contact/main_pc.jpg');
			@include media(sp) {
				background-image: url('../../assets_v2/img/support/contact/main_sp.jpg');
			}
		}
	}

	h2, h3{
		position: relative;
		&:has( > .icon-img){
			padding-left: 52px;
		}
		.icon-img{
			position: absolute;
			left: 0;
			top: 2px;
		}
	}
	h3{
		.icon-img{
			top: -2px;
			@include media(sp) {
				top: -4px;
			}
		}
	}

	.layout--01{
		.l-txt{
			@include media(sp) {
				font-size: 18px;
			}
		}
	}

	.btn{
		.icon, .icon-img{
		width: 32px;
	}
	}

	.layout-list{
		li{
			&:not(:last-child){
				margin-bottom: 16px;
				padding-bottom: 16px;
				border-bottom: 1px solid #ddd;
			}
		}
	}
	
	.products-sec{
		.layout--01{
			.btn{
				max-width: 100%;
				height: auto;
			}
		}
		&__txt{
			font-size: 20px;
			@include media(sp) {
				font-size: 16px;
			}
		}
	}
	.use-sec{
		&__flex{
			justify-content: center;
			display: flex;
			li{
				width: 352px;
				@include media(sp) {
					width: 33.333%;
				}
				.btn{
					max-width: 100%;
					&__txt{
						text-align: left;
						@include media(sp) {
							text-align: center;
						}
					}
				}
			}
		}
	}
	.addiction-box{
		max-width: 736px;
		margin: 0 auto 80px;
		@include media(sp) {
			margin: 0 auto 40px;
		}
		.column:first-of-type{
			border: none;
			@include media(sp) {
			border: none;
			}
		}
	}

	.sec-01{
		.layout.layout--02.border-red,
		.layout.layout--01.bg-blue,
		.layout.layout--01.border-gray,
		.layout.layout--01.bg-gray{
				strong {
					font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
					font-weight: bold;
					display: inline-block;
				}
	
				a {
					text-decoration: underline;
					color: #005baa;
	
					&:hover,
					&:focus-visible {
						text-decoration: none;
					}
				}
			}
		}
		.no-height{
			height: auto;
		}
  }
	//==========================================
  // Voice
  //==========================================
  &.voice {
	
		.voice__menu__lead {
			font-size: 18px;
			text-align: center;
			.text_l {
				display: inline-block;
				background: linear-gradient(transparent 70%, #FFFF00 50%);
				text-decoration: none;
			}
			.text_s_b {
				display: block;
				text-decoration: none;
			}
		}
		.voice__menu__image {
			height: 110px;
			width: 100%;
			align-self: flex-end;
			img {
				display: block;
				height: 110px;
				width: auto;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.tab-contents {
			.voicelist {
				&.gap-02 {
					@include media(sp) {
						gap: 20px 8px;
					}
				}
				.voicelist-linktxt {
					color: #005baa;
					text-decoration: underline;
				}
				.card__link-in {
					@include hover {
						.voicelist-linktxt {
							text-decoration: none;
						}
					}
				}
			}
		}
		

	}
		
}


