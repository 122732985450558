@use "./assets_v2/scss/mixin" as *;

#NEWSROOM {

  //==========================================
  // socialmediaコンテンツスタイル適用
  //==========================================
  &.socialmedia {
    .icon-logo {
      width: 75px;
      margin-right: 16px;
    }

    .flex {
      &.aligncenter {
        align-items: center;
      }
    }

    .border-accordion {
      border: none;
      border-bottom: 4px solid #fff;
      margin: 0;
    }

    .radius-social1 {
      border-radius: 8px 8px 0 0;
    }

    .radius-social2 {
      border-radius: 0 0 8px 8px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .snscard {
      li {
        display: flex;
        flex-direction: column;

        .layout {
          &:nth-of-type(2) {
            cursor: pointer;
          }
        }

        .btn {
          .icon--img {
            margin-right: 14px;
          }
        }
      }
    }

    .btn--dummy {
      display: block;
      width: 100%;
      min-height: 56px;

      @include media(sp) {
        display: none;
      }
    }

    .txt-link {
      .icon--img {
        margin-right: 8px;
      }
    }

    .policy-sec {
      .text-right {
        @include media(sp) {
          text-align: left;
        }
      }
    }

    .sec-etc {
      hr {
        border-color: #fff;
        margin: 24px 0;
      }

      .layout--01 {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .layout{
      &:has(.dns-box){
        cursor:auto !important;
      }
    }
    .dns-box {
      padding: 24px 0;
      h5{
        margin-bottom: 32px;
      }
      .btn-app-list {
        display: grid;
        grid-template-columns: 42% 1fr;
        gap: 6.5%;

        a {
          &:hover {
            opacity: .6;
          }
        }
      }
    }
  }

  .flex.gap-01 {
    @include media(sp) {
      gap: 32px;
    }
  }

  .accordion {
    @include media(sp) {
      width: 100vw;
      margin: 0 calc(50% - 50vw);
    }
  }
}