@use "./assets_v2/scss/mixin" as *;

.footer {
  position: relative;
  .btn-top {
    position: fixed;
    right: 16px;
    bottom: 32px;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: .2s;
    aspect-ratio: 1;
    width: 48px;
    @include media(sp) {
      right: 10px;
      bottom: 39px;
    }
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
    &.is-bottom {
      position: absolute;
      @include media(pc2) {
        top: -80px;
        bottom: unset;
      }
      @include media(sp) {
      }
    }
  }
  &__top {
    background-color: #f6f6f4;
    padding: 40px 0;
    @include media(sp) {
      padding: 0;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    .nav-list1 {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0 48px;
      margin-bottom: 18px;
      @include media(sp) {
        gap: 0;
        margin-bottom: 0;
        justify-content: left;
      }
      .nav-list__item {
        @include media(sp) {
          width: 50%;
          border-bottom: 1px solid #ccc;
        }
        &:first-child {
          @include media(sp) {
            display: none;
          }
        }
        &:nth-child(even) {
          @include media(sp) {
            border-right: 1px solid #ccc;
          }
        }
      }
      a {
        display: block;
        font-size: 18px;
        font-family: $ff-bold;
        @include media(sp) {
          font-size: 16px;
          padding: 20px 23px;
        }
      }
    }
    .nav-list2 {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0 50px;
      @include media(sp) {
        gap: 0;
        justify-content: left;
      }
      .nav-list__item {
        @include media(sp) {
          width: 50%;
          border-bottom: 1px solid #ccc;
        }
        &:nth-child(odd) {
          @include media(sp) {
            border-right: 1px solid #ccc;
          }
        }
        &:last-child {
          @include media(sp) {
            border-bottom: none;
          }
        }
      }
      a {
        font-size: 16px;
        display: block;
        @include media(sp) {
          font-size: 14px;
          padding: 13px 23px;
        }
      }
    }
  }
  &__bottom {
    width: 100%;
    margin: 40px auto 0;
    .wrap__other-list {
      @include media(sp) {
        border-top: 1px solid #cccccc;
      }
    }
    .other-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0 28px;
      max-width: 880px;
      margin: 0 auto 40px;
      @include media(sp) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px 32px;
        padding: 40px 46px 0 23px;
        margin-bottom: 40px;
      }
      .other-list__item {
        font-size: 13px;
        @include media(sp) {
          width: auto;
					font-size: 13px;
          &:nth-of-type(1),
          &:nth-of-type(6) {
            width: 100%;
          }
        }
      }
    }
    .logo-list {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0 64px;
      margin-bottom: 40px;
      @include media(sp) {
        width: 180px;
        margin-bottom: 40px;
      }
      .logo-list__item {
        width: 180px;
        @include media(sp) {
          width: 100%;
        }
        & + .logo-list__item {
          @include media(sp) {
            margin-top: 25px;
          }
        }
      }
      .logo-list__item--dshc {
        width: 273px;
        @include media(sp) {
          width: 100%;
        }
      }
    }
  }
  .c_right {
    background-color: #005baa;
    color: #fff;
    text-align: center;
    font-size: 10px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
		@include media(sp) {
			text-align: left;
			height: auto;
			font-size: 12px;
			padding: 16px 0;
		}
  }
  

  a, button {
    cursor: pointer;
  }

  #ot-sdk-btn.ot-sdk-show-settings{
    color: #333 !important;
    border:none !important;
    height: auto !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    padding: 0 !important;
    font-size: 13px !important;
    line-height: 1.2 !important;
    transition:none !important;
    font-family: "Hiragino UD Sans W3 JIS2004", sans-serif !important;
    &:hover{
      color: #333 !important;
      background-color:#fff !important;
    }
  }
}

.footer__sns {
  .footer__sns-ttl {
    font-size: 18px;
    text-align: center;
    font-family: $ff-bold;
    margin-bottom: 24px;
    @include media(sp) {
      font-size: 16px;
    }
  }
  .footer__sns-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 40px;
    margin-bottom: 20px;
    @include media(sp) {
      gap: 0 30px;
      margin-bottom: 30px;
    }
    .icon_youtube {
      width: 45.71px;
    }
    .icon_x {
      width: 32px;
    }
  }
  .link-area {
    text-align: center;
    margin-bottom: 40px;
    .txt-link {
      font-size: 16px;
      font-family: $ff-bold;
    }
  }
}
