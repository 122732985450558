@use "./assets_v2/scss/mixin" as *;

#RECRUIT {
	//==========================================
	// 汎用CSS
	//==========================================
	.prev-sec {
		&__border {
			border-top: 1px solid #ddd;
		}
	}
	//==========================================
	// top
	//==========================================
	&.top {
		.kv {
			&::after {
				background-image: url("../../assets_v2/img/recruit/top/kv.jpg");
				background-size: contain;
				@include media(sp) {
					background-image: url("../../assets_v2/img/recruit/top/kv_sp.jpg");
					background-size: contain;
				}
			}
		}
		.contribution-sec {
			&__ttl {
				font-size: 30px;
				@include media(sp) {
					font-size: 24px;
				}
			}
		}
		.company-sec {
			&__box {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-wrap: wrap;
				gap: 0 32px;
				h2, h3 {
					width: 100%;
				}
				.img,
				.info {
					flex: 1 1;
				}
				.img {
					margin-top: -56px;
					width: 48%;
					@include media(sp) {
						width: 100%;
						margin-top: 0;
						margin-bottom: 22px;
						order: 1;
					}
				}
				.info {
					@include media(sp) {
						order: 2;
					}
				}
			}
		}

		.layout {
			&--05 {
				display: flex;
    		flex-direction: column;
				.btn {
					padding: 0 30px 0 20px;
				}
				h3{
					height: 60px;
					@include media(sp) {
						height: auto;
					}
				}
			}
		}
		.list-in{
			flex-grow: 1;
		}
	}

	//==========================================
	// career
	//==========================================
	&.career {
		.kv {
			&::after {
				background-image: url("../../assets_v2/img/recruit/career/kv.jpg");
				@include media(sp) {
					background-image: url("../../assets_v2/img/recruit/career/kv_sp.jpg");
				}
			}
		}

		.layout {
			&--05 {
				position: relative;
				padding-bottom: 110px;
				@include media(sp) {
					padding-bottom: 40px;
				}
				.btn {
					position: absolute;
					bottom: 24px;
					width: calc(100% - 48px);
					@include media(sp) {
						position: relative;
						width: auto;
						margin-top: 32px;
						bottom: auto;
					}
				}
			}
		}
	}

	//==========================================
	// referral
	//==========================================
	&.referral {
		.kv {
			&::after {
				background-image: url("../../assets_v2/img/recruit/referral/kv.jpg");
				@include media(sp) {
					background-image: url("../../assets_v2/img/recruit/referral/kv_sp.jpg");
				}
			}
		}
		.step-sec {
			&__item {
				border-radius: 10px;
				background-color: #fff;
				padding: 16px;
				text-align: center;
				position: relative;
				@include media(sp) {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
				}
				&:not(:last-child) {
					&::after {
						content: "";
						display: inline-block;
						position: absolute;
						width: 62px;
						height: 91px;
						top: calc(50% - 91px / 2);
						right: -50px;
						background-repeat: no-repeat;
						background-image: url("../../assets_v2/img/recruit/referral/flow_arrow.svg");
						background-size: contain;
						vertical-align: middle;
						z-index: 2;
						@include media(sp) {
							transform: rotate(90deg);
							width: 39px;
							height: 58px;
							right: calc(50% - 39px / 2);
							bottom: -40px;
							top: auto;
						}
					}
				}
				.step {
					color: #fff;
					background-color: #005baa;
					text-align: center;
					border-radius: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 36px;
					width: max-content;
					margin: 0 auto 8px;
					padding: 0 24px;
					font-size: 14px;
					@include media(sp) {
						font-size: 13px;
						margin: 0 8px 0 0;
					}
					span {
						font-size: 18px;
						display: inline-block;
						margin-left: 8px;
						@include media(sp) {
							margin-left: 4px;
							font-size: 26px;
						}
					}
				}
				.ttl {
					line-height: 1.6;
					color: #005baa;
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 50px;
					@include media(sp) {
						min-height: auto;
						justify-content: flex-start;
						text-align: left;
					}
				}
				.img {
					width: 80%;
					margin: 0 auto;
					display: block;
					@include media(sp) {
						width: 160px;
						margin: 0 0 0 auto;
					}
					@media only screen and (max-width: 767px) {
						width: 100%;

						img {
							width: 60%;
							margin: 8px auto 0;
							display: block;
						}
					}
				}
			}
		}
	}

	//==========================================
	// careerrate
	//==========================================
	&.careerrate{
		.right-txt{
			text-align: right;
		}
	}

	//==========================================
	// message
	//==========================================

	&.message {
		.representative {
			position: relative;

			&__img {
				background: url("../../assets_v2/img/recruit/message/message_img_01.jpg") 0 0 no-repeat;
				background-size: cover;
				aspect-ratio: 27 / 10;
				margin-bottom: 80px;

				@include media(sp) {
					background: url("../../assets_v2/img/recruit/message/message_img_01_sp.jpg") 0 0 no-repeat;
					height: auto;
					aspect-ratio: 67.1 / 38;
					background-size: cover;
					margin-bottom: 40px;
				}

				&+h2 {
					position: absolute;
					left: 80px;
					top: 50%;
					transform: translateY(-50%);

					@include media(sp) {
						position: static;
						margin-bottom: 32px;
						transform: none;
					}
				}
			}
		}

		.flex__basic {
			align-items: center;
			justify-content: flex-end;
			gap: 32px;
			@include media(sp) {
				justify-content: center;
			}

			h3 {
				width: 242px;
				@include media(sp) {
					width: 50%;
				}
			}
		}
	}

	
}
