@use "./assets_v2/scss/mixin"as *;
@use "./assets_v2/scss/component"as *;

#COMPANY {


  //==========================================
  // 汎用CSS
  //==========================================

  .card--06__blank {
    position: relative;

    h3 {
      padding-right: 20px;

      .icon-blank {
        top: 26px;
        right: 24px;
      }
    }
  }

  //==========================================
  // TOP
  //==========================================
  &.top {
    .mt-64 {
      margin-top: 64px;

      @include media(sp) {
        margin-top: 32px;
      }
    }

    .mt-8--sp {
      @include media(sp) {
        margin-top: 8px;
      }
    }

    .kv {
      height: getvwPc(420);

      @include media(sp) {
        height: auto;
      }

      &__ttl {
        height: getvwPc(338);

        @include media(sp) {
          height: auto;
        }
      }

      &::after {
        left: 0;
        background-image: url("../../assets_v2/img/company/top/kv.jpg");
        background-size: cover;
        width: 100%;
        height: 100%;

        @include media(sp) {
          background-image: url("../../assets_v2/img/company/top/kv_sp.jpg");
        }
      }

      &__in {
        position: relative;
        z-index: 2;
      }
    }

    .notice-sec {
      h2 {
        @extend h5;
      }
    }

    .sec-ttl {
      display: flex;
      align-items: center;

      .icon-img {
        margin-right: 16px;
      }
    }

    .news-release-sec {
      hr {
        border-top: 1px solid #ddd;
        margin-top: 0;
      }

      .date,
      .tag {
        @extend .bold-txt;
      }

      .base-list--05 {
        &>li {
          padding: 0;
          margin: 0;

          a {
            display: block;
            text-decoration: none;
            width: 100%;
            padding: 24px 0;

            @include hover {
              .tag {
                background-color: #ccf2ff;
              }

              .txt-link {
                text-decoration: none;
              }
            }
          }
        }

        .list-item__left {
          @include media(sp) {
            margin-bottom: 0;
          }
        }
      }
    }

    .about-dshc-sec {
      @include media(sp) {
        margin-bottom: 40px;
      }

      .sec-in--small {
        @include media(pc) {
          max-width: 1006px;
        }
      }

      .about-dshc-header {
        position: relative;

        .sec-in {
          position: absolute;
          right: 0;
          left: 0;
          text-align: right;

          @include media(pc) {
            top: 0;
            height: 100%;
            padding-top: getvwPc(168);
          }

          @include media(sp) {
            bottom: 0;
            text-align: left;
          }

          h2 {
            font-size: 36px;

            @include media(sp) {
              font-size: 24px;
            }
          }
        }
      }

      .sec-in--bg {
        @include media(sp) {
          padding-top: 40px;
        }

        &>.sec-in {
          @include media(sp) {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

      .flex__column-2--sp {
        @include media(sp) {
          gap: 8px;
        }
      }

      .flex--btn {
        @include media(sp) {
          gap: 8px;
        }
      }

      .page-list {
        @include media(sp) {
          grid-template-columns: repeat(2, 1fr);
          gap: 20px 8px;
        }

        &>li {
          border-bottom: 1px solid #767676;
          padding-bottom: 14px;

          .txt-link {
            display: block;
            color: inherit;
            text-decoration: none;
          }
        }
      }

    .flex--btn.flex.flex__basic.center.gap-01.mt-64{
      width: 100vw;
      margin: 64px calc(50% - 50vw) 0;
      padding: 0 24px;
      @include media(sp) {
        margin: 32px auto 0;
        width: 100%;
        padding: 0;
      }
    }
    }

    .about-other-sec {
      .card--07 {
        min-height: 335px;

        @include media(sp) {
          min-height: auto;
        }

        .card__link {
          @include media(sp) {
            display: grid;
            grid-template-columns: 90px 1fr;
          }
        }

        .card__info--01 {
          @include media(sp) {
            border-top: none;
            border-left: 1px solid #ddd;
          }
        }
      }

      .l-ttl {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .bnr {
        max-width: 176px;

        @include media(sp) {
          max-width: 106px;
        }
      }

      .card--07__img {
        @include media(sp) {
          border-radius: 8px 0 0 8px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  //==========================================
  // 会社概要
  //==========================================
  &.data {
    .data-sec {
      &__flex {
        display: grid;
        grid-template-columns: 180px 1fr;
        gap: 32px 0;
        border-bottom: 1px solid #ddd;
        padding-bottom: 32px;
        margin-bottom: 32px;

        @include media(sp) {
          grid-template-columns: 1fr;
          gap: 16px 0;
        }

        .bm-list {
          li {
            &:not(:last-child) {
              margin-bottom: 32px;
            }
          }
        }
      }

      .table--data {
        display: block;

        tr {
          display: grid;

          @include media(pc) {
            grid-template-columns: 144px 1fr;
            gap: 32px 16px;
          }

          @include media(sp) {
            row-gap: 12px;
          }

          &:not(:last-of-type) {
            border-bottom: 1px solid #ddd;
            padding-bottom: 32px;
          }
        }

        th {
          text-align: left;
        }
      }
    }
  }

  //==========================================
  // 製造終了品一覧
  //==========================================
  &.end_products {
    .anchor-nav {
      .flex__column-4 {
        @include media(sp) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .cat-sec {
      &+.cat-sec {
        margin-top: -40px;
      }

      .card--04 {
        display: flex;
        flex-direction: column;

        @include media(sp) {
          width: 100%;
        }

        &__bottom {
          margin-top: auto;

          h4 {
            @include media(sp) {
              font-size: 18px;
            }
          }
        }
      }

      .list {
        @extend .bold-txt;

        @include media(pc) {
          display: grid;
          gap: 8px;
          text-align: right;
        }

        @include media(sp) {
          font-size: 14px;
          gap: 12px;
        }
      }
    }

    .sec-other {
      @include media(pc) {
        text-align: center;
      }
    }
  }

  //==========================================
  // safetyコンテンツスタイル適用
  //==========================================

  &.safety {

    //liのモディファイア追加（）
    .base-list {
      &--none li {
        list-style: none;
      }
    }

    // table（日本国内の労働安全衛生に関する制度・取り組み）
    .table {
      &.table--secondary {
        tr:first-of-type {
          th {
            background-color: #005baa;
            color: #fff;
          }
        }

        tr:not(:first-of-type) th {
          background-color: #f5f5f5;
          color: #333;
        }

        tr:first-of-type th:nth-of-type(1) {
          border-radius: 8px 0 0 0;
        }

        tr:first-of-type th:nth-of-type(2) {
          border-radius: 0 8px 0 0;
        }
      }
    }

    // ページ下部のロゴ付バナー
    .base-logo {
      width: 100%;
      border: #707070 solid 1px;
      border-radius: 12px;
      padding: 30px;

      figure {
        margin: 0 auto;
        max-width: 600px;
        height: auto;
        text-align: center;

        img {
          vertical-align: middle;
        }

        figcaption {
          margin-bottom: 1em;
        }
      }
    }
  }

  //==========================================
  // workstyle
  //==========================================
  &.workstyle {
    .kv {
      &::after {
        background-repeat: no-repeat;
        background-image: url("../../assets_v2/img/company/workstyle/main_pc.jpg");
        background-size: contain;

        @include media(sp) {
          background-repeat: no-repeat;
          background-image: url("../../assets_v2/img/company/workstyle/main_sp.jpg");
          background-size: contain;
        }
      }
    }

    /*** 人材育成、インクルージョン＆ダイバーシティの推進　等の二行を揃えるやつ ***/
    .card__txt-arrange {
      display: flex;
      flex-direction: column;

      .card--10__ttl {
        flex-grow: 1;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 72px;
      }
    }

  }

  //==========================================
  // hr
  //==========================================
  &.hr {
    .layout--01 {
      margin: 0 auto 40px;
      max-width: 400px;
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 0 16px;

      @include media(sp) {
        max-width: 100%;
      }
    }

    .hr-img {
      max-width: 480px;
      display: block;
      margin: 0 auto 80px;

      @include media(sp) {
        width: 100%;
        margin: 0 auto 40px;
      }
    }
    .scroll-table {
      overflow: auto;

      img {
          @include media(sp) {
            width: 1024px;
          }
      }
    }
  }

  //==========================================
  // diversity
  //==========================================
  &.diversity {
    .diversity-img {
      width: 180px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .flex {
      .diversity-img-list {
        width: 180px;
      }
    }
    .img {
      max-width: 720px;
      margin: 32px auto 0;
      display: block;
    }
  }

  //==========================================
  // business
  //==========================================
  &.business {
    .bland-warp {
      @include flex(s, n);
      align-items: center;
      border: solid 1px #dddddd;
      border-radius: 12px;
      overflow: hidden;

      .img-wrap {
        width: calc(100% - 448px);
      }

      .btn-wrap {
        width: 352px;
        margin: 0 auto;
      }

      @include media(sp) {
        @include flex(s, w);

        .img-wrap {
          width: 100%;
        }

        .btn-wrap {
          width: 100%;
          padding: 30px;

          .btn {
            margin: 0 auto;
          }
        }
      }
    }

    .flex__column-3 {
      @include media(sp) {
        padding-left: 40px;
        position: relative;

        &::before {
          content: "";
          background-color: #005baa;
          width: 1px;
          height: calc(76% + 40px);
          position: absolute;
          left: 15px;
          top: -40px;
        }
      }

      .layout--01 {
        @include media(sp) {
          .img {
            position: relative;

            &::before {
              content: "";
              background-color: #005baa;
              width: 20px;
              height: 1px;
              position: absolute;
              left: -48px;
              top: calc(50% - 1px / 2);
            }

            &.last {
              &::after {
                content: "";
                background-color: #005baa;
                width: 1px;
                height: calc(79.7% + 40px);
                position: absolute;
                left: -49px;
                bottom: calc(50% - 1px / 2);
              }
            }

            .arrow {
              @include media(sp) {
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 10px solid #005baa;
                border-right: 0;
                position: absolute;
                top: calc(50% - 10px / 2);
                left: -33px;
              }
            }
          }
        }
      }
    }
  }

  //==========================================
  // history
  //==========================================

  //共通CSS
  //==========================================
  .history-sec {
    &__list {
      max-width: 928px;
      margin: 0 auto 220px;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 80px;
      flex-wrap: wrap;
      align-items: flex-start;

      @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 40px 0;
        padding-left: 24px;
        margin: 0 auto 80px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        background-color: #005baa;
        height: calc(100% + 70px);
        top: 44px;
        left: 50%;

        @media only screen and (max-width: 767px) {
          left: 8px;
          height: 98%;
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -150px;
        background: url("../../assets_v2/img/company/brandhistory/obj.svg") no-repeat center;
        left: 50%;
        width: 1px;
        height: 36px;

        @media only screen and (max-width: 767px) {
          left: 8px;
          bottom: -30px;
        }
      }
    }

    &__item {
      position: relative;

      @media only screen and (max-width: 767px) {
        position: static;
        grid-column: 1 / span 2;
      }

      .layout--03 {
        background-color: #fff;
      }

      h2,
      h3 {
        position: relative;
        color: #005baa;

        &::before {
          content: "";
          border-radius: 50%;
          position: absolute;
          top: 16px;
          width: 10px;
          height: 10px;
          background-color: #005baa;
          border: 2px solid #fff;
          z-index: 2;

          @include media(sp) {
            top: 14px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 15px;
          height: 1px;
          width: 56px;
          background-color: #005baa;
          border: 2px solid #fff;

          @include media(sp) {
            width: 38px;
            top: 13px;
          }
        }
      }

      &:nth-child(odd) {

        h2,
        h3 {
          &::before {
            right: -80px;

            @media only screen and (max-width: 767px) {
              left: -55px;
              right: auto;
            }
          }

          &::after {
            right: -68px;

            @media only screen and (max-width: 767px) {
              left: -50px;
              right: auto;
            }
          }
        }
      }

      &:nth-child(even) {

        h2,
        h3 {
          &::before {
            left: -79px;

            @media only screen and (max-width: 767px) {
              left: -55px;
            }
          }

          &::after {
            left: -66px;

            @media only screen and (max-width: 767px) {
              left: -50px;
            }
          }
        }
      }

      &--full {
        grid-column: 1 / span 2;
        margin-top: 40px !important;

        @media only screen and (max-width: 767px) {
          margin-top: 0 !important;
          left: -23px;
          position: relative;
          width: calc(100% + 23px);
        }

        h2,
        h3 {

          &::before,
          &::after {
            content: none;
          }
        }

        .history-sec__img {
          max-width: 100%;
        }
      }
    }

    &__img {
      max-width: 100%;
      margin: 0 auto;

      &:has(+ .txt-link) {
        margin-bottom: 16px;
      }
    }
  }

  .background-sec {
    &__flex {
      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .img {
        float: right;
        margin: 60px 0 24px 24px;
        max-width: 460px;

        @include media(sp) {
          float: none;
          margin: 0 auto 32px;
        }
      }
    }

    .voice-list {
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 0 40px;

        @include media(sp) {
          gap: 0 35px;
        }

        img {
          width: 150px;

          @include media(sp) {
            width: 70px;
          }
        }

        p {
          width: calc(100% - 150px - 40px);
          position: relative;

          @include media(sp) {
            width: calc(100% - 70px - 35px);
            position: relative;
          }

          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent #eaf6fc transparent transparent;
            border-width: 15px 25px 15px 0px;
            left: -24px;
            top: 35px;

            @include media(sp) {
              top: 25px;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 32px;
        }

        &:nth-child(even) {
          p {
            &::before {
              border-color: transparent transparent transparent #fdf2ea;
              border-width: 15px 0 15px 25px;
              left: auto;
              right: -25px;
            }
          }
        }
      }
    }
  }

  //製品ヒストリー
  //==========================================
  &.brandhistory {
    .history-sec {
      &__item {
        &:nth-child(2) {
          top: 180px;
        }

        &:nth-child(3) {
          top: 10px;
        }

        &:nth-child(4) {
          top: calc(180px + 32px);
        }

        &:nth-child(5) {
          top: 70px;
        }

        &:nth-child(6) {
          top: calc(212px + 53px);
        }

        &:nth-child(7) {
          top: 126px;
        }
      }
    }
  }

  //各製品ヒストリー
  //==========================================

  &.history {

    .container {
      overflow: unset;
    }

    .card--06__img {
      aspect-ratio: 271 / 103;
    }

    .current-list {
      position: sticky;
      top: 16px;
      z-index: 9;

      li {
        a {
          max-width: 100%;

          &.btn--anchor {
            padding: 0 16px;

            .btn__txt {
              padding: 0;
            }
          }

          &.current {
            background-color: #005baa;
            color: #fff;
          }
        }
      }
    }

    .base-list--01 li::before {
      color: #005baa;
    }

    //沿革
    //======================================
    &.h-top {
      .history-sec {
        &__list {
          &:first-of-type {
            margin-bottom: 40px;

            &::after {
              content: none;
            }

            @media only screen and (max-width: 767px) {
              &:before {
                top: 100px;
              }
            }
          }

          &:last-of-type {
            margin-bottom: 120px;

            @media only screen and (max-width: 767px) {
              margin-bottom: 80px;
            }

            &:before {
              height: calc(100% - 40px);
            }

            &:after {
              bottom: -46px;
            }
          }
        }

        &__item {
          &--full {
            .layout--03 {
              display: grid;
              grid-template-columns: 1fr 120px;
              align-items: center;
              gap: 0 24px;

              @media only screen and (max-width: 767px) {
                grid-template-columns: 1fr 76px;
              }
            }
          }

          &:nth-child(1) {
            @media only screen and (max-width: 860px) {
              margin-top: -30px;
            }
          }

          &:nth-child(2) {
            margin-top: 200px;
          }

          &:nth-child(3) {
            margin-top: -66px;
          }

          &:nth-child(4) {
            margin-top: 130px;
          }

          &:nth-child(5) {
            margin-top: -125px;
          }

          &:nth-child(6) {
            margin-top: 90px;
          }

          &:nth-child(7) {
            margin-top: 35px;
          }

          &:nth-child(8) {
            margin-top: 240px;
          }

          &:nth-child(9) {
            margin-top: -170px;
          }

          &:nth-child(10) {
            margin-top: 35px;
          }

          &:nth-child(11) {
            margin-top: -200px;
          }

          &:nth-child(12) {
            margin-top: 35px;
          }

          &:nth-child(13) {
            margin-top: 35px;
          }

          &:nth-child(14) {
            margin-top: 95px;
          }

          @media only screen and (max-width: 767px) {
            margin-top: 0 !important;
          }
        }
      }
    }

    //ルル
    //==========================================
    &.lulu {
      .history-sec {
        &__list {
          margin: 0 auto;

          &::before {
            height: calc(100% - 84px);
            top: 84px;

            @media only screen and (max-width: 767px) {
              height: calc(100% - 54px);
              top: 54px;
            }
          }

          &::after {
            bottom: -36px;
            height: 36px;
          }
        }

        &__item {
          margin-top: 40px;

          @media only screen and (max-width: 767px) {
            margin-top: 0;
          }

          &:nth-child(even) {
            margin-top: 80px;

            @media only screen and (max-width: 767px) {
              margin-top: 0;
            }
          }

          .layout--03 {
            &:not(:last-of-type) {
              margin-bottom: 40px;
            }
          }
        }
      }

      .history-bnr-sec {
        &__list {
          li {
            width: 40%;

            @media only screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }

      .hover-border {
        height: auto;
      }

      .flex__lulu-column {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;

        @include media(sp) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        li {
          width: 25%;

          @include media(sp) {
            width: 100%;
          }
        }
      }
    }

    //マキロン
    //==========================================
    &.makiron {
      .background-sec__flex {
        .img {
          margin-top: 0;

          &--01 {
            max-width: 280px;
            margin-top: 60px;

            @include media(sp) {
              margin-top: 0;
            }
          }

          &--02 {
            max-width: 100px;
          }

          &--03 {
            max-width: 265px;
          }
        }
      }

      .history-sec__img {
        &--01 {
          max-width: 160px;
        }
      }

      table {

        th,
        td {
          @media only screen and (max-width: 767px) {
            display: block;
            width: 100%;
          }
        }

        span {
          display: inline-block;

          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }

  //==========================================
  // direct
  //==========================================
  &.direct {
    .g-hr{
      margin: 64px 0;
      @include media(sp) {
        margin: 40px 0;
      }
    }
    .sec-direct {
      hr {
        margin: 32px 0;
      }

      dl {
        dt {
          width: 155px;

          @include media(sp) {
            width: 100%;
          }
        }

        dd {
          width: calc(100% - 187px);

          @include media(sp) {
            width: 100%;
          }
        }
      }
    }
    .sec-philosophy{

      &__txt{
        color: #005BAA;
        font-size: 24px;
        padding-left: 40px;
        @include media(sp) {
          font-size: 20px;
        }
      }
    }
  }

  //==========================================
  // direct_branch
  //==========================================
  &.direct_branch {
    .sec-direct_branch {
      hr {
        margin: 32px 0;

        @include media(sp) {
          margin: 36px 0;
        }
      }

      dl {
        dt {
          width: 155px;

          @include media(sp) {
            width: 100%;
          }
        }

        dd {
          width: calc(100% - 187px);

          @include media(sp) {
            width: 100%;
          }
        }
      }
    }
  }

  //==========================================
  // direct_contact
  //==========================================
  &.direct_contact {
    .contact-area {
      text-align: center;

      @include media(sp) {
        padding: 32px 24px;
      }

      .column {
        padding: 0 24px;

        @include media(sp) {
          padding-left: 0;
          padding-right: 0;
        }

        &:first-of-type {
          border-right: 1px solid #707070;

          @include media(sp) {
            border-right: none;
            border-bottom: 1px solid #707070;
            margin-bottom: 32px;
            padding-bottom: 28px;
          }
        }
      }

      .tel {
        &>span {
          position: relative;
          font-size: 24px;

          .icon-tel {
            transform: translateY(-50%);
            background: url('../img/company/assortment/icon_tel_01.svg') no-repeat 0 0 / 100% auto;
            aspect-ratio: 1;
            width: 30px;
            margin: 0 6px -26px 0;
            vertical-align: middle;
          }
        }
      }
    }
  }

  //==========================================
  // branch-data-direct-recruit
  //==========================================
  &.branch-data-direct-recruit {
    .kankyo-child {
      @include media(sp) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
      }
    }

    .cardlist {
      @include media(sp) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 12px;
      }
    }

    .flex__column-3 {
      .base-list {
        li {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  //==========================================
  // message
  //==========================================
  &.message {
    .kv {
      &::after {
        content: none;
        background-image: url("../../assets_v2/img/company/message/kv.jpg");
        background-size: cover;

        @include media(sp) {
          background-image: url("../../assets_v2/img/company/message/kv_sp.jpg");
        }
      }

      &__in {
        display: flex;
        justify-content: space-between;

        @include media(sp) {
          display: block;
        }
      }

      &__img {
        margin-right: calc(50% - 50vw);

        @include media(sp) {
          display: block;
          margin: 0 calc(50% - 50vw);
        }
        img{
          height: 25vw;
          width: getvwPc(700);
          @include media(sp) {
            height: auto;
            width: 100vw;
          }
        }
      }
    }

    .message-sec {
      .author-img {
        width: 140px;
      }
    }
  }

  //==========================================
  // idea
  //==========================================
  &.idea {
    .idea-sec {
      .logo--01 {
        max-width: 340px;
      }

      .logo--02 {
        max-width: 220px;
      }

      .s-txt {
        @include media(sp) {
          display: block;
          font-size: 14px;
        }
      }
    }
  }

  //==========================================
  // gdprnotice
  //==========================================

  &.gdprnotice {

    //（）付のナンバー付与
    .base-list--02>li::before {
      position: static;
      top: 0;
      font-size: 16px;
      font-weight: normal;
      margin-right: 0;
      content: "("counter(number) ") ";
    }

    //（）付のナンバー付与
    .base-list--02>li::before {
      position: static;
      top: 0;
      font-size: 16px;
      font-weight: normal;
      content: "("counter(number) ") ";
    }

    .base-list--02>li {
      padding-left: 30px;
      text-indent: -30px;
    }

    //dlの打消し
    .flex__basic {
      text-indent: 0;
    }
  }

  //==========================================
  // branch-data-direct-recruit
  //==========================================
  &.data-branch-direct-business {
    .sec-brand {
      .section.sec {
        @include media(sp) {}
      }

      .sec-in--bg {
        @include media(sp) {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }

      .flex.flex__column-2.gap-01 {
        @include media(sp) {
          gap: 32px 0;
          flex-flow: column-reverse;
        }
      }

      .brand-txt {
        @include media(sp) {
          order: 2;
        }
      }

      .brand-img {
        @include media(sp) {
          order: 1;
        }
      }
    }

    .logo {
      @include media(sp) {
        margin: 0 auto 32px;
      }

      &.logo-riceforce {
        width: 294px;

        @include media(sp) {
          width: 236px;
        }
      }

      &.logo-brightage {
        width: 221px;

        @include media(sp) {
          width: 183px;
        }
      }

      &.logo-regain {
        width: 160px;

        @include media(sp) {
          width: 129px;
        }
      }

      &.logo-karadawakai {
        width: 271px;

        @include media(sp) {
          width: 193px;
        }
      }
    }
  }

  //==========================================
  // direct_career
  //==========================================
  &.direct_career {
    .occupation-sec {
      .layout--05 {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 4;
        gap: 0;
      }
    }

    .step-sec {
      &__item {
        border-radius: 10px;
        background-color: #fff;
        padding: 16px;
        border: 1px solid #767676;
        text-align: center;
        position: relative;

        @include media(sp) {
          padding: 16px 16px 24px;
        }

        &:not(:last-child) {
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            width: 62px;
            height: 91px;
            top: calc(50% - 91px / 2);
            right: -50px;
            background-repeat: no-repeat;
            background-image: url("../../assets_v2/img/recruit/referral/flow_arrow.svg");
            background-size: contain;
            vertical-align: middle;
            z-index: 2;

            @include media(sp) {
              transform: rotate(90deg);
              width: 39px;
              height: 58px;
              right: calc(50% - 39px / 2);
              bottom: -40px;
              top: auto;
            }
          }
        }

        .step {
          color: #fff;
          background-color: #005baa;
          text-align: center;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          width: max-content;
          margin: 0 auto 8px;
          padding: 0 24px;
          font-size: 14px;

          @include media(sp) {
            font-size: 13px;
            margin: 0 auto;
          }

          span {
            font-size: 18px;
            display: inline-block;
            margin-left: 8px;

            @include media(sp) {
              margin-left: 4px;
              font-size: 26px;
            }
          }
        }

        h3,
        .step-ttl {
          line-height: 1.6;
          color: #2f5ba9;
          text-align: center;
          margin: 16px 0;
          font-weight: bold;

          @include media(sp) {
            margin: 12px 0;
          }

          &+p {
            color: #2f5ba9;
          }
        }
      }
    }
  }

  //==========================================
  // direct_flesh
  //==========================================

  &.direct_flesh {
    .mynavi-bnr {
      max-width: 260px;
      display: block;
    }

    .salary {
      dl {
        display: flex;
        flex-wrap: wrap;

        @include media(sp) {
          display: block;
        }

        dt {
          width: 160px;

          @include media(sp) {
            width: 100%;
          }
        }
      }
    }
  }

  //==========================================
  // branch
  //==========================================
  &.branch {
    .table {
      .flex {
        width: 100%;
        justify-content: space-between;

        &__img {
          width: 50%;

          @include media(sp) {
            width: 100%;
          }
        }
      }
    }

    .google-map {
      width: 100%;
      height: 500px;

      @include media(sp) {
        height: 300px;
      }
    }

    .pc-i {
      display: inline-block;

      @include media(sp) {
        display: none;
      }
    }

  }

  //==========================================
  // transparency
  //==========================================
  &.transparency {
    dl.flex {
      flex-wrap: nowrap;

      @include media(sp) {
        justify-content: space-between;
      }

      &>dt {
        width: 260px;

        @include media(sp) {
          width: 45%;
        }
      }

      &>dd {
        @include media(sp) {
          width: 45%;
        }
      }
    }
  }

  //==========================================
  // kijyun
  //==========================================

  //1.1等の章節項付リスト＋長文のインデント調整
  &.kijyun {
    .base-list:not(.base-list--01) {
      text-indent: -32px;
      padding-left: 32px;
    }
  }

  ol>li:nth-of-type(3) li:nth-of-type(n + 1)>.base-list:not(.base-list--01) {
    text-indent: -32px;
    padding-left: 32px;
  }

  // 第3章第10条以降に対応
  ol>li:nth-of-type(3) li:nth-of-type(n + 7)>.base-list:not(.base-list--01) {
    text-indent: -40px;
    padding-left: 40px;
  }

  // 第4章以降に対応
  ol>li:nth-of-type(n + 3) li>.base-list:not(.base-list--01) {
    text-indent: -40px;
    padding-left: 40px;
  }

  //==========================================
  // climate
  //==========================================
  &.climate {
    .flex__column-img{
      .img{
        margin-top: 0;
      }
    }
    .img-list {
      max-width: 520px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin: 32px auto 0;
      @include media(sp) {
        max-width: 100%;
        gap: 24px;
      }
      img{
        &:last-of-type{
          width: 232px;
          @include media(sp) {
            width: 93%;
          }
        }
      }
    }
  }

  //==========================================
  // transparency_agree
  //==========================================
  &.transparency_agree {
    .no-link {
      pointer-events: none;
      opacity: .5;
    }
  }


  //==========================================
  // lifestyle
  //==========================================
  &.lifestyle {

    // 汎用CSS
    //==========================================
    .tab__item {
      @include media(sp) {
        display: block;
        height: auto;
        padding: 8px;

        span {
          width: 100%;
        }

        img {
          margin: 0 auto 8px;
          display: block;
        }
      }
    }

    .mb-wide {
      margin-bottom: 120px;

      @include media(sp) {
        margin-bottom: 60px;
      }
    }

    .sec-ttl-area {
      text-align: center;
      margin-bottom: 64px;

      @include media(sp) {
        margin-bottom: 32px;
        padding: 0 24px;
      }

      &__img {
        width: 56px;
        position: absolute;
        vertical-align: middle;
        left: -64px;

        @include media(sp) {
          width: 40px;
          left: -8px;
        }
      }

      h2 {
        font-size: 40px;
        position: relative;
        width: max-content;
        margin: 0 auto;

        @include media(sp) {
          font-size: 24px;
          text-align: left;
          padding-left: 40px;
        }

        &+p {
          font-size: 18px;

          @include media(sp) {
            font-size: 16px;
            text-align: left;
          }
        }
      }
    }

    .flex-full-img {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100vw;
      margin: 0 calc(50% - 50vw) 80px;

      @include media(sp) {
        margin: 0 calc(50% - 50vw) 60px;
      }
    }

    .anchor-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0 86px;
      margin-bottom: 100px;

      @include media(sp) {
        gap: 0 24px;
        margin-bottom: 48px;

        &--2-column {
          .anchor-list__item {
            width: 40%;
          }
        }
      }

      &__item {
        position: relative;

        @include media(sp) {
          width: auto;
          text-align: center;
          line-height: 1.2;

          &:first-child {
            &::before {
              @include media(sp) {
                content: none;
              }
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          height: 40px;
          top: calc(50% - 40px / 2);
          width: 1px;
          left: -40px;
          background-color: #767676;

          @include media(sp) {
            height: 100%;
            top: 0;
            left: -12px;
          }
        }

        &:last-child {
          &::after {
            content: "";
            position: absolute;
            height: 40px;
            top: calc(50% - 40px / 2);
            width: 1px;
            background-color: #767676;
            right: -40px;

            @include media(sp) {
              content: none;
            }
          }
        }


      }

      a {
        font-size: 18px;
        position: relative;
        display: block;

        @include media(sp) {
          font-size: 14px;
        }

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 8px;
          height: 8px;
          bottom: -24px;
          right: calc(50% - 8px / 2);
          border-top: 2px solid #005BAA;
          border-right: 2px solid #005BAA;
          transform: rotate(135deg);

          @include media(sp) {
            bottom: -16px;
          }
        }
      }

      &__img {
        width: 40px;
        vertical-align: middle;
        margin: -6px 12px 0 0;

        @include media(sp) {
          display: block;
          width: 32px;
          margin: 0 auto 8px;
        }
      }
    }

    .lifestyle-kv {
      aspect-ratio: 1280 / 566;
      position: relative;
      margin-bottom: calc(getvwPc(60) + getvwPc(30));

      @include media(sp) {
        aspect-ratio: auto;
      }

      &::after {
        content: "";
        background-image: url("../../assets_v2/img/company/data/lifestyle/kv.jpg");
        background-repeat: no-repeat;
        aspect-ratio: 703 / 565;
        position: absolute;
        height: 100%;
        right: 0;
        background-size: cover;
        display: inline-block;
        bottom: 0;

        @include media(sp) {
          position: static;
          aspect-ratio: 75 / 76;
          background-image: url("../../assets_v2/img/company/data/lifestyle/kv_sp.jpg");
          background-size: cover;
          width: 100%;
        }
      }

      .sec-in {
        position: relative;
        height: 100%;
      }

      &__ttl {
        position: absolute;
        top: 45%;
        left: 52%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include media(sp) {
          position: static;
          transform: none;
          padding: 40px 0;
        }

        h1 {
          font-size: 48px;

          @include media(sp) {
            font-size: 30px;
          }

          span {
            padding-bottom: 4%;
            display: inline;
            background: linear-gradient(90deg, rgb(0, 91, 170) 0%, rgb(0, 180, 237) 30%, rgb(137, 186, 23) 70%, rgb(255, 233, 0) 100%);
            background-repeat: no-repeat;
            background-size: 100% 4%;
            background-position: bottom left;
            background-position: 0px 52px;

            @include media(sp) {
              background-position: 0px 35px;
            }
          }

          &+p {
            line-height: 2;
            margin-top: 54px;

            @include media(sp) {
              margin-top: 24px;
            }
          }
        }
      }
    }

    .tab-sec {
      position: relative;

      &::before {
        content: "";
        background-image: url("../../assets_v2/img/company/data/lifestyle/kv_bottom.png");
        background-repeat: no-repeat;
        aspect-ratio: 1280 / 219;
        position: absolute;
        width: 100%;
        background-size: cover;
        display: inline-block;
        top: getvwPc(-146);

        @include media(sp) {
          background-image: url("../../assets_v2/img/company/data/lifestyle/kv_bottom_sp.png");
          aspect-ratio: 375 / 179;
          background-size: cover;
          top: getvw(-168);
        }
      }
    }

    .tab {
      position: relative;
      z-index: 2;

      &__img {
        width: 40px;
        margin: 0 16px 0 0;
      }
    }

    .tab-contents {
      position: relative;

      & [role=tabpanel] {
        padding-top: 94px;

        @include media(sp) {
          padding-top: 24px;
        }
      }
    }

    .lifestyle-contents {
      &__box {
        background-color: #ddd;
        padding: 60px 0 180px;

        @include media(sp) {
          padding: 40px 0 80px;

          .sec-in {
            padding: 0 32px;
          }
        }

        &:not(:last-of-type) {
          margin-bottom: 120px;

          @include media(sp) {
            margin-bottom: 60px;
          }
        }

        &.bg-01,
        &.bg-02 {
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: -1px;
            background-image: url("../../assets_v2/img/company/data/lifestyle/wave_white_01.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            aspect-ratio: 160 / 7;
            z-index: 2;

            @include media(sp) {
              aspect-ratio: 125 / 14;
              background-image: url("../../assets_v2/img/company/data/lifestyle/wave_white_01_sp.svg");
              background-size: cover;
            }
          }
        }

        &.bg-01 {
          position: relative;
          background: url("../../assets_v2/img/company/data/lifestyle/bg_01.jpg") no-repeat center;
          background-size: cover;

          @include media(sp) {
            background: url("../../assets_v2/img/company/data/lifestyle/bg_01_sp.jpg") no-repeat center;
            background-size: cover;
          }

        }

        &.bg-02 {
          position: relative;
          background: url("../../assets_v2/img/company/data/lifestyle/bg_02.jpg") no-repeat center;
          background-size: cover;

          @include media(sp) {
            background: url("../../assets_v2/img/company/data/lifestyle/bg_02_sp.jpg") no-repeat center;
            background-size: cover;

            &::after {
              aspect-ratio: 375 / 13;
              background-image: url("../../assets_v2/img/company/data/lifestyle/wave_white_02_sp.svg");
              background-size: cover;
            }
          }
        }

        &.bg-03 {
          position: relative;
          background: url("../../assets_v2/img/company/data/lifestyle/bg_03.jpg") no-repeat center;
          background-size: cover;

          @include media(sp) {
            background: url("../../assets_v2/img/company/data/lifestyle/bg_03_sp.jpg") no-repeat center;
            background-size: cover;
          }

          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: -1px;
            background-image: url("../../assets_v2/img/company/data/lifestyle/wave_white_02.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            aspect-ratio: 128 / 7.1;
            z-index: 2;

            @include media(sp) {
              aspect-ratio: 750 / 29;
              background-image: url("../../assets_v2/img/company/data/lifestyle/wave_white_03_sp.svg");
              background-size: cover;
            }
          }
        }
      }

      .sec-sub-ttl {
        text-align: center;
        width: max-content;
        margin: 0 auto 120px;
        font-size: 34px;
        position: relative;

        @include media(sp) {
          font-size: 20px;
          margin: 0 auto 24px;
          text-align: left;
          width: max-content;
          padding-left: 32px;
        }

        &__img {
          width: 48px;
          vertical-align: middle;
          position: absolute;
          left: -58px;
          top: -4px;

          @include media(sp) {
            width: 32px;
            left: -6px;
            top: auto;
          }
        }
      }

      &__kv {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 60px;

        @include media(sp) {
          flex-wrap: wrap;
          margin-bottom: 40px;
        }

        &--first {
          margin-top: -140px;

          @include media(sp) {
            margin-top: 0;
          }
        }

        h4 {
          font-size: 28px;
          position: relative;
          width: 480px;
          margin-right: 2%;

          @include media(sp) {
            font-size: 19px;
            width: 100%;
            margin-right: 0;
          }

          &::after {
            content: "";
            position: absolute;
            width: 120%;
            display: inline-block;
            background: -webkit-gradient(linear, left top, right top, from(rgb(0, 91, 170)), color-stop(30%, rgb(0, 180, 237)), color-stop(70%, rgb(137, 186, 23)), to(rgb(255, 233, 0)));
            background: linear-gradient(90deg, rgb(0, 91, 170) 0%, rgb(0, 180, 237) 30%, rgb(137, 186, 23) 70%, rgb(255, 233, 0) 100%);
            height: 2px;
            bottom: -5px;
            left: 0;
          }

          span {
            color: #005BAA;
          }

          sup {
            line-height: 1;
          }

          .notes {
            line-height: 1.6;
            color: #333;
            display: inline-block;

            &::before {
              top: 0;
            }
          }
        }

        img {
          border-radius: 6px 0 0 6px;
          object-fit: cover;
          max-height: 321px;
          right: 0;
          width: getvwPc(740);
          flex: 1;
          margin-right: calc(50% - 50vw);
          position: relative;
          z-index: 2;

          @include media(sp) {
            width: 100%;
            margin-top: 32px;
          }
        }
      }

      &__products {
        h5 {
          color: #005BAA;
          text-align: center;
          font-size: 28px;
          margin-bottom: 50px;

          @include media(sp) {
            font-size: 19px;
            text-align: left;
            margin-bottom: 32px;
          }

          span {
            position: relative;

            &::before,
            &::after {
              content: '';
              display: inline-block;
              width: 19px;
              height: 19px;
              background-image: url("../../assets_v2/img/company/data/lifestyle/icon_quotation.svg");
              background-size: contain;
              vertical-align: top;
            }

            &::after {
              transform: scale(-1, 1);
            }
          }
        }

        .products-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: getvwPc(80) 9.2%;

          @include media(sp) {
            gap: 60px 0;
          }

          &__item {
            width: 27.2%;
            display: flex;
            flex-direction: column;

            @include media(sp) {
              width: 100%;
            }

            .item-ttl {
              flex-grow: 1;

              h6 {
                font-size: 20px;

                @include media(sp) {
                  font-size: 18px;
                }
              }
            }

            .item-btn {
              &:has(.one-btn) {
                margin-bottom: 72px;

                @include media(sp) {
                  margin-bottom: 0;
                }
              }
            }
          }

          &__img {
            border-radius: 50%;
            width: 256px;
            height: 256px;
            margin: 0 auto 32px;
            display: block;
          }
        }
      }
    }

  }


  //==========================================
  // TOP
  //==========================================
  &.dx {

    figure{
      figcaption{
        margin-top: 8px;
        text-align: right;
      }
    }

    .kv{
      &::after {
        content: none;
      }
      &__in {
        display: flex;
        justify-content: space-between;

        @include media(sp) {
          display: block;
        }
      }

      &__img {
        margin-right: calc(50% - 50vw);

        @include media(sp) {
          display: block;
          margin: 0 calc(50% - 50vw);
        }
        img{
          height: 25vw;
          width: getvwPc(700);
          @include media(sp) {
            height: auto;
            width: 100vw;
          }
        }
      }
    }

    .lh-txt{
      @include media(sp) {
        line-height: 1.6;
      }
    }

    .menu-sec {
      &.flex__column-3 {
        @include media(sp) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    .flex__column-2 {
      @include media(sp) {
        gap: 0;

        .info {
          display: contents;

          h3 {
            order: 1;
          }

          p,
          ul {
            order: 3;
          }
        }

        .img {
          order: 2;
        }
      }

    }

    .btn--anchor {
      max-width: 100%;
    }

    .card-list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: center;

      @include media(sp) {
        flex-wrap: nowrap;
        gap: 0;
        justify-content: flex-start;
      }

      .card--06 {
        width: 31.4%;

        @include media(sp) {
          height: auto;
        }

        &__info {
          min-height: 190px;

          @include media(sp) {
            min-height: auto;
          }
        }
      }
    }

    .swiper-area {
      .swiper {
        padding: 0;
        @include media(sp) {
          padding: 4px 25px;
        }
      }

      .swiper-control {
        display: none;

        @include media(sp) {
          display: flex;
        }
      }
    }


    .layout--dx {
      padding: 40px 80px;

      @include media(sp) {
        padding: 20px;
      }

      &--02 {
        max-width: 656px;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;
      }
    }

    .figure {
      margin-left: auto;
      margin-right: auto;
      display: block;

      &--01 {
        max-width: 575px;
      }

      &--02 {
        max-width: 593px;
      }
    }

  }
}



//==========================================
// 旧サイトのスタイル
//==========================================
.aly_tx_left {
  text-align: left !important;
}

.aly_tx_center {
  text-align: center !important;
}

.aly_tx_right {
  text-align: right !important;
}

.aly_tx_justify {
  text-align: justify !important;
}

.aly_tx_xxs {
  font-size: 0.55em !important;
}

.aly_tx_xs {
  font-size: 0.7em !important;
}

.aly_tx_s {
  font-size: 0.8em !important;
}

.aly_tx_m {
  font-size: 1em !important;
}

.aly_tx_l {
  font-size: 1.2em !important;
}

.aly_tx_xl {
  font-size: 1.44em !important;
}

.aly_tx_xxl {
  font-size: 2em !important;
}

.aly_tx_f_auto {
  color: rgb(0, 0, 0) !important;
}

.aly_tx_f_black {
  color: rgb(0, 0, 0) !important;
}

.aly_tx_f_maroon {
  color: rgb(128, 0, 0) !important;
}

.aly_tx_f_saddlebrown {
  color: rgb(139, 69, 19) !important;
}

.aly_tx_f_darkslategray {
  color: rgb(47, 79, 79) !important;
}

.aly_tx_f_teal {
  color: rgb(0, 128, 128) !important;
}

.aly_tx_f_navy {
  color: rgb(0, 0, 128) !important;
}

.aly_tx_f_indigo {
  color: rgb(75, 0, 130) !important;
}

.aly_tx_f_dimgray {
  color: rgb(105, 105, 105) !important;
}

.aly_tx_f_firebrick {
  color: rgb(178, 34, 34) !important;
}

.aly_tx_f_brown {
  color: rgb(165, 42, 42) !important;
}

.aly_tx_f_goldenrod {
  color: rgb(218, 165, 32) !important;
}

.aly_tx_f_darkgreen {
  color: rgb(0, 100, 0) !important;
}

.aly_tx_f_turquoise {
  color: rgb(64, 224, 208) !important;
}

.aly_tx_f_mediumblue {
  color: rgb(0, 0, 205) !important;
}

.aly_tx_f_purple {
  color: rgb(128, 0, 128) !important;
}

.aly_tx_f_gray {
  color: rgb(128, 128, 128) !important;
}

.aly_tx_f_red {
  color: rgb(204, 0, 0) !important;
}

.aly_tx_f_darkorange {
  color: rgb(255, 140, 0) !important;
}

.aly_tx_f_gold {
  color: rgb(255, 215, 0) !important;
}

.aly_tx_f_green {
  color: rgb(0, 128, 0) !important;
}

.aly_tx_f_cyan {
  color: rgb(0, 255, 255) !important;
}

.aly_tx_f_blue {
  color: rgb(0, 0, 255) !important;
}

.aly_tx_f_violet {
  color: rgb(238, 130, 238) !important;
}

.aly_tx_f_darkgray {
  color: rgb(169, 169, 169) !important;
}

.aly_tx_f_lightsalmon {
  color: rgb(255, 160, 122) !important;
}

.aly_tx_f_orange {
  color: rgb(255, 165, 0) !important;
}

.aly_tx_f_yellow {
  color: rgb(255, 255, 0) !important;
}

.aly_tx_f_lime {
  color: rgb(0, 255, 0) !important;
}

.aly_tx_f_paleturquoise {
  color: rgb(175, 238, 238) !important;
}

.aly_tx_f_lightblue {
  color: rgb(173, 216, 230) !important;
}

.aly_tx_f_plum {
  color: rgb(221, 160, 221) !important;
}

.aly_tx_f_lightgrey {
  color: rgb(211, 211, 211) !important;
}

.aly_tx_f_lavenderblush {
  color: rgb(255, 240, 245) !important;
}

.aly_tx_f_antiquewhite {
  color: rgb(250, 235, 215) !important;
}

.aly_tx_f_lightyellow {
  color: rgb(255, 255, 224) !important;
}

.aly_tx_f_honeydew {
  color: rgb(240, 255, 240) !important;
}

.aly_tx_f_azure {
  color: rgb(240, 255, 255) !important;
}

.aly_tx_f_aliceblue {
  color: rgb(240, 248, 255) !important;
}

.aly_tx_f_lavender {
  color: rgb(230, 230, 250) !important;
}

.aly_tx_f_white {
  color: rgb(255, 255, 255) !important;
}

.aly-form_error {
  color: #d0121b;
  font-weight: 400;
  margin: 10px 0;
}

@media only screen and (max-width: 750px) {
  #tpl-contents .tpl-form--btn-wrap form:not(#validation-form2) {
    width: 100%;
  }

  #tpl-contents .tpl-form--btn-wrap form:not(#validation-form2) .tpl-form--btn,
  #tpl-contents .tpl-form--btn-wrap input.tpl-btn--back {
    margin: auto;
  }
}
