@use "./assets_v2/scss/mixin"as *;

#SUSTAINABILITY {


  //==========================================
  // 共通CSS
  //==========================================

  .icon_sdgs {
    gap: 3px;
  }

  .icon--sdgs {
    width: 64px;
  }

  .kv {
    h1 {
      display: flex;
      align-items: center;

      @include media(sp) {
        align-items: flex-start;
      }

      .icon-img {
        width: 48px;
        margin-right: 16px;
        @include media(sp) {
          width: 32px;
          margin-top: 8px;
          margin-right: 12px;
        }
      }
    }
  }

  .card--sdgs {
    .card__link {
      display: flex;
      flex-direction: column;

      .card--06__info{
        flex-grow: 1;
      }

      .sdgs-link {
        padding: 0 24px 24px;
      }
      .pick-up-link{
        @include media(sp) {
          position: absolute;
          bottom: 16px;
          right: 16px;
          padding: 0;
        }
      }

      &:hover {
        .txt-link {
          text-decoration: none;
        }
      }
    }
  }

  .theme-list {
    @include media(sp) {
      grid-template-columns: 1fr 1fr;
      li {
        width: 100%;
        max-width: 100%;
      }

      .btn {
        width: 100%;
        max-width: 100%;
      }
    }
    @media only screen and (max-width: 767px){
      grid-template-columns: 1fr;
    }
  }

  //==========================================
  // sustainability
  //==========================================
  &.sustainability {
    .kv {
      &::after {
        background-image: url("../../assets_v2/img/sustainability/kv.jpg");
        background-size: contain;

        @include media(sp) {
          background-image: url("../../assets_v2/img/sustainability/kv_sp.jpg");
          background-size: contain;
        }
      }
    }

    .slogan {
      .img {
        max-width: 280px;
        margin: auto;

        @include media(sp) {
          max-width: 260px;
        }
      }
    }

    .border-dotted {
      border: none;
      border-bottom: 3px dotted #005baa;
      max-width: 640px;
      margin: 24px auto;
    }

    .border-gray {
      border: none;
      width: 100%;
      height: 24px;
      background: #F4F4F4;
    }

    .bg-sankaku {
      position: relative;
      max-width: 800px;
      margin: auto;

      @include media(sp) {
        padding: 20px 16px;
      }

      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 345px solid transparent;
        border-left: 345px solid transparent;
        border-bottom: 379px solid #eaf5fc;
        border-top: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        z-index: -1;

        @include media(sp) {
          border-right: 139px solid transparent;
          border-left: 139px solid transparent;
          border-bottom: 583px solid #eaf5fc;
        }
      }
    }

    .housin {
      max-width: 560px;
      margin: auto;

      .housin-head {
        background: #eaf5fc;
        border-bottom: 1px solid #707070;
        border-radius: 8px 8px 0 0;
        padding: 11px 0;
      }

      .housin-sustainability {
        display: flex;
        align-items: center;
        justify-content: center;

        &::before,
        &::after {
          border-top: 2px solid #005baa;
          content: "";
          width: 100px;

          @include media(sp) {
            width: 34px;
          }
        }

        &::before {
          margin-right: 20px;

          @include media(sp) {
            margin-right: 10px;
          }
        }

        &::after {
          margin-left: 20px;

          @include media(sp) {
            margin-left: 10px;
          }
        }
      }

      .housin-sustainability-icons{
        width: 74%;
        margin: 0 auto 24px;
        @include media(sp) {
          width: 78%;
        }
      }

      .sdgs-imgs {
        li {
          max-width: 56px;

          @include media(sp) {
            max-width: 40px;
          }
        }
      }
    }

    .arrow {
      max-width: 69px;

      @include media(sp) {
        max-width: 58px;
      }
    }

    .sec-message {
      .message {
        display: flex;
        justify-content: space-between;

        @include media(sp) {
          flex-wrap: wrap;
        }

        .message__txt {
          width: calc(100% - 256px);
          padding-right: 32px;

          @include media(sp) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 40px;
          }
        }

        .message__imgs {
          width: 256px;

          @include media(sp) {
            width: 100%;
            max-width: 280px;
            margin: auto;
          }

          .name {
            width: 170px;
            margin: auto;

            @include media(sp) {
              width: 223px;
            }
          }
        }
      }
    }

    .theme-list {
      @include media(sp) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      li {
        @include media(sp) {
          width: 104px;
        }

        a {
          @include media(sp) {
            flex-direction: column;
            padding: 12px;
          }

          .btn__txt {
            @include media(sp) {
              text-align: center;
              padding: 12px 0;
            }
          }

          .icon-img {
            @include media(sp) {
              margin: 0;
            }
          }

          .icon-arrow {
            @include media(sp) {
              position: relative;
              top: auto;
              right: auto;
              left: auto;
              text-align: center;
              margin-top: auto;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .img-sdgs {
      max-width: 656px;
      margin: auto;
    }

    .flex {
      &.aligncenter {
        align-items: center;
      }
    }

    .column-2-sec {
      .layout--06 {
        display: flex;
        flex-direction: column;

        .ttl-area {
          flex-grow: 1;
        }
      }
    }
  }

  //==========================================
  // organization
  //==========================================
  &.organization {

    // 画像
    .switch-img {
      width: 100%;
      margin: 0 auto;

      img {
        max-width: 590px;
        display: block;
        margin: 41px auto 80px;
      }
    }

    .flex-box {
      display: grid;
      grid-template-columns: 1fr 340px;
      gap: 32px;

      @include media(sp) {
        grid-template-columns: 1fr;
      }
    }

    .u-line {
      text-decoration: underline;
    }
  }

  //==========================================
  // environment
  //==========================================
  &.environment {
    .theme-list {
      .btn {
        justify-content: flex-start;
      }
    }
  }
}
