@use "./assets_v2/scss/mixin" as *;

#SITEMAP {
  .column-box{
    &:not(:last-of-type){
      margin-bottom: 80px;
      @include media(sp) {
        margin-bottom: 0;
      }
    }
  }
   .column-1{
    display:block;
  }
  .column-1-1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include media(sp) {
      grid-template-columns: 1fr;
      ul{
        &:not(:last-of-type){
          margin-bottom: 12px;
        }
      }
    }
  }
 

  .column-1-1-1{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include media(sp) {
      grid-template-columns: 1fr;
    }
  }
  .link-list{
    li{
      &:not(:last-child){
        margin-bottom: 12px;
      }
    }
  }

  .gap-32{
    gap: 0 32px;
  }

  .gap-60{
    gap: 0 60px;
  }
}
