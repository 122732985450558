@use "./assets_v2/scss/mixin" as *;

#SEARCH {
	.result-sec{
		&__list{
			li{
				display: grid;
				grid-template-columns: 240px 1fr;
				gap: 32px;
				padding-bottom: 32px;
				border-bottom: 1px solid #ddd;
				@media only screen and (max-width: 767px) {
					grid-template-columns: 1fr;
					gap: 16px;
				}

				&:not(:last-child){
					margin-bottom: 32px;
				}
				.img{
					display: contents;
					img{
						height: 100%;
					}
				}
				.url-txt{
					color: #005baa;
				}
			}
		}
	}
}
