@use "./assets_v2/scss/mixin"as *;

#NEW_COMPONENT {
  //==========================================
  // 共通CSS
  //==========================================

  table {
    &.center-table {

      th,
      td {
        text-align: center !important;
      }
    }
  }

  &.template {
    .article-sec {
      img {
        width: auto;
        max-width: 100%;
      }

      strong {
        font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
        font-weight: bold;
        display: inline-block;
      }

      a {
        text-decoration: underline;
        color: #005baa;

        &:hover,
        &:focus-visible {
          text-decoration: none;
        }
      }

      table {
        width: 100% !important;

        tr {
          vertical-align: top;

          th,
          td {
            border: 1px solid #000;
            padding: 16px;
            vertical-align: top;

            @include media(sp) {
              padding: 8px;
            }
          }

          th {
            background-color: #f2f2f2;
          }
        }
      }

      //テキストリンクの影響打ち消し
      .btn,
      .btn--reversal {
        text-decoration: none;

        .icon-img {
          width: 40px;
          margin-right: 8px;
        }

        &.btn--icon-img-02 .icon-img {
          width: 24px;
        }

        .icon-arrow {
          display: flex;
          position: absolute;
          width: 8px;
          top: calc(50% - 5.5px);
          right: 15px;
        }

        .icon-blank {
          display: flex;
          position: absolute;
          width: 13px;
          top: calc(50% - 6.5px);
          right: 15px;
        }
      }

      .btn {
        color: #fff;

        &:hover,
        &:focus-visible {
          color: #005baa;
        }
      }

      .btn--reversal {
        color: #005baa;

        &:hover,
        &:focus-visible {
          color: #fff;
        }
      }
    }
  }

  //==========================================
  // ニュースページテンプレート
  //==========================================
  &.news {
    .kv {
      @include media(pc) {
        padding-top: getvwPc(64);
        padding-bottom: getvwPc(120);
      }
    }

    .flex--img {
      display: grid;
      grid-template-columns: 448px 1fr;

      @include media(sp) {
        display: block;
        grid-template-columns: 50% 45%;
      }

      img {
        @include media(sp) {
          float: left;
          width: 50%;
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .accordion__contents {
      display: none;

      button {
        p {
          text-align: left;
          font-size: 16px;
        }
      }
    }

    .flex__column-4 {
      @include media(sp) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 8px;
      }

      a {
        width: 100%;

        .card {
          display: block;
        }

        .card-txt {
          font-size: 16px;
          text-align: left;
          display: block;
        }

        img {
          border-radius: 8px;
          height: getvwPc(254);
          padding: getvwPc(24);
          object-fit: contain;

          @include media(pc-l) {
            height: 254px;
            padding: 24px;
          }

          @include media(sp) {
            height: getvw(450);
            padding: getvw(48);
          }
        }
      }
    }

    .flex__column-1-sp {
      @include media(sp) {
        grid-template-columns: 1fr;
      }
    }

    .base-list--05 {
      .list-item {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 1px solid #ddd;

        &>a {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          padding-bottom: 24px;

          &:hover, &:focus {
            .txt-link {
              text-decoration: none;
            }

            .tag {
              &--03 {
                background-color: #005baa;
                color: #fff;
              }

              &--01 {
                background-color: #ccf2ff;
              }
            }
          }
        }

        &:first-of-type {
          a {
            padding-top: 0;
          }
        }
        &__info{
          @include media(sp) {
            width: 60%;
          }
        }
        &__left{
          width: 100%;
          align-items: flex-start;
          margin-bottom: 16px;

          @include media(sp) {
            .info-head-sp{
              order: 1;
            }
            .list-item__img{
              order: 2;
            }
            .list-item__info{
              order: 3;
            }
          }
        }
      }
    }


  }

  //==========================================
  // 添付文書テンプレート
  //==========================================
  &.attached {
    h2 {
      margin-bottom: 32px;
    }
  }

  //==========================================
  // 汎用テンプレート
  //==========================================
  &.general {}
}
