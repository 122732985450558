@use "./assets_v2/scss/mixin" as *;

#RD {

  //==========================================
  // TOP
  //==========================================
  &.top {
    .kv {
      &::after {
        background-image: url("../../assets_v2/img/rd/top/kv.jpg");

        @include media(sp) {
          background-image: url("../../assets_v2/img/rd/top/kv_sp.jpg");
        }
      }
    }

    .vision-sec {

      h2,
      h3 {
        line-height: 1.5;
      }
    }

    .presentation-sec {
      &__list {
        li {
          &:not(:last-child) {
            border-bottom: 1px solid #ccc;
            padding-bottom: 40px;
          }
        }
      }
    }

    .story-sec-01 {
      .story-list {
        @include media(sp) {
          gap: 32px;
        }

        li,
        .card--07 {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    }

    .story-sec-02 {
      .story-list {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: 767px) {
          display: grid;
          grid-template-columns: 1fr;
        }

        &>li {
          @include media(sp) {
            &:not(:first-of-type) {
              display: block;
            }
          }
        }
      }

      .card--07 {
        width: 352px;

        @include media(sp) {
          width: 100%;
        }

        &__info {
          .logo--flex {
            align-items: center;
          }

          .logo {
            aspect-ratio: 11 / 4;
            max-width: 88px;

            &--02 {
              aspect-ratio: 135 / 22;
              max-width: 135px;
            }
          }
        }
      }
    }

    .external-media-sec {
      .card--07 {
        .logo {
          height: 18px;
        }
      }
    }
  }

  //==========================================
  // Interview
  //==========================================
  &.interview {
    .kv {
      &::after {
        background-image: url("../../assets_v2/img/rd/interview/top/kv.jpg");

        @include media(sp) {
          background-image: url("../../assets_v2/img/rd/interview/top/kv_sp.jpg");
        }
      }
    }

    .interview-list-sec {
      .interview-list {
        .card {
          button {
            font-size: 16px;
          }
        }

        .category {
          @include media(pc) {
            font-size: 14px;
          }
        }
      }

      .notes {
        font-size: 16px;
      }
    }
  }

  //==========================================
  // interview-page
  //==========================================
  &.interview-page {
    .interview-kv {
      position: relative;

      figcaption {
        position: absolute;
        left: 50px;
        bottom: 40px;
        font-size: 28px;
        color: #fff;
        line-height: 1.8;
        text-shadow: 0px 3px 18px rgba(0, 0, 0, 0.56);

        @include media(sp) {
          margin-top: 20px;
          position: relative;
          left: auto;
          bottom: auto;
          font-size: 19px;
          color: #333;
          text-shadow: none;
        }
      }
    }

    .interview__product {
      &.aligncenter {
        align-items: center;

        @include media(sp) {
          align-items: flex-start;
        }
      }

      .interview__product-img {
        width: 25%;
        margin-right: 5%;

        @include media(sp) {
          width: 28.667vw;
          margin-right: 3.333vw;
        }
      }

      .interview__product-txt {
        width: calc(70% - 8px);

        @include media(sp) {
          flex: 1;
          width: calc(100% - (28.667vw + 3.333vw));
        }
      }

      .product-detail {
        @include media(sp) {
          font-size: 11px;
          width: 100%;
        }
      }

      .product-tag {
        span {
          border: 1px solid black;
          padding: 6px;
          font-size: 14px;

          @include media(sp) {
            font-size: 11px;
          }
        }
      }
    }

    .sec-interviewBlock {
      .flex {
        @include media(sp) {
          gap: 60px 0;
        }

        .interviewBlock-img {
          @include media(sp) {
            order: 2;
          }
        }

        .interviewBlock-txt {
          @include media(sp) {
            order: 1;
          }
        }
      }

      .flex__reverse {
        .interviewBlock-img {
          order: 2;
        }

        .interviewBlock-txt {
          order: 1;
        }
      }
    }

    .movie__block {
      background-image: url("../img/rd/interview/lulu/lulu04.jpg");
      background-repeat: no-repeat;
      background-position: center right;
      height: 300px;
      display: flex;
      align-items: center;
      padding: 20px 15px 20px 80px;
      border-radius: 8px;

      @include media(sp) {
        background-image: url("../img/rd/interview/lulu/lulu04_sp.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        align-items: flex-start;
        padding: 20px;
        height: 345px;
      }

      .label {
        font-size: 28px;
        line-height: 1.55556;

        @include media(sp) {
          font-size: 18px;
          line-height: 1.46667;
        }
      }

      .tpl-paragraph {
        @include media(sp) {
          font-size: 14px;
        }
      }

      .btn {
        @include media(sp) {
          width: 56vw;
        }
      }
    }

    // ミノン
    &--minon {
      .movie__block {
        background-image: url("../img/rd/interview/minon/minon04.jpg");

        @include media(sp) {
          background-image: url("../img/rd/interview/minon/minon04_sp.jpg");
        }
      }
    }

    // ロキソニンS
    &--loxonin-s {
      .movie__block {
        background-image: url("../img/rd/interview/loxonins/loxonin-s04.jpg");

        @include media(sp) {
          background-image: url("../img/rd/interview/loxonins/loxonin-s04_sp.jpg");
        }
      }
    }
  }



  //==========================================
  // ingredient
  //==========================================
  &.ingredient {
    .kv::after {
      background-image: url(https://placehold.jp/dddddd/dddddd/1440x652.png);
      background-size: contain;
      @include media(sp) {
        background-image: url(https://placehold.jp/dddddd/dddddd/750x760.png);
        background-size: contain;
      }
  }
    .history-sec {
      &__list {
        max-width: 928px;
        margin:0 auto 120px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          background-color: #005baa;
          height: 100%;
          top: 44px;
          left: 50%;
          z-index: -1;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -85px;
          background: url(../../assets_v2/img/company/brandhistory/obj.svg) no-repeat center;
          left: 50%;
          width: 1px;
          height: 36px;
        }
      }

      &__item {
        display: grid;
        grid-template-columns: 1fr 220px;
        align-items: center;
        gap: 24px;
        padding: 32px;
        border: 1px solid #707070;
        border-radius: 8px;
        background: #fff;

        &:not(:last-of-type) {
          margin-bottom: 32px;
        }

        @media only screen and (max-width: 767px) {
          grid-template-columns: 1fr;
          padding: 24px;
        }

        .info {
          .ttl-area {
            display: flex;
            align-items: center;
            gap: 24px;
            margin-bottom: 24px;

            @include media(sp) {
              flex-direction: column;
              gap: 12px;
              align-items: flex-start;
            }

            .date-txt {
              color: #005baa;
              font-size: 24px;
              font-weight: bold;

              @include media(sp) {
                font-size: 20px;
              }
            }

            h2 {
              font-size: 20px;

              @include media(sp) {
                font-size: 18px;
              }
            }
          }
        }
      }

      &__img {
        @include media(sp) {
          width: 60%;
          display: block;
          margin: auto;
        }
      }
    }
  }
  //==========================================
  // tranexamic-acid
  //==========================================
  &.tranexamic-acid{

    .kv{
      &__ttl{
        justify-content: space-between;
      }
      &__img {
        width: getvwPc(304);
        margin-bottom: getvwPc(-50);

        @include media(sp) {
          width: 100%;
          margin: 32px 0 0;
        }
      }
    }
    .flex__column-img .info {
      width: calc(100% - 340px - 32px);
      @include media(sp) {
        width: 100%;
      }
    }
    .flex__column-img .img {
      width: 340px;
      margin-top: -30px;
      @include media(sp) {
        width: 100%;
        margin-top: 0;
      }
    }
    .flex__column-img--02{
      &::after{
        content: "";
        display: block;
        clear: both;
      }
      img{
        float: right;
        width: 300px;
        margin: 0 0 20px 20px;
        @include media(sp) {
          float: none;
          width: 100%;
          margin: 0 0 24px 0;
        }
      }
    }
    hr{
      margin: 0;
    }
  }
}