@use "./assets_v2/scss/mixin" as *;

#SELFCARE {

  //==========================================
  // TOP
  //==========================================
  &.top {
    .kv {
			&::after {
				background-image: url("../../assets_v2/img/selfcare/top/kv.jpg");
				@include media(sp) {
					background-image: url("../../assets_v2/img/selfcare/top/kv_sp.jpg");
				}
			}
		}
    .pick-up-sec{
      .flex{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 0 32px;
        @include media(sp) {
          align-items: flex-start;
          gap: 0 16px;
        }
      }
      &__info{
        width:calc(100% - 140px - 32px);
        @include media(sp) {
          display: contents;
        }
      }
      .info-txt{
        @include media(sp) {
          float: left;
          width: calc(100% - 30% - 16px);
        }
      }

      .info-img{
        width: 140px;
        @include media(sp) {
          float: right;
          width: 30%;
        }
      }
    }
    .self-care-content-sec{
      .flex__column-3{
        @include media(sp) {
          gap: 20px 0;
        }
      }
      .flex__column-4{
        @include media(sp) {
          grid-template-columns: repeat(2, 1fr);
				gap: 16px;
        }
      }
      &__txt{
        @include media(sp) {
          font-size: 13px;
        }
      }
    }
  }
}
