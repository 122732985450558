@use "./assets_v2/scss/mixin" as *;


//==========================================
//  Base
//==========================================

* {
	box-sizing: border-box;
}

html {
	@include media(pc) {
		font-size: getvwPc(10);
	}
	@include media(pc-l) {
		font-size: 62.5%;
	}
	@include media(sp) {
		font-size: getvw(20);
	}
}

body {
	color: #333;
	font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
	position: relative;
	word-break: break-word;
	width: 100%;
	text-size-adjust: none;
	-webkit-text-size-adjust: none;
	font-size: 16px;
}

.container {
	overflow: hidden;
}

//==========================================
// レスポンシブスイッチ
//==========================================

.pc {
	display: block;

	@include media(sp) {
		display: none;
	}
}

.sp {
	display: none;

	@include media(sp) {
		display: block;
	}
}

.pc-s {
	display: block;

	@media only screen and (max-width: 767px){
		display: none;
	}
}

.sp-s {
	display: none;

	@media only screen and (max-width: 767px){
		display: block;
	}
}

//==========================================
// 幅
//==========================================

section.sec {
	margin-bottom: 80px;
	@include media(sp) {
		margin-bottom: 80px;
	}
}
.sec-in {
	width: 100%;
	margin: 0 auto;
	max-width: calc(1120px + 23px * 2);
	padding: 0 23px;
	@include media(sp) {
		max-width: 100%;
		width: 100%;
		padding: 0 23px;
	}
	&--short{
		max-width: calc(736px + 23px * 2);
	}
	&--bg{
		padding-top: 80px;
		padding-bottom: 80px;
		@include media(sp) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	&--bg-sp{
		@include media(sp) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	&--top {
		padding-top: 80px;
		@include media(sp) {
			padding-top: 80px;
		}
	}
	&--bottom{
		padding-bottom: 80px;
		@include media(sp) {
			padding-bottom: 80px;
		}
	}
}

//==========================================
// フォーム関連リセット
//==========================================

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.btn-submit{
	position: relative;
	max-width: 352px;
	margin: 0 auto;
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	font-size: 16px;
	color: #fff;
	max-width: 100%;
	width: 100%;
	height: 100%;
	transition: .3s;
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
	@include hover {
		background-color: #fff;
		border-color: #005baa;
		color: #005baa;
	}
// 	&:hover{
// 		color: #005baa;
// 	}
// 	&:focus-visible{
// 		background-color: #fff;
// 		border-color: #005baa;
// 		color: #005baa;
// }
	
}
}

textarea {
	width: 100% !important;
	height: auto;
}

//==========================================
// 電話番号のリンクをスマホに限定
//==========================================

@media (min-width: 1024px) {
	a[href*="tel:"] {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
	}
}

//==========================================
//  google Cookie
//==========================================

.ot-sdk-row.ot-cat-grp #ot-category-title,
.ot-sdk-row.ot-cat-grp .ot-acc-hdr,
.ot-sdk-row.ot-cat-grp .ot-always-active,
.ot-btn-container, .ot-cat-header, #ot-pc-title{
	font-family: "Hiragino UD Sans W6 JIS2004", sans-serif !important;
	font-weight: bold !important;
}