@use "./assets_v2/scss/mixin"as *;
@use "./assets_v2/scss/component"as *;

#INFO {
  b {
    @extend .bold-txt;
  }

  .list-item__link {
    text-align: right;
  }

  //==========================================
  // INFO
  //==========================================
  .search-sec {
    .legend {
      font-size: 18px;
      @extend .bold-txt;
    }

    .tab-contents {
      .sec-in {
        @include media(sp) {
          padding: 0 15px;
        }
      }
    }

    .border-blue {
      border-width: 2px;

      @include media(sp) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .form-table__input {
      .gap-01 {
        @include media(pc) {
          row-gap: 16px;
        }
      }
    }

    .flex--period {
      align-items: center;

      .select-box {
        min-width: 96px;

        @include media(sp) {
          min-width: 90px;
        }

        &::before {
          top: calc(50% - 9px);
          right: 12px;
          background: url(/assets_v2/img/info/icon_arrow_select.svg) no-repeat center / 100% auto;
          aspect-ratio: 8 / 19;
          transform: unset;
          border: none;
          width: 8px;
          height: auto;
        }
      }
    }

    .flex--btn {
      gap: 16px;
    }
  }

  //==========================================
  // TOP
  //==========================================
  &.top {
    hr {
      border-top-color: #ddd;
    }

    .intro-sec {
      .flex--btn {
        @include media(sp) {
          gap: 8px;

          .btn {
            width: 48%;
          }
        }
      }
    }

    .latest-information-sec {
      hr {
        margin-top: 0;
      }

      .base-list--05 {
        .list-item {
          margin-bottom: 0;
          padding-bottom: 0;

          &>a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding: 24px 0;

            &:hover,
            &:focus {
              .txt-link {
                text-decoration: none;
              }

              .tag {
                &--03 {
                  background-color: #005baa;
                  color: #fff;
                }

                &--01 {
                  background-color: #ccf2ff;
                }
              }
            }
          }

          &:first-of-type {
            a {
              padding-top: 0;
            }
          }

          &__left {
            @include media(sp) {
              .info-head-sp {
                order: 1;
              }

              .list-item__img {
                order: 2;
              }

              .list-item__info {
                order: 3;
              }
            }
          }
        }
      }
    }

    .pickup-sec {
      .swiper {

        // padding-right: 0;
        // padding-left: 0;
        @include media(sp) {
          overflow: visible;
          padding-right: 23px;
          padding-left: 23px;
        }
      }
    }

    .external-media-sec {
      .external-media-list {
        &>li {
          @include media(sp) {
            &:not(:first-of-type) {
              display: none;
            }
          }
        }
      }

      .card--07__info {
        .logo {
          height: 18px;
          width: auto;
        }

        &.card__info--01 {
          p.bold-txt {
            &:first-of-type {
              margin-bottom: 12px;

              &:has(+ .logo) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  //==========================================
  // Release
  //==========================================
  &.release {
    .search-result-sec {
      .search-number {
        font-size: initial;

        &>span b {
          color: #005baa;
          font-size: 20px;
        }
      }
    }
    .base-list--05 {
      .list-item {
        margin-bottom: 0;
        padding-bottom: 0;

        &>a {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 24px 0;

          &:hover,
          &:focus {
            .txt-link {
              text-decoration: none;
            }

            .tag {
              &--03 {
                background-color: #005baa;
                color: #fff;
              }

              &--01 {
                background-color: #ccf2ff;
              }
            }
          }
        }

        &:first-of-type {
          a {
            padding-top: 0;
          }
        }

        &__left {
          @include media(sp) {
            .info-head-sp {
              order: 1;
            }

            .list-item__img {
              order: 2;
            }

            .list-item__info {
              order: 3;
            }
          }
        }
      }
    }
  }

  &.top,
  &.release {
    @include media(sp) {
      .base-list--05 .list-item__info {
        width: calc(100% - 33% - 24px);
      }
    }
  }

  //==========================================
  // info001
  //==========================================
  &.info001 {
    .kv {
      @include media(pc) {
        padding-top: getvwPc(64);
        padding-bottom: getvwPc(120);
      }
    }

    .article-sec {
      img {
        width: auto;
        max-width: 100%;
      }

      strong {
        font-family: "Hiragino UD Sans W6 JIS2004", sans-serif;
        font-weight: bold;
      }

      a {
        text-decoration: underline;
        color: #005baa;

        &:hover,
        &:focus-visible {
          text-decoration: none;
        }
      }

      .btn {
        text-decoration: none;

        &:hover,
        &:focus-visible {
          color: #fff;
        }
      }

      //画像の幅（width:auto;）の打ち消し
      .icon-img {
        width: 40px;
        margin-right: 8px;
      }

      .btn--icon-img-02 .icon-img {
        width: 24px;
      }

      .icon-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        width: 8px;
        top: calc(50% - 5.5px);
        right: 15px;
      }

      .icon-blank {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        width: 13px;
        top: calc(50% - 6.5px);
        right: 15px;
      }

      .flex--img {
        @include media(pc) {
          display: grid;
          grid-template-columns: 448px 1fr;
        }

        @include media(sp) {
          display: block;
        }

        img {
          @include media(sp) {
            float: left;
            max-width: 168px;
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }


    .accordion__contents {
      display: none;

      button {
        p {
          text-align: left;
          font-size: 16px;
        }
      }
    }

    .flex__column-4 {
      @include media(sp) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 8px;
      }

      button {
        width: 100%;

        img {
          border-radius: 8px;
        }
      }
    }

    .flex__column-1-sp {
      @include media(sp) {
        grid-template-columns: 1fr;
      }
    }

    .base-list--05 {
      .list-item {
        border-bottom: 1px solid #ddd;
        margin-bottom: 0;
        align-items: flex-start;

        &__left {
          align-items: flex-start;
          width: 100%;
          gap: 16px;

          @include media(sp) {
            gap: 0;
          }
        }

        &__info {
          @include media(sp) {
            width: 60%;

            .list-item__link {
              li:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }

          .info-txt {
            margin-bottom: 16px;
          }
        }

      }


    }
  }

  //==========================================
  // External_media
  //==========================================
  &.external_media {
    .external-media-sec {
      .external-media-list {
        &>li {
          @include media(sp) {
            &:not(:first-of-type) {
              display: block;
            }
          }
        }
      }

      .card--07__info {
        .logo--flex {
          align-items: center;
          gap: 8px 16px;
        }

        .logo {
          height: 18px;
          width: auto;
        }
      }
    }
  }



  //==========================================
  // external_media
  //==========================================
  &.external_media {
    .external-media-sec {
      .external-media-list {
        li:nth-child(n + 13) {
          display: none;
        }
      }
    }
  }



  //==========================================
  // precautions
  //==========================================
  &.precautions {
    .shop__web__btn {
      margin: 0 auto;
    }

    .accordion__panel {
      left: calc(50% - 272px / 2);
      padding: 0;

      @include media(sp) {
        max-width: getvw(352);
        width: 100%;
        left: calc(50% - getvw(352) / 2);
      }

      @media only screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        left: 0;
      }
    }
  }




}