@use "./assets_v2/scss/mixin" as *;

#PARTNERING {
  //==========================================
  // top
  //==========================================
  &.top {
		.kv {
			&::after {
				background-image: url("../../assets_v2/img/partnering/top/kv.jpg");
				background-size: contain;
				@include media(sp) {
					background-image: url("../../assets_v2/img/partnering/top/kv_sp.jpg");
					background-size: contain;
				}
			}
		}
		.make-sec{
			&__list{
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				gap: 32px;
				position: relative;
				@include media(sp) {
					gap: 40px;
				}
				&::before{
					content: '';
					display: inline-block;
					position: absolute;
					width: 104px;
					height: 104px;
					top: calc(50% - 104px / 2);
					right: calc(50% - 104px / 2);
					background-image: url("../../assets_v2/img/partnering/top/top_img_03.svg");
					background-size: contain;
					@include media(sp) {
						top: 45%;
					}
				}
				& > li{
					width:40%;
					@include media(sp) {
						width: 100%;
						&:nth-child(1){
							padding-bottom:40px;
						}
						&:nth-child(2){
							padding-top:60px;
						}
					}
					h3{
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 0 8px;
						img{
							width: 40px;
						}
					}
					.base-list, p{
						width: max-content;
						margin: 0 auto;
						font-size: 18px;
						@media only screen and (max-width: 767px){
							width: auto;
							font-size: 16px;
						}
					}
				}
			}
		}
		.apply-sec{
			&__list{
				display: grid;
				gap: 0 32px;
				li{
					@include media(sp) {
						&:nth-child(1){
							border-bottom: 1px solid #ddd;
							padding-bottom: 40px;
							margin-bottom: 40px;
						}
					}
					@include media(sp) {
						border-bottom: 1px solid #ddd;
						padding-bottom: 40px;
					}
				}
			}
			&__img{
				width: 328px;
				margin: 0 auto;
				display: block;
			}
			.bnr-link{
				max-width: 770px;
				margin-inline:auto;
				a:hover{
					opacity: .6;
				}
			}
		}
  }
	&.business{
		.anchor-sec{
			.flex__basic {
				gap: 24px 32px;
				li {
					@include media(sp) {
						width: 100%;
					}
			}
		}
	}
	.utilization-sec{
		.accordion{
			&__number{
				display:inline-block;
			}
		}
	}
}
}