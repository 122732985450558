@use "sass:math";
@use "sass:map";


//==========================================
// color
//==========================================

$m_color: #333;
$s1_color: #333;
$s2_color: #333;

//==========================================
// Breakpoint
//==========================================

@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$breakpoints: (
  sp: "only screen and (max-width: 1024px)",
  pc: "only screen and (min-width: 768px)",
  pc2: "only screen and (min-width: 1025px)",
  pc-t: "only screen and (max-width: 1249px)",
  pc-l: "only screen and (min-width: 1250px)",
  f_sp: 1024px,
  f_pc: 1100px,
);

//===========================================
//	可変サイズmixinその1
//===========================================
:root {
  --vw: 1vw;
  @include media(sp) {
    
  --vw: 1vw;
  }
}


// Width
$base: 1024;
$basePc: 1250;


// VW
@function getvw($number) {
  @return calc($number / $base * 100 * var(--vw));
}
@function getvwPc($number) {
  @return calc($number / $basePc * 100 * var(--vw));
}
// @function getvwXl($number) {
//   @return $number / $baseXl * 100vw;
// }

// backgroud-sizeのショートコードには含められない

//===========================================
//	可変サイズmixinその2
//===========================================

@function fluid($fontsize-l,$fontsize) {
  @return clamp( #{$fontsize * 1px}, calc( #{$fontsize * 1px} + (( 100vw - #{map-get($breakpoints, f_sp)} ) * (( #{$fontsize-l} - #{$fontsize} ) / ( #{num(map-get($breakpoints, f_pc))} - #{num(map-get($breakpoints, f_sp))} )))), #{$fontsize-l * 1px} );
}

@function num($input){
  @return math.div( $input, ($input * 0 + 1) );
}

// 記入例 : font-size: fluid(16,13);



//===========================================
//	center
//===========================================

@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
}

//===========================================
//	over
//===========================================
@mixin over{
    transition: .3s;
    &:hover{
        opacity: 0.7;
    }
}

/*
------------------------
用途：個別にhover要素付けたい時用
------------------------
例）
div {
    width:100px;
    height:100px;
    @include hover;
}
*/

//===========================================
//	flex
//===========================================

@mixin flex($flex-justify,$flex-wrap){
    display: flex;
    justify-content: map-get($flex-status,$flex-justify);
    flex-wrap: map-get($flex-wraps,$flex-wrap);
}
$flex-status: (
    s:flex-start,
    c:center,
    e:flex-end,
    b:space-between,
    a:space-around
);
$flex-wraps: (
    w:wrap,
    n:nowrap
);

/*
------------------------
用途：良く使うflexを1行に短縮
------------------------
例）
div {
    @include flex(b,w);
}
↑これは↓これを一行で書いた例
div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
*/

//===========================================
// Browser Hack
//===========================================

@mixin firefox {
  @-moz-document url-prefix() {
    @content;
  }
}
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @content;
  }
}
@mixin safari {
  @at-root _::-webkit-full-page-media,
    _:future,
    :root & {
    @content;
  }
}

//===========================================
// Imgr
//===========================================

@mixin imgr {
  @include chrome {
    image-rendering: -webkit-optimize-contrast;
  }
  @include media(sp) {
    image-rendering: auto;
  }
}

//===========================================
// lineheight
//===========================================
@mixin lineheight($line-height) {
  &::before,
  &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
  }
  &::before {
      margin-top: calc((1 - #{$line-height}) * 0.5em);     //文字上の余白を消す
  }
  &::after {
      margin-bottom: calc((1 - #{$line-height}) * 0.5em);  //文字下の余白を消す
  }
}

//==========================================
// Font-family
//==========================================
$ff-normal: "Hiragino UD Sans W3 JIS2004", sans-serif;
$ff-bold: "Hiragino UD Sans W6 JIS2004", sans-serif;


//==========================================
// Hover
//==========================================
@mixin hover {
	@media (hover: none) {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&:active {
			@content;
		}
	}

	@media (-ms-high-contrast: none), (-ms-high-contrast: active), (-moz-touch-enabled: 0), (hover: hover) {
		&:hover,
    &:focus-visible {
			@content;
		}
	}
}