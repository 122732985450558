@use "./assets_v2/scss/mixin"as *;

.header {
  position: relative;
  height: 100px;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #cccccc;

  @include media(sp) {
    height: 61px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: rgb(0, 91, 170);
    background: linear-gradient(90deg,
        rgba(0, 91, 170, 1) 0%,
        rgba(0, 180, 237, 1) 30%,
        rgba(137, 186, 23, 1) 70%,
        rgba(255, 233, 0, 1) 100%);

    @include media(sp) {
      height: 2px;
    }
  }

  &__in {
    display: flex;
    max-width: 1166px;
    height: 100%;
    margin: 0 auto;
    padding: 0 23px;

    @include media(sp) {
      width: 100%;
      justify-content: space-between;
      padding: 0 15px;
    }
  }

  a,
  button {
    font-family: inherit;
    cursor: pointer;
  }

  //====================================================
  // ヘッダーロゴ
  //====================================================
  &__logo {
    display: flex;
    align-items: center;
    text-align: center;
    width: 246px;

    @include media(sp) {
      width: 241px;
    }

    img {
      display: block;
      top: 0;
    }

    a {
      display: block;
    }
  }

  //====================================================
  // ヘッダーメニュー
  //====================================================

  &__nav {
    display: flex;
    margin-right: 0;
    margin-left: auto;
  }

  &__menu {
    display: flex;
    flex-direction: column;

    @include media(pc2) {
      justify-content: space-between;
    }

    @include media(sp) {
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      right: 0;
      background: #fff;
      opacity: 0;
      visibility: hidden;
      width: 100%;

      &.is-open,
      &.is-open--search {
        opacity: 1;
        visibility: visible;
      }

      &.is-open--search {

        .header__menu-list1,
        .header__menu-list2 {
          position: absolute;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  &__menu-list1 {
    @include media(pc2) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 0 24px;
      padding-top: 20px;
    }

    @include media(sp) {
      order: 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-top: 1px solid #767676;

      &::after {
        content: "";
        border-left: 1px solid #767676;
        border-bottom: 1px solid #767676;
      }
    }

    .header__menu-item {
      @include media(sp) {
        border-bottom: 1px solid #767676;

        &:nth-of-type(even) {
          border-left: 1px solid #767676;
        }
      }

      &>a,
      &>button {
        display: flex;
        transition: 0.2s;

        @include media(pc2) {
          font-size: 14px;
          align-items: center;
        }

        @include media(sp) {
          flex-direction: column;
          font-size: 14px;
          padding: 15px 23px;
        }

        .icon_search {
          @include media(pc2) {
            width: 13.4px;
            margin-right: 4.6px;
          }
        }
      }

      &>button {
        &[aria-expanded="true"] {
          color: #005BAA;
        }

        @include hover {
          color: #005BAA;
        }
      }
    }
  }

  &__menu-list2 {

    @include media(pc2) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 0 48px;
      padding-bottom: 18px;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1200px) {
      gap: 0 calc(48px * .6);
    }

    @include media(sp) {
      order: 1;
    }

    .header__menu-item {

      
      @include media(sp) {
        position: relative;
        &:not(:first-of-type) {
          border-top: 1px solid #767676;
        }
      }

      &>a,
      &>button {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: $ff-bold;
        transition: 0.2s;

        @include media(sp) {
          width: 100%;
          padding: 20px 23px;
        }

        @include media(pc) {
          &.is-focus:focus {
            outline: 4px solid #007921;
            outline-offset: 4px;
          }
        }

        .icon_header {
          @include media(pc2) {
            position: relative;
            top: 1px;
            display: inline-block;
            width: 7.6px;
            margin-left: 8px;
          }
        }


        &::after {
          content: "";

          @include media(pc2) {
            position: absolute;
            top: calc(100% + 18px - 1.5px);
            right: 0;
            left: 0;
            z-index: 2;
            background: #005BAA;
            border-radius: 2px;
            opacity: 0;
            visibility: hidden;
            width: 100%;
            height: 3px;
            margin: 0 auto;
          }

          @include media(sp) {
            background: url('../img/common/icon_arrow_dropmenu_01_sp.svg') no-repeat 0 0 / 100% auto;
            aspect-ratio: 7 / 13;
            width: 7px;
            margin: 0 0 0 14px;
          }
        }
      }

      &>button {
        @include media(sp) {
          &.trigger-drop-menu--sp {

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 22px;
              bottom: 0;
              background: url('../img/common/icon_open.svg') no-repeat 0 0 / 100% auto;
              width: 24px;
              height: 24px;
              margin: auto;
            }

            &:focus-visible {
              outline: 4px solid #007921;
              outline-offset: 4px;
              // &::before {
              // }
            }
          }
        }

        &[aria-expanded="true"] {
          .icon_header {
            @include media(pc2) {
              transform: rotate(180deg)
            }
          }

          @include media(sp) {
            background: #F6F6F4;

            &.trigger-drop-menu--sp {
              &::before {
                background-image: url('../img/common/icon_open_on.svg');
              }
            }
          }
        }

        &[aria-expanded="true"] {
          @include media(pc2) {
            color: #005BAA;

            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        @include hover {
          @include media(pc2) {
            color: #005BAA;

            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      &--drop-menu {
        &>button {
          &::after {
            @include media(sp) {
              content: none;
            }
          }
        }
      }
    }
  }

  // キーワード検索（SP）
  &__menu-search {
    @include media(sp) {
      order: 3;
      padding: 40px 23px 0;

      .input-area {
        position: relative;

        .input {
          display: block;
          font-size: 16px;
          border: 2px solid #767676;
          border-radius: 2px;
          width: 100%;
          height: 100%;
          padding: 15px 58px 15px 15px;
          margin-bottom: 18px;
          font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

          ::placeholder {
            color: #333;
            font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
          }
        }

      }
    }
  }

  // 閉じる（SP）
  &__menu-close {
    @include media(sp) {
      order: 4;
      padding-bottom: 38px;
    }

    .btn-close-drop-menu {
      @include media(sp) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        margin: 0 auto;

        &>span {
          display: inline-flex;
          align-items: center;
          font-size: 16px;
          font-family: $ff-bold;
        }

        .icon-close {
          width: 14px;
          margin-left: 7px;
        }
      }
    }
  }

  //====================================================
  // 検索
  //====================================================

  &__search {
    @include media(sp) {
      position: absolute;
      top: calc(50% - (37px / 2));
      right: 65px;
      display: block;

      button {
        display: grid;
        align-items: space-between;
        justify-content: center;
        width: 23px;
        height: 37px;
        &.is-focus {
          outline: 4px solid #007921;
          outline-offset: 4px;  
        }
      }


    }
  }



  .btn-search {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 100%;
    background: url('../img/common/icon_search.svg') no-repeat 0 0 / 100% auto;
    background-size: 25px;
    background-position: 50% 50%;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 18px;
      bottom: 0;

      aspect-ratio: 1;
      width: 25px;
      margin: auto 0;
    }
  }

  //====================================================
  // ハンバーガーメニュー
  //====================================================

  &__menu-toggle {
    @include media(sp) {
      &.is-focus:focus {
        outline: 4px solid #007921;
        outline-offset: 4px;
      }
      display: block;
      width: 32px;
      height: 36px;
      position: absolute;
      top: calc(50% - 35px / 2);
      z-index: 100;
      right: 17px;
      transition: 0.2s;
      display: grid;
      place-items: center;

      &>div {
        @include center;
        width: 24px;
        height: 17px;
        top: 0;
        transform: translate(-50%, 0);
      }

      span {
        display: block;
        height: 2px;
        background: #333;
        position: absolute;
        width: 24px;
        left: 0;
        transition: 0.3s ease-in-out;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 9px;
        }

        &:nth-child(3) {
          top: 18px;
        }
      }

      /*開閉ボタンopen時*/
      &.is-open {

        // top: calc(50% - 26px / 2);
        span {
          &:nth-child(1) {
            top: 9px;
            transform: rotate(135deg);
          }

          &:nth-child(2) {
            width: 0;
            left: 50%;
          }

          &:nth-child(3) {
            top: 9px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  //====================================================
  // 共通
  //====================================================
  .icon-txt {
    font-size: 10px;
    transform: scale(0.8);
    position: absolute;
    bottom: 0;
    width: max-content;
    font-family: $ff-bold;
    line-height: 1;
  }

  //==========================================
  // ドロップメニュー
  //==========================================
  .drop-menu {
    visibility: hidden;
    opacity: 0;

    @include media(pc2) {
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      background: #fff;
      width: 100%;
      padding: 40px 0 80px;
    }

    @include media(sp) {
      position: absolute;

      &--search {
        position: static;
        visibility: visible;
        opacity: 1;

        .drop-menu__in {
          padding: 0;
        }
      }
    }

    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;

      @include media(pc2) {}

      @include media(sp) {
        position: relative;
      }
    }

    &__in {
      @include media(pc2) {
        position: relative;
        width: 100%;
        max-width: 1166px;
        margin: 0 auto;
        padding: 0 23px;
      }

      @include media(sp) {
        padding: 0 23px;
      }
    }

    &__header {
      @include media(pc2) {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 24px;
      }

      .link {
        @include media(pc2) {
          display: inline-flex;
          align-items: center;
          font-size: 24px;
          font-family: $ff-bold;
          width: fit-content;
        }

        .icon-link {
          @include media(pc2) {
            width: 10px;
            margin-left: 24px;
          }
        }
      }
    }

    // 閉じるボタン
    .btn-close-drop-menu {
      @include media(pc2) {
        position: absolute;
        top: 4.5px;
        right: 23px;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        font-family: $ff-bold;
        width: fit-content;
      }

      .icon-close {
        @include media(pc2) {
          width: 14px;
          margin-left: 7px;
        }
      }
    }

    // キーワード検索

    // ドロップメニュー
    &-list {
      @include media(pc2) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 31px;
      }

      @include media(sp) {
        padding-bottom: 40px;
      }

      &__item {
        border-bottom: 1px solid #ddd;

        &>a {
          position: relative;
          display: flex;
          align-items: center;

          @include media(pc2) {
            font-size: 16px;
            font-family: $ff-normal;
            width: 100%;
            padding: 24px 0 20px;
          }

          @include media(sp) {
            font-size: 15px;
            padding: 16px 30px 12px 15px;
          }

          &::after {
            content: "";
            position: absolute;
            top: calc(50% - 9px / 2);
            right: 0;
            bottom: 0;

            @include media(pc2) {
              background: url('../img/common/icon_arrow_blue.svg') no-repeat 0 0 / 100% auto;
              width: 9px;
              margin: auto 0;
            }

            @include media(sp) {
              background: url('../img/common/icon_arrow_blue.svg') no-repeat 0 0 / 100% auto;
              right: 8px;
              width: 9px;
              margin: auto 0;
            }
          }
        }
      }
    }
  }

  .input-area {
    width: calc(928 / 1120 * 100%);
    position: relative;

    @include media(sp) {
      width: 100%;
    }

    .input {
      display: block;
      font-size: 16px;
      border: 2px solid #767676;
      position: relative;
      border-radius: 2px;
      padding: 10px 20px;
      font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;

      ::placeholder {
        color: #333;
        font-family: "Hiragino UD Sans W3 JIS2004", sans-serif;
      }
    }
  }

  .drop-menu--search[aria-hidden="true"] {
    display: none;
  }
}

.drop-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  background: rgba(#000, .3);
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100vh;

  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}
