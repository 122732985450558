@use "./assets_v2/scss/mixin" as *;

#WEB_ACCESSIBILITY {
	&.top{
		.img{
			width: auto;
			max-width: 100%;
		}
		.break-list{
			word-break: break-all;
		}
	}
}
